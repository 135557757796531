import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import theme from '../../../styles/HrosarinnTheme';
import * as Wizard from '../../../components/Wizard';
import * as Form from '../../../components/Form';

import RockOn from '../../../icons/rock-on.svg';
import {
  useGetDepartmentsQuery,
  useInviteUsersMutation,
} from '../../../graphql';
import Progress from '../../../components/Progress';
import { LanguagePicker } from 'components';

const NEXT_STEP = '/onboarding/finish';

type InviteUser = {
  name: string;
  email: string;
  department: string;
  hasError?: boolean;
};

const AddUsers = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const defaultFields: InviteUser[] = [
    { name: '', email: '', department: '', hasError: false },
    { name: '', email: '', department: '', hasError: false },
    { name: '', email: '', department: '', hasError: false },
  ];
  const [usersToInvite, setUsersToInvite] = useState(defaultFields);

  const [inviteUsers] = useInviteUsersMutation();

  const { data: departmentsData } = useGetDepartmentsQuery();

  const handleInputChange = (
    index: number,
    e: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>,
  ) => {
    const values = [...usersToInvite];
    const { value, name } = e.currentTarget;
    switch (name) {
      case 'email':
        values[index][name] = value;
        values[index].hasError = false;
        break;
      case 'name':
      case 'department':
        values[index][name] = value;
        break;
    }

    setUsersToInvite(values);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...usersToInvite];
    values.splice(index, 1);
    setUsersToInvite(values);
  };

  const handleAddFields = () => {
    const values = [...usersToInvite];
    values.push({ name: '', email: '', department: '' });
    setUsersToInvite(values);
  };

  const onSubmit = async () => {
    const inviteList = usersToInvite
      .filter(({ email }) => Boolean(email))
      .map(user => {
        const { email, name, department } = user;

        return {
          email,
          name,
          department: department === '' ? null : parseInt(department, 10),
        };
      });

    if (inviteList.length === 0) {
      history.push(NEXT_STEP);
      return;
    }

    await inviteUsers({
      variables: {
        input: {
          users: inviteList,
        },
      },
    });

    history.push(NEXT_STEP);
  };

  const validate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let hasErrors = false;
    const validatedUsers = usersToInvite.map(user => {
      if (!user.email) {
        return user;
      } else if (!Form.isValidEmail(user.email)) {
        hasErrors = true;
        return { ...user, hasError: true };
      }
      return user;
    });

    if (hasErrors) {
      setUsersToInvite(validatedUsers);
      return;
    }

    onSubmit();
  };

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley
          src={RockOn}
          alt="Smiley"
          style={{ maxHeight: '300px' }}
        />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form onSubmit={validate}>
          <Wizard.Fields wide>
            <Wizard.Header>
              <Wizard.Heading>{t('onboarding.addUsers.title')}</Wizard.Heading>
            </Wizard.Header>

            {usersToInvite.map((user, index) => (
              <Form.FieldRow key={index}>
                <Form.InputField
                  id={`email.${index}`}
                  name="email"
                  label={t('onboarding.addUsers.email')}
                  value={user.email}
                  error={user.hasError}
                  onChange={event => handleInputChange(index, event)}
                />
                <Form.InputField
                  id={`name.${index}`}
                  name="name"
                  label={t('onboarding.addUsers.name')}
                  value={user.name}
                  onChange={event => handleInputChange(index, event)}
                />
                <Form.SelectField
                  id={`department.${index}`}
                  name="department"
                  label={t('onboarding.addUsers.department')}
                  value={user.department}
                  onChange={event => handleInputChange(index, event)}
                >
                  {departmentsData &&
                    departmentsData.departments.departments.map(
                      (department, index) => (
                        <option value={department.id} key={index}>
                          {department.name}
                        </option>
                      ),
                    )}
                </Form.SelectField>

                <Form.RemoveButton
                  onClick={() => {
                    handleRemoveFields(index);
                  }}
                  alt={t('onboarding.addUsers.removeButtonCta')}
                />
              </Form.FieldRow>
            ))}
            <Form.AddButton onClick={handleAddFields}>
              {t('onboarding.addUsers.addAnother')}
            </Form.AddButton>
          </Wizard.Fields>
          <Wizard.Footer>
            <Wizard.Button type="submit">
              {t('onboarding.addUsers.cta')}
            </Wizard.Button>
            <Wizard.SkipLink to={NEXT_STEP}>
              {t('onboarding.addUsers.skip')}
            </Wizard.SkipLink>
          </Wizard.Footer>
        </Wizard.Form>
        <Wizard.ProgressContainer>
          <Progress current={3} total={4} />
        </Wizard.ProgressContainer>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

export default AddUsers;
