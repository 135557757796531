import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import * as StyleUtils from 'styles/utils';

export const MenuContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: ${StyleUtils.Spacing(3)};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.br3};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-height: 340px;
  overflow-y: auto;

  ${up('md')} {
    width: 380px;
  }
`;

export const MenuItem = styled.div`
  ${StyleUtils.Flex};
  align-items: center;
  // min-width: 380px;
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.fonts.body};
`;

export const MenuIconContainer = styled.div`
  ${StyleUtils.Flex};
  ${StyleUtils.CenterContent};
  width: 35px;
`;

export const MenuIcon = styled.img`
  height: 35px;
  width: auto;
`;

export const Separator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.borderGray};
`;

type Spacer = {
  h?: number;
  v?: number;
};

export const Spacer = styled.div<Spacer>`
  height: ${({ v }) => (v ? StyleUtils.Spacing(v) : 0)};
  width: ${({ h }) => (h ? StyleUtils.Spacing(h) : 0)};
`;
