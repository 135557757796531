import styled, { css } from 'styled-components';
import { fluidType, Spacing, fluidProp } from '../../styles';
import { Link } from 'react-router-dom';

interface TypographyProps {
  min: number;
  max: number;
  weight?: 100 | 400 | 500 | 700;
  top?: number;
  bottom?: number;
  maxWidth?: number;
  lineHeight?: number;
}

export const Type = styled.p<TypographyProps>`
  font-weight: ${props => (props.weight ? props.weight : 400)};
  line-height: 1.3;
  padding-top: ${({ top }) => (top ? Spacing(top) : 0)};
  padding-bottom: ${({ bottom }) => (bottom ? Spacing(bottom) : 0)};

  ${props =>
    fluidType(
      props.min,
      props.max,
      props.theme.breakpoints.xs,
      props.theme.breakpoints.xl,
      'px',
    )};

  ${({ maxWidth, theme }) =>
    maxWidth &&
    fluidProp(
      'max-width',
      200,
      maxWidth,
      theme.breakpoints.xs,
      theme.breakpoints.xl,
      'px',
    )};}

    ${({ lineHeight }) =>
      lineHeight &&
      css`
        line-height: ${lineHeight};
      `}
`;

export const BodyLink = styled(Link)`
  font-weight: bold;
`;
