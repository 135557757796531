import React, { FC } from 'react';

import * as Styled from './styles';
import { useTranslation } from 'react-i18next';

import { Type, Spacer, Image } from 'components';

import monkey from './images/monkey.svg';

import deco1 from '../images/deco1.svg';
import deco2 from '../images/deco2.svg';
import deco3 from '../images/deco3.svg';

type Props = {
  fullScreen?: boolean;
};

const NotFound: FC<Props> = ({ fullScreen }) => {
  const { t } = useTranslation();
  return (
    <Styled.ErrorContainer fullScreen={fullScreen}>
      <Styled.Inner>
        <Image src={monkey} alt="" min={170} max={260} />
        <Spacer v={8} />
        <Type min={28} max={50} weight={700}>
          <Styled.Faded>402 ... 403 ...</Styled.Faded> 404
        </Type>
        <Spacer v={3} />
        <Type min={18} max={40} style={{ maxWidth: '800px' }}>
          {t('errors.404.body')}
        </Type>
        <Styled.DecoImage
          src={deco1}
          left={'0'}
          top={'40%'}
          min={42}
          max={80}
        />
        <Styled.DecoImage
          src={deco2}
          right={'0'}
          top={'30%'}
          min={42}
          max={80}
        />
        <Styled.DecoImage
          src={deco3}
          right={'0'}
          bottom={'-50px'}
          min={42}
          max={80}
        />
      </Styled.Inner>
    </Styled.ErrorContainer>
  );
};

export default NotFound;
