import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Type } from 'components';

import * as StyleUtils from 'styles/utils';

export const Sidebar = styled.div`
  background-color: ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: ${StyleUtils.Spacing(2, 0)};
  width: 100%;
`;

export const OrgNameContainer = styled.div`
  padding: ${StyleUtils.Spacing(0, 4)};
  margin-bottom: ${StyleUtils.Spacing(8)};
`;

export const OrgName = styled(Type)`
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MainEntries = styled.nav``;

interface SidebarEntryProps {
  readonly active?: boolean;
}

export const SidebarEntry = styled(NavLink)<SidebarEntryProps>`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.active ? props.theme.colors.purpleActive : 'none'};
  color: ${props => props.theme.colors.white};
  cursor: pointer;

  &:hover,
  &.active {
    background-color: ${props => props.theme.colors.purpleActive};
  }
`;

export const EntryInner = styled.div`
  align-items: center;
  display: flex;
  padding: ${StyleUtils.Spacing(1, 4)};
  margin: ${StyleUtils.Spacing(0.5, 0)};
`;

export const EntryIcon = styled.img`
  width: 22px;
  height: auto;
`;

export const EntryLabel = styled.div`
  margin-left: ${StyleUtils.Spacing(2)};
`;

export const SettingsEntry = styled.div`
  border-top: 1px solid ${props => props.theme.colors.borderGray};
  margin: ${StyleUtils.Spacing(0, 2)};
`;

export const SettingsEntryInner = styled.div`
  margin: ${StyleUtils.Spacing(0, -2)};
`;
