import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ReactEditor, useSlate } from 'slate-react';
import { Editor, Range } from 'slate';
import styled from 'styled-components';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import {
  isBlockActive,
  isMarkActive,
  isTitle,
  toggleBlock,
  toggleMark,
} from './format';

const HoveringToolbar = React.forwardRef((props, ref: $FixMe) => {
  const editor = useSlate();

  useEffect(() => {
    const el = ref?.current;
    const { selection } = editor;
    const domSelection = window.getSelection();

    if (!el) {
      return;
    }

    if (
      !selection ||
      !domSelection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === '' ||
      isTitle(editor)
    ) {
      el.removeAttribute('style');
      return;
    }

    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = '1';
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left +
      window.pageXOffset -
      el.offsetWidth / 2 +
      rect.width / 2}px`;
  });

  return (
    <Portal>
      <Menu ref={ref}>
        <FormatButton type="block" format="heading">
          <FormatSizeIcon />
        </FormatButton>
        <FormatButton type="block" format="block_quote">
          <FormatQuoteIcon />
        </FormatButton>
        <FormatButton type="block" format="list_numbered">
          <FormatListNumberedIcon />
        </FormatButton>
        <FormatButton type="block" format="list_bulleted">
          <FormatListBulletedIcon />
        </FormatButton>
        <FormatButton type="mark" format="bold">
          <FormatBold />
        </FormatButton>
        <FormatButton type="mark" format="italic">
          <FormatItalic />
        </FormatButton>
        <FormatButton type="mark" format="underlined">
          <FormatUnderlined />
        </FormatButton>
      </Menu>
    </Portal>
  );
});

const Menu = styled.div`
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 10;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  transition: opacity 0.75s;

  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
`;

const Portal: FC = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

type ButtonProps = {
  reversed?: boolean;
  active: boolean;
};

const Button = styled.span<ButtonProps>`
  cursor: pointer;
  color: ${({ reversed, active }) =>
    reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#ccc'};
`;

type FormatButtonProps = {
  format: string;
  type: 'block' | 'mark';
};

const FormatButton: FC<FormatButtonProps> = ({ format, type, children }) => {
  const editor = useSlate();
  const active =
    type === 'mark'
      ? isMarkActive(editor, format)
      : isBlockActive(editor, format);
  const toggle = type === 'mark' ? toggleMark : toggleBlock;
  return (
    <Button
      reversed
      active={active}
      onMouseDown={event => {
        event.preventDefault();
        toggle(editor, format);
      }}
    >
      {children}
    </Button>
  );
};

export default HoveringToolbar;
