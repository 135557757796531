import React, { useState, useRef, createRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Type, Role } from '../../../../../components';
import { ComplimentCicleIcon, OptionsMenuIcon } from '../../../icons';
import OptionsMenu from './OptionsMenu';
import EntryDeselectedIcon from '../../icons/EntryDeselected.svg';
import EntrySelectedIcon from '../../icons/EntrySelected.svg';

import * as Styled from './styles';

import Star from './icons/star.svg';
import { FocusOn } from 'react-focus-on';

interface MenuItem {
  title: string;
  onClick: () => void;
  shouldSkip?: boolean;
}

interface EntryProps {
  name: string;
  isMe: boolean;
  isEmployee?: boolean;
  isOnboard?: boolean;
  isStar?: boolean;
  id: number;
  menuOptions: MenuItem[];
  isSelected?: boolean;
  setSelected: (id: number) => void;
  department?: string;
  onCompliment: () => void;
  role?: string;
}

const Entry = ({
  name,
  isMe = false,
  isEmployee = false,
  isOnboard = true,
  isStar = false,
  id,
  menuOptions,
  isSelected,
  setSelected,
  department,
  onCompliment,
  role,
}: EntryProps) => {
  const { t } = useTranslation();
  const [isOver, setIsOver] = useState(false);
  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [selected, setSelectedMenuOption] = useState(0);

  const refs = useMemo(
    () => menuOptions.map(() => createRef<HTMLDivElement>()),
    [],
  );

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          const prev = Math.max(0, selected - 1);
          setSelectedMenuOption(prev);
          refs[prev]?.current?.focus();
          break;
        case 'ArrowDown':
          const next = Math.min(menuOptions.length - 1, selected + 1);
          setSelectedMenuOption(next);
          refs[next]?.current?.focus();
          break;
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [selected, refs]);

  const handleSelectClick = () => {
    if (!isMe && isOnboard) setSelected(id);
  };

  return (
    <Styled.Row
      role="button"
      tabIndex={0}
      aria-label={t('app.a11y.selectRow', { name })}
      ref={rowRef}
      isOver={isOver}
      onFocus={() => {
        setIsOver(true);
      }}
      onBlur={() => {
        setTimeout(() => {
          // Check if the new activeElement is a child of the original container
          if (!rowRef?.current?.contains(document.activeElement as Node)) {
            // Outside focus
            setIsOver(false);
            setIsShowingMenu(false);
          }
        }, 0);
      }}
      onMouseEnter={() => {
        setIsOver(true);
      }}
      onMouseLeave={() => {
        setIsOver(false);
        setIsShowingMenu(false);
      }}
      onClick={handleSelectClick}
      onKeyPress={e => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === ' ') {
          handleSelectClick();
        }
      }}
    >
      <Styled.SelectColumn>
        {(!isEmployee || (!isMe && isOnboard)) && (
          <Styled.SelectionImage
            src={isSelected ? EntrySelectedIcon : EntryDeselectedIcon}
          />
        )}
      </Styled.SelectColumn>
      <Styled.NameColumn>
        <Type min={14} max={16} weight={700}>
          {(isEmployee && (
            <Link
              to={`/profile/${id}`}
              aria-label={t('app.a11y.viewUserProfile', { name })}
            >
              {name}
              {isStar && <Styled.StarImage src={Star} />}
            </Link>
          )) ||
            name}
        </Type>
        {department && (
          <Styled.DepartmentsLabel min={12} max={14} top={1}>
            {department}
          </Styled.DepartmentsLabel>
        )}
      </Styled.NameColumn>

      <Styled.DepartmentsColumn>
        <Type min={14} max={16} weight={700}>
          {department}
        </Type>
      </Styled.DepartmentsColumn>

      <Styled.RoleColumn>
        <Role expectedRole="ADMIN">
          <Type min={14} max={16} weight={700}>
            {role ? role.toLowerCase() : ''}
          </Type>
        </Role>
      </Styled.RoleColumn>

      <Styled.StatusColumn>
        <Role expectedRole="ADMIN">
          <Type min={14} max={16} weight={700}>
            {!isEmployee
              ? ''
              : isOnboard
              ? t('dashboard.employees.onboard')
              : t('dashboard.employees.invited')}
          </Type>
        </Role>
      </Styled.StatusColumn>

      <Styled.ComplimentsColumn isOver={isOver}>
        {(!isEmployee || (!isMe && isOnboard)) && (
          <div
            aria-label={t('app.a11y.compliment', { name })}
            role="button"
            tabIndex={0}
            onClick={e => {
              e.stopPropagation();
              if (!isMe) onCompliment();
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                if (!isMe) onCompliment();
              }
            }}
          >
            <ComplimentCicleIcon />
            <Type min={12} max={14} weight={500} as="span">
              {t('app.sendCompliment')}
            </Type>
          </div>
        )}
      </Styled.ComplimentsColumn>
      <Styled.OptionsColumn
        isOver={isOver}
        onClick={e => {
          e.stopPropagation();
          setIsShowingMenu(true);
        }}
      >
        <Role expectedRole="ADMIN">
          <button role="menu" tabIndex={0} style={{ cursor: 'pointer' }}>
            <OptionsMenuIcon />
          </button>

          <OptionsMenu isShowing={isShowingMenu}>
            <FocusOn
              enabled={isShowingMenu}
              onEscapeKey={() => setIsShowingMenu(false)}
              onClickOutside={() => setIsShowingMenu(false)}
            >
              {menuOptions.map(
                ({ title, onClick, shouldSkip }: MenuItem, index: number) => {
                  if (shouldSkip) return undefined;

                  return (
                    <Styled.MenuItem
                      ref={refs[index]}
                      key={index}
                      tabIndex={0}
                      onClick={onClick}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          onClick();
                        }
                      }}
                      onFocus={() => setSelectedMenuOption(index)}
                    >
                      <Type
                        min={14}
                        max={16}
                        weight={500}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {title}
                      </Type>
                    </Styled.MenuItem>
                  );
                },
              )}
            </FocusOn>
          </OptionsMenu>
        </Role>
      </Styled.OptionsColumn>
    </Styled.Row>
  );
};

export default Entry;
