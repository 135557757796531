import React, { ReactNode } from 'react';
import { useUserState } from '../../store/User';

interface RoleProps {
  children: ReactNode;
  expectedRole: 'USER' | 'MANAGER' | 'ADMIN';
}

export const Role = ({ children, expectedRole }: RoleProps) => {
  const { role } = useUserState();

  if (role === expectedRole) {
    return <>{children}</>;
  }

  return <></>;
};
