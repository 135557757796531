import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { Image } from 'components';

import * as StyleUtils from 'styles/utils';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  background-color: ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.white};
  min-height: 100vh;

  text-align: center;
`;

export const Inner = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  flex: 1;
  position: relative;
  width: 100%;
  padding: ${StyleUtils.Spacing(2)};

  ${up('md')} {
    width: 70%;
    padding: ${StyleUtils.Spacing(12)};
  }
`;

export const Button = styled(Link)`
  ${StyleUtils.PrimaryButtonContainer};
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.purple};

  max-width: 256px;
  width: 50%;

  position: absolute;
  bottom: 100px;
  margin: 0 auto;

  ${up('md')} {
    position: relative;
    bottom: 0;
  }
`;
