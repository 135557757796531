import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import * as StyleUtils from 'styles/utils';

const MenuIndicator = styled.div`
  border-top: 2px solid ${props => props.theme.colors.charcoal};
  width: 35px;

  &:before,
  &:after {
    background: ${props => props.theme.colors.charcoal};
    content: '';
    display: block;
    height: 2px;
    margin-top: ${StyleUtils.Spacing(1)};
    width: 100%;
  }

  &:after {
    width: 80%;
  }

  ${up('md')} {
    display: none;
  }
`;

export default MenuIndicator;
