import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Home, Register, Login, Dashboard, Welcome } from 'pages';
import Me from 'components/Me/Me';
import { UserStore } from 'store/User';
import { useAuthState } from 'context/AuthProvider';
import Onboarding from '../pages/Onboarding';
import MarketingHome from '../pages/MarketingHome';
import NotFound from '../pages/errors/NotFound';
import AuthRedirect from './AuthRedirect';
import { useGetOrganisationExistsQuery } from '../graphql';
import 'focus-visible';

const App = () => {
  const { loggedIn } = useAuthState();

  /**
   * Set an accurate vh size on mobile.
   * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   *
   */
  useEffect(() => {
    const resizeListener = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', resizeListener);
    resizeListener();

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const [, , subdomain] = window.location.hostname
    .replace('www.', '')
    .split('.')
    .reverse();

  const { error } = useGetOrganisationExistsQuery({
    variables: { input: { organisation: `${subdomain}` } },
  });

  const isStagingRoot = window.location.host === 'hrosarinn.aranja.com';

  if (!subdomain || isStagingRoot) {
    // They are not at an org subdomain, but might be at the register route
    return (
      <UserStore>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/welcome">
            <Welcome />
          </Route>
          <Route>
            <MarketingHome />
          </Route>
        </Switch>
      </UserStore>
    );
  }

  if (error) {
    // This organisation does not exist
    return <NotFound fullScreen />;
  }

  return (
    <UserStore>
      {loggedIn ? (
        <Me>
          <Switch>
            <Route path="/login">
              <Redirect to="/" />
            </Route>
            <Route path="/onboarding">
              <Onboarding />
            </Route>
            <Route>
              <Dashboard />
            </Route>
          </Switch>
        </Me>
      ) : (
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/welcome">
            <Welcome />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/compliments/:id">
            <AuthRedirect />
          </Route>
          <Route>
            <Home />
          </Route>
        </Switch>
      )}
    </UserStore>
  );
};

export default App;
