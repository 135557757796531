import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import * as StyleUtils from 'styles/utils';

export const TopNav = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.gray};
  border-bottom: 1px solid ${props => props.theme.colors.borderGray};
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: ${StyleUtils.Spacing(2, 2)};
  width: 100%;

  ${up('md')} {
    margin: ${StyleUtils.Spacing(0, 2)};
    padding: ${StyleUtils.Spacing(2, 0)};
  }
`;

export const Right = styled.div`
  ${StyleUtils.Flex};
  align-items: center;
`;
