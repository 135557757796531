import gql from 'graphql-tag';
import { COMPLIMENT_FRAGMENT } from '../compliments';

const DASHBOARD_FEED_FRAGMENT = gql`
  fragment DashboardFeedFragment on UserFeedPayload {
    entries {
      ...ComplimentFragment
    }
    pageInfo {
      hasNextPage
      cursor
    }
  }

  ${COMPLIMENT_FRAGMENT}
`;

export const GET_DASHBOARD_HOME = gql`
  query GetDashboardHome {
    me {
      user {
        id
        name
        feed(filter: { limit: 10 }) {
          ...DashboardFeedFragment
        }
      }
    }
    users {
      users {
        id
        name
        isStar
        isOnboard
      }
    }
    departments {
      departments {
        id
        name
      }
    }
  }

  ${DASHBOARD_FEED_FRAGMENT}
`;

export const GET_MORE_FEED = gql`
  query GetMoreDashboardFeed($cursor: String!) {
    me {
      user {
        feed(filter: { limit: 10, cursor: $cursor }) {
          ...DashboardFeedFragment
        }
      }
    }
  }

  ${DASHBOARD_FEED_FRAGMENT}
`;
