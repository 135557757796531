import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserDispatch } from '../../store/User';
import { LoadingContainer } from '../Loading/styles';
import Loading from '../Loading';
import { useMeQuery } from '../../graphql';

interface MeProps {
  children: ReactNode;
}

const Me = ({ children }: MeProps) => {
  const { i18n } = useTranslation();
  const dispatch = useUserDispatch();

  const { error, data, loading } = useMeQuery();

  if (error) {
    throw error;
  }

  useEffect(() => {
    if (data) {
      const {
        me: { user },
      } = data;

      dispatch({
        type: 'load',
        payload: user,
      });

      i18n.changeLanguage(user.locale);
    }
  }, [data, dispatch, i18n]);

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (
    data &&
    data.organisation &&
    data.organisation.organisation.origin !== window.location.origin
  ) {
    window.location.href = data.organisation.organisation.origin;
    return null;
  }

  return <>{children}</>;
};

export default Me;
