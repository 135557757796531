import React, {
  useReducer,
  useContext,
  useRef,
  createContext,
  useMemo,
} from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
// import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { Observable } from 'apollo-link';

import Log from 'utils/Log';

type TokenPayload = { token: string; expiry: Date };
type ErrorPayload = { error: Error };
type Action =
  | { type: 'login'; payload: TokenPayload }
  | { type: 'logout' }
  | { type: 'error'; payload: ErrorPayload };
type Dispatch = (action: Action) => void;

type State = {
  loggedIn: boolean;
  loginError?: Error;
};

const unauthenticatedCodes = [
  'userNotLoggedIn',
  'alreadyLoggedIn',
  'invalidAuthToken',
  'missingAuthToken',
];

const AuthStateContext = createContext<State | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

const promiseToObservable = <T extends unknown>(promise: Promise<T>) =>
  new Observable<T>(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err),
    );
  });

const refreshToken = async () => {
  try {
    const result = await fetch(process.env.REACT_APP_API_URL!, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `mutation Refresh {
                          refresh {
                            refresh{
                              token
                              expiry
                            }
                          }
                        }`,
      }),
    });
    const res = await result.json();

    if (!res || !res.data || !res.data.refresh) {
      throw new Error(res.errors[0].message || '');
    }

    return res.data.refresh.refresh;
  } catch (error) {
    return null;
  }
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  const accessToken = useRef<string | null>(null);
  const accessTokenExpiry = useRef<Date | null>(null);

  const [state, dispatch] = useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'login': {
          Log.info('Logged in!', 'AuthProvider');
          Log.info(
            `{"authorization": "Bearer ${action.payload.token}"}`,
            'AuthProvider',
          );

          accessToken.current = action.payload.token;
          accessTokenExpiry.current = action.payload.expiry;

          return { loggedIn: true };
        }
        case 'logout': {
          Log.info('Logged out!', 'AuthProvider');
          accessToken.current = '';
          accessTokenExpiry.current = null;
          return { loggedIn: false };
        }
        case 'error':
          return {
            ...state,
            loginError: action.payload.error,
          };
        default: {
          return state;
        }
      }
    },
    {
      loggedIn: false,
    },
  );

  const getNewToken = async () => {
    if (accessToken.current) {
      return `Bearer ${accessToken.current}`;
    } else {
      return '';
    }
  };

  const client = useMemo(() => {
    // const httpLink = new HttpLink({
    //   uri: process.env.REACT_APP_API_URL,
    //   credentials: 'include',
    // });

    const errorLink = onError(
      // @ts-ignore
      ({ graphQLErrors, networkError, response, operation, forward }) => {
        if (graphQLErrors) {
          for (const graphQLError of graphQLErrors) {
            const { message, locations, path, extensions } = graphQLError;
            Log.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              'AuthProvider',
            );

            if (
              message === 'Expired auth token' ||
              (extensions && unauthenticatedCodes.includes(extensions.code))
            ) {
              return promiseToObservable(refreshToken()).flatMap(
                // @ts-ignore
                refresh => {
                  if (refresh) {
                    dispatch({
                      type: 'login',
                      payload: {
                        token: refresh.token,
                        expiry: new Date(refresh.expiry),
                      },
                    });

                    return forward(operation);
                  }
                  dispatch({
                    type: 'logout',
                  });
                  return Observable.of(response);
                },
              );
            }
          }
        }
        if (networkError) {
          Log.error(`[Network error]: ${networkError}`, 'AuthProvider');
        }
      },
    );

    const authLink = setContext(async (_, { headers, ...context }) => {
      const authorization = await getNewToken();

      return {
        headers: {
          ...headers,
          authorization,
        },
        ...context,
      };
    });

    const uploadLink = createUploadLink({
      uri: process.env.REACT_APP_API_URL,
      credentials: 'include',
    });

    return new ApolloClient({
      link: errorLink.concat(authLink.concat(uploadLink)),
      cache: new InMemoryCache(),
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <AuthStateContext.Provider value={state}>
        <AuthDispatchContext.Provider value={dispatch}>
          {children}
        </AuthDispatchContext.Provider>
      </AuthStateContext.Provider>
    </ApolloProvider>
  );
}

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthProvider');
  }
  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within an AuthProvider');
  }
  return context;
}

export default AuthProvider;
