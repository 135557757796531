import * as React from 'react';

function AvatarIcon() {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none">
      <path
        opacity={0.15}
        d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
        fill="#fff"
      />
      <path
        d="M16 15.647a4.942 4.942 0 004.936-4.936A4.942 4.942 0 0016 5.775a4.942 4.942 0 00-4.937 4.936A4.942 4.942 0 0016 15.647zM23.13 25.046v-2.268a4.942 4.942 0 00-4.936-4.936h-4.389a4.942 4.942 0 00-4.936 4.936v2.268"
        stroke="#7D5AD7"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AvatarIcon;
