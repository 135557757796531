import { Editor, Point, Range, Transforms } from 'slate';

const withBreakReset = ({ types }: { types: string[] }) => <T extends Editor>(
  editor: T,
) => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const parent = Editor.above(editor, {
        match: n => types.includes(String(n.type)),
      });

      if (parent) {
        const [, parentPath] = parent;
        const parentEnd = Editor.end(editor, parentPath);

        if (Point.equals(selection.anchor, parentEnd)) {
          Transforms.splitNodes(editor, {
            always: true,
          });
          Transforms.setNodes(editor, { type: 'paragraph' });
          return;
        }
      }
    }

    insertBreak();
  };

  return editor;
};

export default withBreakReset;
