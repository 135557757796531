import React, { FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import theme from '../../../styles/HrosarinnTheme';
import * as Wizard from '../../../components/Wizard';
import * as Form from '../../../components/Form';
import { useUserState } from '../../../store/User';

import StarSmiley from '../../../icons/star-smiley.svg';
import { useUpdateUserMutation } from '../../../graphql';
import { assertNonNull } from '../../../types/assert';
import { LanguagePicker } from 'components';

const OnboardUser = () => {
  const { t } = useTranslation();
  const { id, name: initialName } = useUserState();
  const history = useHistory();
  const [name, setName] = useState(initialName || '');
  const [updateUser] = useUpdateUserMutation();

  const onSubmit: FormEventHandler = async event => {
    event.preventDefault();
    assertNonNull(id, 'Missing user id');

    await updateUser({ variables: { input: { id, name, isOnboard: true } } });

    history.push('/');
  };

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley src={StarSmiley} alt="Smiley" />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form onSubmit={onSubmit}>
          <Wizard.Fields>
            <Wizard.Header>
              <Wizard.Heading>
                {t('onboarding.onboardUser.title', { name })}
              </Wizard.Heading>
              <Wizard.Subheading>
                {t('onboarding.onboardUser.copy')}
              </Wizard.Subheading>
            </Wizard.Header>
            <Form.InputField
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              type="text"
              required
              label={t('onboarding.onboardUser.fullName')}
            />
          </Wizard.Fields>
          <Wizard.Button>{t('onboarding.onboardUser.cta')}</Wizard.Button>
        </Wizard.Form>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

export default OnboardUser;
