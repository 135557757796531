import styled from 'styled-components';

import { Type } from 'components/Typography';
import { theme, fluidType } from '../../../../../../styles';

import * as StyleUtils from 'styles/utils';

export const CardListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: ${StyleUtils.Spacing(2, 2, 1)};
  border-radius: ${({ theme }) => theme.borderRadius.br3};
  margin-bottom: ${StyleUtils.Spacing(3)};
  font-family: ${({ theme }) => theme.fonts.body};
`;

type OL = {
  start?: number;
  color?: string;
};

export const OL = styled.ol<OL>`
  list-style: none;
  counter-reset: my-awesome-counter ${({ start }) => (start ? start : '')};
  min-height: 200px;

  & > li {
    counter-increment: my-awesome-counter;
    padding: ${StyleUtils.Spacing(1, 2)};
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  & > li::before {
    content: counter(my-awesome-counter) '. ';
    font-weight: 700;
    padding-right: 8px;
  }

  &.withHighlight > li:first-child {
    padding: ${StyleUtils.Spacing(2, 2)};
    margin-bottom: ${StyleUtils.Spacing(1)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};

    &::before {
      font-size: ${fluidType(
        18,
        24,
        theme.breakpoints.xs,
        theme.breakpoints.xl,
        'px',
      )}}
      color: ${({ color }) => (color ? color : '')};
    }
  }
`;

type LiInner = {
  color?: string;
};

export const LiInner = styled.div<LiInner>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  align-items: flex-start;
  color: ${({ color }) => (color ? color : 'auto')};
`;

type Field = {
  isHighlight?: boolean;
};

export const Name = styled(Type).attrs((props: Field) => ({
  min: props.isHighlight ? 18 : 16,
  max: props.isHighlight ? 24 : 18,
  weight: props.isHighlight ? 700 : 400,
}))<Field>`
  &:hover {
    text-decoration: underline;
  }
`;

export const Count = styled(Type).attrs((props: Field) => ({
  min: props.isHighlight ? 18 : 16,
  max: props.isHighlight ? 24 : 18,
  weight: 700,
}))<Field>``;

export const Label = styled(Type).attrs({ min: 16, max: 16 })`
  flex: 1;
  text-align: center;
  padding: ${StyleUtils.Spacing(3, 2, 2)};
  border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
`;

export const EmptyState = styled.div`
  ${StyleUtils.FlexColumn}
  ${StyleUtils.CenterContent}
  padding: ${StyleUtils.Spacing(4)};
`;
