import gql from 'graphql-tag';
import { DashboardFeedFragmentFragmentDoc } from '../../../../graphql';

export const GET_PROFILE = gql`
  query User($input: UserInput, $cursor: String) {
    user(input: $input) {
      user {
        id
        name
        complimentsReceived {
          id
        }
        complimentsSent {
          id
        }
        isStar
        stars {
          stars {
            id
          }
        }
        feed(filter: { limit: 10, cursor: $cursor }) {
          ...DashboardFeedFragment
        }
      }
    }
  }
  ${DashboardFeedFragmentFragmentDoc}
`;

export const GET_MORE_PROFILE_FEED = gql`
  query GetMoreProfileFeed($input: UserInput, $cursor: String!) {
    user(input: $input) {
      user {
        feed(filter: { limit: 10, cursor: $cursor }) {
          ...DashboardFeedFragment
        }
      }
    }
  }
  ${DashboardFeedFragmentFragmentDoc}
`;
