import styled from 'styled-components';

import * as StyleUtils from '../../../../styles/utils';

export * from '../styles';

export const AddButton = styled.button`
  color: ${props => props.theme.colors.mint};
  cursor: pointer;
  margin: ${StyleUtils.Spacing(2, 2)};
`;
