import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { mb, mt } from 'styled-components-spacing';
import { Type } from './Typography';
import * as StyleUtils from '../styles/utils';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.charcoal};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 100vh;
`;

type SmileyContainer = {
  bg: string;
};

export const SmileyContainer = styled.div<SmileyContainer>`
  align-items: center;
  background-color: ${({ bg }) => bg};
  display: none;
  flex-direction: column;
  justify-content: center;
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 50%;

  ${up('lg')} {
    display: flex;
  }
`;

export const Smiley = styled.img`
  height: auto;
  margin-bottom: -2rem;
  max-width: 240px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  text-align: center;
  width: 100%;

  ${up('lg')} {
    width: 50%;
  }
`;

export const ProgressContainer = styled.div`
  padding-bottom: 2rem;
  width: 60%;
`;

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding-bottom: 5rem;
  width: 100%;
`;

export const Header = styled.div`
  ${mb({ xs: 4, md: 5 })};
  ${mt({ xs: 3 })};
`;

export const Heading = styled(Type).attrs({
  as: 'h1',
  min: 24,
  max: 32,
  weight: 700,
})`
  color: ${({ theme }) => theme.colors.mint};
  white-space: pre-line;
`;

export const Subheading = styled(Type).attrs({ min: 16, max: 18 })`
  ${mt({ xs: 3 })};
`;

type FieldsProps = {
  wide?: boolean;
};

export const Fields = styled.div<FieldsProps>`
  margin: 15vh ${StyleUtils.Spacing(3)} 30px;

  ${({ wide }) =>
    !wide &&
    css`
      ${up('lg')} {
        margin-left: 15%;
        margin-right: 15%;
      }

      ${up('xl')} {
        margin-left: 20%;
        margin-right: 20%;
      }
    `}
`;

export const Footer = styled.footer``;

export const Button = styled.button`
  ${StyleUtils.PrimaryButtonContainer};
  min-width: 200px;
`;

export const SkipLink = styled(Link)`
  display: inline-block;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.charcoal};
  margin-top: ${StyleUtils.Spacing(2)};
`;
