import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { Type } from 'components';
import * as gql from './gql';

import * as Styled from './styles';

import HeartIcon from './icons/heart.svg';

import { Reaction } from '../../../../../../../@types/graphql';
import { Link } from 'react-router-dom';

type Menu = {
  onClick: () => void;
};

const Menu = ({ onClick }: Menu) => {
  const { t } = useTranslation();

  const { error, data } = useQuery(gql.GET_ME_NOTIFICATIONS);

  if (error) {
    throw error;
  }

  if (data && data.me.user.notifications.length === 0) {
    return (
      <Styled.MenuContainer>
        <Type min={14} max={16}>
          {t('notifications.empty')}
        </Type>
      </Styled.MenuContainer>
    );
  }

  return (
    <Styled.MenuContainer>
      <Type min={14} max={16}>
        {t('notifications.today')}
      </Type>
      <Styled.Spacer v={2} />
      <Styled.Separator />
      <Styled.Spacer v={3} />
      {data &&
        data.me.user.notifications
          .slice(0, 10)
          .map((notification: Reaction, id: number) => {
            return (
              <Link key={id} to={`/compliments/${notification.compliment.id}`}>
                <Styled.MenuItem>
                  <Styled.MenuIconContainer>
                    <Styled.MenuIcon src={HeartIcon} alt="" />
                  </Styled.MenuIconContainer>
                  <Styled.Spacer h={2} />
                  <div>
                    <Type min={14} max={16} weight={700}>
                      {notification.user.name}
                    </Type>
                    <Type min={14} max={16}>
                      {t('notifications.liked')}
                    </Type>
                  </div>
                </Styled.MenuItem>
                <Styled.Spacer v={3} />
              </Link>
            );
          })}
    </Styled.MenuContainer>
  );
};

export default Menu;
