import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Type } from 'components/Typography';
import { Col } from 'react-styled-flexboxgrid';

import * as StyleUtils from 'styles/utils';

export * from '../styles';

export const StatsContainer = styled.div`
  width: 100%;
  ${up('md')} {
    width: 80%;
    margin: 0 auto;
  }
`;

export const Hero = styled.div`
${StyleUtils.Flex}
${StyleUtils.FlexColumn}
${StyleUtils.CenterContent}
    position: relative;
`;

export const TitleContainer = styled.div`
${StyleUtils.Flex}
${StyleUtils.FlexColumn}
${StyleUtils.CenterContent}
  width: 100%:
`;

export const PageTitle = styled(Type).attrs({
  min: 32,
  max: 60,
  weight: 700,
})`
  text-transform: uppercase;
  text-align: center;
  margin: ${StyleUtils.Spacing(2)};
`;

export const BottomSectionTitle = styled(Type).attrs({
  min: 24,
  max: 40,
  weight: 700,
})`
  text-transform: uppercase;
  text-align: center;
  margin: ${StyleUtils.Spacing(2)};
`;

export const Tab = styled(Col).attrs({ xs: 4 })`
  font-family: ${({ theme }) => theme.fonts.body};
  text-align: center;
`;

type TabLabel = {
  isActive?: boolean;
  activeColor?: string;
};

export const TabLabel = styled(Type).attrs({ min: 16, max: 24, weight: 700 })<
  TabLabel
>`
  color: ${({ theme, isActive, activeColor }) =>
    isActive ? activeColor : theme.colors.charcoal};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
  border-bottom: ${({ isActive }) => (isActive ? '4px' : '0')} solid
    ${({ activeColor }) => (activeColor ? activeColor : '#000')};
  display: inline-block;
  cursor: pointer;
`;
