import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const AuthRedirect = () => {
  const { pathname, search, hash } = useLocation();
  const redirectUrl = `${pathname}${search}${hash}`;
  return <Redirect to={`/login?redirect=${encodeURIComponent(redirectUrl)}`} />;
};

export default AuthRedirect;
