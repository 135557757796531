import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import { Image } from 'components';

import * as StyleUtils from 'styles/utils';

type ErrorContainerProps = {
  fullScreen?: boolean;
};

export const ErrorContainer = styled.div<ErrorContainerProps>`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  background-color: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.charcoal};
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      min-height: 100vh;
    `}
  text-align: center;
`;

export const Inner = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  flex: 1;
  position: relative;
  width: 100%;
  padding: ${StyleUtils.Spacing(2)};

  ${up('md')} {
    width: 70%;
    padding: ${StyleUtils.Spacing(12)};
  }
`;

export const HeroImage = styled.img`
  margin: ${StyleUtils.Spacing(3)};
  width: 30%;

  ${up('md')} {
    width: 30%;
  }
`;

type DecoImage = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

export const DecoImage = styled(Image)<DecoImage>`
  position: absolute;
  top: ${({ top }) => (top ? top : 'unset')};
  right: ${({ right }) => (right ? right : 'unset')};
  bottom: ${({ bottom }) => (bottom ? bottom : 'unset')};
  left: ${({ left }) => (left ? left : 'unset')};
`;
