import React, { FC, useMemo } from 'react';
import { Node } from 'slate';
import ComplimentInput, { fromPlain } from '../ComplimentInput';

type Props = {
  value: string;
  fontFamily: string;
};

const ComplimentMessage: FC<Props> = ({ value = '', fontFamily }) => {
  const parsed = useMemo<Node[]>(
    () => (value.indexOf('[') === 0 ? JSON.parse(value) : fromPlain(value)),
    [value],
  );

  return <ComplimentInput value={parsed} fontFamily={fontFamily} readOnly />;
};

export default ComplimentMessage;
