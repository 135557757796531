import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Notifications = styled.div`
  position: relative;
`;

type MenuContainer = {
  isShowing?: boolean;
};

export const InnerContainer = styled.div<MenuContainer>`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: flex-end;
  position: relative;

  &:before {
    content: '';
    background: ${({ theme }) => theme.colors.white};
    display: ${({ isShowing }) => (isShowing ? 'flex' : 'none')};
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -21px;
    right: 17px;
    transform: rotate(45deg);
    z-index: 2;
  }
`;

export const BellIcon = styled.img`
  position: relative;
  top: -2px;
`;

export const MenuContainer = styled.div<MenuContainer>`
  position: fixed;
  display: ${({ isShowing }) => (isShowing ? 'flex' : 'none')};
  top: 83px;
  width: 85%;
  left: 7.5%;
  max-height: 50%;

  ${up('md')} {
    position: absolute;
    width: 300px;
    right: -10px;
    left: unset;
    top: 135%;
    width: unset;
    max-height: 400px;
    // overflow-y: scroll;
  }
`;
