import styled from 'styled-components';
import { Spacing, Flex } from '../../styles';
import { Type } from '../Typography';

export const Button = styled.button`
  ${Flex};
  cursor: pointer;
  align-items: center;
  padding: 1px;
`;

export const Icon = styled.img.attrs({
  'aria-hidden': 'true',
})`
  height: ${Spacing(3)};
  width: ${Spacing(3)};
`;

export const Label = styled(Type)`
  margin-left: 8px;
`;
