import { useAuthDispatch } from '../context/AuthProvider';
import { useRefreshMutation, useVerifyMutation } from '../graphql';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import React, { FC, useEffect, useState } from 'react';
import { LoadingContainer } from './Loading/styles';
import Loading from './Loading';
import { assertNonNull } from '../types/assert';

const getRedirectUrl = (location: Location, query: URLSearchParams) => {
  const modifiedQuery = new URLSearchParams(query);
  modifiedQuery.delete('token');
  let queryString = modifiedQuery.toString();
  if (queryString) {
    queryString = `?${queryString}`;
  }
  return `${location.pathname}${queryString}${location.hash}`;
};

const getLoginUrl = (redirectUrl = '') => {
  // Is there a valid redirectUrl, more than '/'.
  if (redirectUrl.length > 1) {
    return `/login?redirect=${encodeURIComponent(redirectUrl)}`;
  } else {
    return '/login';
  }
};

const TryAuthenticate: FC = ({ children }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const [verify] = useVerifyMutation();
  const [refresh] = useRefreshMutation();

  const tryVerify = async (token: string) => {
    try {
      if (!token) {
        return false;
      }

      const { data } = await verify({ variables: { input: { token } } });
      assertNonNull(data, 'Should have data');

      dispatch({
        type: 'login',
        payload: {
          token: data.verify.verify.token,
          expiry: new Date(data.verify.verify.expiry),
        },
      });
    } catch (error) {
      // Verify failed.
      dispatch({
        type: 'error',
        payload: {
          error,
        },
      });
    }
  };

  const tryRefresh = async () => {
    try {
      const { data } = await refresh();
      assertNonNull(data, 'Should have data');

      const { token, expiry } = data.refresh.refresh;

      dispatch({
        type: 'login',
        payload: { token, expiry: new Date(expiry) },
      });
      return true;
    } catch (error) {
      console.warn(error);
      return false;
    }
  };

  const doAuthenticate = async () => {
    try {
      const query = new URLSearchParams(location.search);
      const token = query.get('token');
      if (!token) {
        await tryRefresh();
        return;
      }

      const redirectUrl = getRedirectUrl(location, query);

      let isAuthenticated = await tryVerify(token);
      if (!isAuthenticated) {
        isAuthenticated = await tryRefresh();
      }
      if (!isAuthenticated) {
        // Go back to the login page.
        history.replace(getLoginUrl(redirectUrl));
      } else {
        history.replace(redirectUrl);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    doAuthenticate();
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return <>{children}</>;
};

export default TryAuthenticate;
