import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { pt, mb, px, p } from 'styled-components-spacing';

import * as StyleUtils from '../../../styles/utils';
import { Type } from 'components';

export const LoadingContainer = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  flex: 1;
`;

export const Container = styled.div`
  ${StyleUtils.FlexColumn};
  min-height: 100vh;
  width: 100%;
  ${pt({ xs: 4 })};
`;

export const Form = styled.form`
  align-items: center;
  display: none;
  height: 55px;
  ${mb({ xs: 4 })};
  width: 100%;

  ${up('md')} {
    display: flex;
  }
`;

export const ListHeader = styled.div`
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  padding-bottom: 10px;
`;

export const Table = styled.table`
  width: 100%;
`;

type RowProps = {
  readonly isHeader?: boolean;
  readonly isOver?: boolean;
};

export const Row = styled.tr<RowProps>`
  ${StyleUtils.Flex};
  ${StyleUtils.CenterContent};
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: -1px;
  justify-content: flex-start;

  ${({ isHeader, isOver }) =>
    !isHeader &&
    isOver &&
    css`
      ${up('lg')} {
        &:hover {
          border-radius: 4px;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
          z-index: ${({ theme: { layers } }) => layers.l2};
        }
      }
    `}

  &[role='button'] {
    cursor: pointer;
  }
`;

const column = css`
  min-height: 60px;
  ${StyleUtils.Flex};
  align-items: center;
  ${p({ xs: 3 })};
`;

interface ColumnProps {
  readonly isOver?: boolean;
}

export const SelectColumn = styled.td`
  ${column};
  ${StyleUtils.CenterContent};
  flex: 0 0 5%;
  cursor: pointer;
  width: 5%;
`;

export const NameColumn = styled.td`
  ${column};
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 25%;
  ${px({ xs: 2 })};
`;

export const DepartmentsLabel = styled(Type)`
  display: block;

  ${up('lg')} {
    display: none;
  }
`;

export const DepartmentsColumn = styled.td`
  ${column};
  flex: 1 0 25%;
  ${px({ xs: 2 })};

  display: none;

  ${up('lg')} {
    display: block;
  }
`;

export const ComplimentsColumn = styled.td<ColumnProps>`
  ${column};
  flex: 1 0 10%;
  width: 5%;
  ${px({ xs: 2 })};

  justify-content: center;
  opacity: ${({ isOver }) => (isOver ? 1 : 0)};
  display: none;

  & > div {
    cursor: pointer;
    ${StyleUtils.Flex};
    ${StyleUtils.CenterContent}
  }

  & > div > span {
    display: none;
  }

  ${up('md')} {
    display: block;
  }

  ${up('lg')} {
    & > div > span {
      display: inline-block;
      margin-left: 8px;
    }
  }
`;

export const RoleColumn = styled.td<ColumnProps>`
  ${column};
  flex: 1 0 10%;
  text-transform: capitalize;
  ${px({ xs: 2 })};
  display: none;

  ${up('md')} {
    display: block;
  }
`;

export const StatusColumn = styled.td<ColumnProps>`
  ${column};
  flex: 1 0 10%;
  text-transform: capitalize;
  ${px({ xs: 2 })};
  display: none;

  ${up('md')} {
    display: block;
  }
`;

export const OptionsColumn = styled.td<ColumnProps>`
  ${column};
  ${StyleUtils.Flex};
  ${StyleUtils.CenterContent};
  flex: 1 0 10%;
  justify-content: flex-end;
  margin-left: auto;
  cursor: pointer;

  height: 50px;

  ${up('lg')} {
    opacity: ${({ isOver }) => (isOver ? 1 : 0)};
  }
`;
