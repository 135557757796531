import React, { SyntheticEvent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Translation } from 'react-i18next';
import { Node } from 'slate';

import { Type } from 'components';
import SentScreen from './components/Sent';

import * as Styled from './styles';
import * as gql from './gql';

import CancelIcon from './icons/cancel.svg';
import pickThemeIcon from './icons/theme.svg';
import pickFontIcon from './icons/font.svg';

import signOfTheHornsIcon from 'complimentThemes/icons/sign-of-the-horns.svg';
import starOfTheMonthIcon from 'complimentThemes/icons/star-of-the-month.svg';
import grinningFaceWithSweatIcon from 'complimentThemes/icons/grinning-face-with-sweat.svg';
import starStruckIcon from 'complimentThemes/icons/star-struck.svg';

import signOfTheHornsAnimation from 'complimentThemes/animations/sign-of-the-horns.json';
import grinningFaceWithSweatAnimation from 'complimentThemes/animations/grinning-face-with-sweat.json';
import starStruckIconAnimation from 'complimentThemes/animations/star-struck.json';

import ComplimentInput, { emptyValue, isEmpty } from '../ComplimentInput';
import { FocusOn } from 'react-focus-on';

import { Lottie } from '@crello/react-lottie';

type Recipient = {
  id: number;
  isDepartment?: boolean;
  name: string;
};

type ModalEditorProps = {};

type ModalEditorState = {
  isOpen: boolean;
  themeIndex: number;
  message: Node[];
  // Slate doesn't gracefully reset content and selection, so we'll
  // create new slate editors when reseting.
  messageVersion: number;
  to: Recipient[];
  currentTheme: {
    font: string;
    color: string;
    icon: string;
    ctaColor: string;
  };
  isStar: boolean;
  complimentSent: boolean;
};

let resolve: (value?: boolean | PromiseLike<boolean> | undefined) => void;

// TODO: Shared
type animations = {
  [key: string]: object;
};

type icons = {
  [key: string]: string;
};

type fonts = {
  [key: string]: string;
};

const animations: animations = {
  SIGN_OF_THE_HORNS: signOfTheHornsAnimation,
  GRINNING_FACE_WITH_SWEAT: grinningFaceWithSweatAnimation,
  STAR_STRUCK: starStruckIconAnimation,
};

const icons: icons = {
  SIGN_OF_THE_HORNS: signOfTheHornsIcon,
  GRINNING_FACE_WITH_SWEAT: grinningFaceWithSweatIcon,
  STAR_STRUCK: starStruckIcon,
  STAR_OF_THE_MONTH: starOfTheMonthIcon,
};

const fonts: fonts = {
  BITTER: 'BITTER',
  AVANT_GARDE: 'Avant Garde Gothic ITC',
};

const themes = [
  {
    color: '#474747',
    icon: 'SIGN_OF_THE_HORNS',
    font: fonts.BITTER,
    ctaColor: '#7D5AD7',
  },
  {
    color: '#31C5AE',
    icon: 'STAR_STRUCK',
    font: fonts.AVANT_GARDE,
    ctaColor: '#E52E64',
  },
  {
    color: '#7D5AD7',
    icon: 'GRINNING_FACE_WITH_SWEAT',
    font: fonts.AVANT_GARDE,
    ctaColor: '#31C5AE',
  },
];

const defaultTheme = themes[1];
// Shared

export default class ModalComplimentEditor extends React.Component<
  ModalEditorProps,
  ModalEditorState
> {
  formatButtonRef: React.RefObject<HTMLDivElement>;
  constructor(props: ModalEditorProps) {
    super(props);

    this.state = {
      isOpen: false,
      themeIndex: 1,
      message: emptyValue,
      messageVersion: 0,
      to: [],
      currentTheme: defaultTheme,
      isStar: false,
      complimentSent: false,
    };

    this.formatButtonRef = React.createRef<HTMLDivElement>();
  }

  show(to: Recipient[], isStar = false) {
    this.setState({ isOpen: true, to, isStar });
    if (isStar) {
      this.setState({
        currentTheme: {
          icon: 'STAR_OF_THE_MONTH',
          font: defaultTheme.font,
          color: '#EC3C69',
          ctaColor: '#31C5AE',
        },
      });
    }

    return new Promise<boolean | undefined>(res => {
      resolve = res;
    });
  }

  reset = () => {
    this.setState({
      isOpen: false,
      to: [],
      message: emptyValue,
      messageVersion: this.state.messageVersion + 1,
      themeIndex: 0,
      currentTheme: defaultTheme,
      complimentSent: false,
    });
  };

  handleCancel = () => {
    this.reset();

    resolve(undefined);
  };

  handleConfirm = () => {
    this.reset();

    resolve(true);
  };

  async onSubmit(
    isAnonymous: boolean,
    event: SyntheticEvent,
    sendCompliments: $FixMe,
  ) {
    event.preventDefault();
    const { message, isStar, currentTheme, to } = this.state;

    if (isEmpty(message)) return;

    const filteredTo = to.map(t => {
      return {
        id: t.id,
        isDepartment: t.isDepartment,
      };
    });

    await sendCompliments({
      variables: {
        input: {
          to: isStar ? filteredTo[0] : filteredTo,
          message: JSON.stringify(message),
          theme: JSON.stringify(currentTheme),
          isAnonymous,
        },
      },
    });

    this.setState({ complimentSent: true });
  }

  render() {
    const {
      isOpen,
      message,
      messageVersion,
      to,
      themeIndex,
      currentTheme,
      isStar,
      complimentSent,
    } = this.state;

    return (
      <Mutation mutation={isStar ? gql.SET_STAR : gql.SEND_COMPLIMENTS}>
        {(sendCompliments: $FixMe) => (
          <Translation>
            {t => (
              <FocusOn
                enabled={isOpen}
                onEscapeKey={() => this.handleCancel()}
                shards={[this.formatButtonRef]}
              >
                <Styled.EditorContainer
                  style={{ backgroundColor: currentTheme.color }}
                  isShowing={isOpen}
                >
                  <Styled.EditorCancelButton
                    onClick={this.handleCancel}
                    aria-label={t('dashboard.editor.a11y.closeButtonLabel')}
                  >
                    <img src={CancelIcon} alt="back arrow icon" />
                  </Styled.EditorCancelButton>
                  <Styled.Editor
                    onSubmit={e => this.onSubmit(false, e, sendCompliments)}
                  >
                    {isStar && (
                      <Styled.HeroImage src={icons[currentTheme.icon]} />
                    )}

                    {!isStar && (
                      <Styled.AnimatedIconContainer>
                        {isOpen && (
                          <Lottie
                            config={{
                              loop: false,
                              animationData: animations[currentTheme.icon],
                            }}
                          />
                        )}
                      </Styled.AnimatedIconContainer>
                    )}

                    <Styled.ToLabel>
                      <Type min={16} max={24}>
                        {t('dashboard.editor.to')}
                        {to.map(
                          (t, index) =>
                            t.name + `${index < to.length - 1 ? ', ' : ''}`,
                        )}
                        {isStar && t('dashboard.editor.starOfTheMonth')}
                      </Type>
                    </Styled.ToLabel>

                    <Styled.InputContainer>
                      <ComplimentInput
                        key={messageVersion}
                        fontFamily={currentTheme.font}
                        value={message}
                        onChange={message => this.setState({ message })}
                        placeholder={t('dashboard.editor.placeholder')}
                        ref={this.formatButtonRef}
                      />
                    </Styled.InputContainer>

                    <Styled.ButtonContainer>
                      <Styled.SendButton
                        style={{ backgroundColor: currentTheme.ctaColor }}
                      >
                        <Type min={16} max={18} weight={700}>
                          {t('dashboard.editor.sendButtonLabel')}
                        </Type>
                      </Styled.SendButton>
                      {!isStar && (
                        <Styled.SendAnonymousButton
                          onClick={e => this.onSubmit(true, e, sendCompliments)}
                        >
                          <Type min={16} max={18} weight={700}>
                            {t('dashboard.editor.sendAnonymousLabel')}
                          </Type>
                        </Styled.SendAnonymousButton>
                      )}
                    </Styled.ButtonContainer>

                    {!isStar && (
                      <Styled.ThemeUIContainer>
                        <Styled.ThemeUIButton
                          aria-label={t(
                            'dashboard.editor.a11y.changeThemeButtonLabel',
                          )}
                          onClick={e => {
                            e.preventDefault();

                            const next =
                              themeIndex === themes.length - 1
                                ? 0
                                : themeIndex + 1;
                            const nextTheme = {
                              font: themes[next].font,
                              icon: isStar
                                ? currentTheme.icon
                                : themes[next].icon,
                              color: themes[next].color,
                              ctaColor: themes[next].ctaColor,
                            };
                            this.setState({
                              themeIndex: next,
                              currentTheme: nextTheme,
                            });
                          }}
                        >
                          <img src={pickThemeIcon} alt="" />
                        </Styled.ThemeUIButton>
                        <Styled.ThemeUIButton
                          aria-label={t(
                            'dashboard.editor.a11y.changeFontButtonLabel',
                          )}
                          onClick={e => {
                            e.preventDefault();
                            const font = currentTheme.font;
                            // TODO: make this more robust for more fonts - Noam
                            const nextFont =
                              font === fonts.BITTER
                                ? fonts.AVANT_GARDE
                                : fonts.BITTER;

                            this.setState({
                              currentTheme: {
                                icon: currentTheme.icon,
                                color: currentTheme.color,
                                font: nextFont,
                                ctaColor: currentTheme.ctaColor,
                              },
                            });
                          }}
                        >
                          <img src={pickFontIcon} alt="" />
                        </Styled.ThemeUIButton>
                      </Styled.ThemeUIContainer>
                    )}
                  </Styled.Editor>
                </Styled.EditorContainer>
                {complimentSent && (
                  <FocusOn
                    enabled={complimentSent}
                    onEscapeKey={() => this.handleConfirm()}
                  >
                    <SentScreen onClose={this.handleConfirm} />
                  </FocusOn>
                )}
              </FocusOn>
            )}
          </Translation>
        )}
      </Mutation>
    );
  }
}
