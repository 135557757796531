import React from 'react';
import { Lottie } from '@crello/react-lottie';
import animationData from './animations/loading.json';
import animationDataPurple from './animations/loading-purple.json';

type Loading = {
  isPurple?: boolean;
  width?: string;
  height?: string;
};

const Loading = ({
  isPurple = false,
  width = '300px',
  height = '400px',
}: Loading) => {
  return (
    <Lottie
      config={{
        loop: true,
        autoplay: true,
        animationData: isPurple ? animationDataPurple : animationData,
      }}
      height={height}
      width={width}
    />
  );
};

export default Loading;
