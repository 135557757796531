import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import {
  Home,
  Departments,
  Employees,
  Profile,
  Compliment,
  Stats,
  SentCompliments,
} from '../screens';
import NotFound from '../../errors/NotFound';
import { useUserState } from 'store/User';

const AdminRoute = ({ children, ...rest }: $FixMe) => {
  const { role } = useUserState();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        role === 'ADMIN' || role === 'MANAGER' ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const ActiveView = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/compliments/sent/:id">
        <SentCompliments />
      </Route>
      <Route exact path="/compliments/:id">
        <Compliment />
      </Route>
      <AdminRoute path="/leaderboard">
        <Stats />
      </AdminRoute>
      <Route path="/departments">
        <Departments />
      </Route>
      <Route path="/employees">
        <Employees />
      </Route>
      <Route path="/profile/:id">
        <Profile />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default ActiveView;
