import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';

import Start from './Start';
import AddDepartments from './AddDepartments';
import AddUsers from './AddUsers';
import Finish from './Finish';
import OnboardUser from './OnboardUser';
import { useGetOrganisationUsersQuery } from '../../graphql';
import NotFound from '../errors/NotFound';

const Onboarding = () => {
  const { path } = useRouteMatch();
  const { data: organisationData } = useGetOrganisationUsersQuery();
  if (!organisationData) {
    return null;
  }
  const isFirstUser = organisationData.users.users.length <= 1;
  if (isFirstUser) {
    return (
      <Switch>
        <Route exact path={path}>
          <Start />
        </Route>
        <Route path={`${path}/departments`}>
          <AddDepartments />
        </Route>
        <Route path={`${path}/users`}>
          <AddUsers />
        </Route>
        <Route path={`${path}/finish`}>
          <Finish />
        </Route>
        <Route>
          <NotFound fullScreen />
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path}>
          <OnboardUser />;
        </Route>
        <Route>
          <NotFound fullScreen />
        </Route>
      </Switch>
    );
  }
};

export default Onboarding;
