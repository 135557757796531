import styled, { css, keyframes } from 'styled-components';
import { up } from 'styled-breakpoints';

import * as StyleUtils from '../../../../styles/utils';

import HeroPattern from './images/hero-pattern.svg';
import { Type } from 'components/Typography';
import { Row } from 'react-styled-flexboxgrid';

export * from '../styles';

export const ProfileContainer = styled.div`
  padding-top: ${StyleUtils.Spacing(5)};
  width: 100%;
  ${up('md')} {
    width: 80%;
    margin: 0 auto;
  }
`;

const container = css`
  ${StyleUtils.Flex};
  ${StyleUtils.CenterContent};
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.br2};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: ${StyleUtils.Spacing(4)};
`;

export const CTAs = styled(Row)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${up('md')} {
    flex-direction: row;
  }
`;

export const CTA = styled(Type).attrs({
  min: 16,
  max: 18,
  weight: 700,
  as: 'div',
})`
  ${StyleUtils.Flex};
  align-items: center;
  color: ${({ theme }) => theme.colors.purple};
  cursor: pointer;
`;

export const Hero = styled.div`
  ${container}
  ${StyleUtils.FlexColumn};
  align-self: center;
  background-image: url(${HeroPattern});
  background-position: center;
  background-repeat: repeat-x;
  height: 380px;
  position: relative;
  overflow: hidden;
`;

const slideMedalIn = keyframes`
  0% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
  }

  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

export const Medal = styled.img`
  animation: 1s cubic-bezier(0.34, 1.56, 0.64, 1) 0.25s normal backwards 1
    ${slideMedalIn};
  height: auto;
  position: absolute;
  top: 0;
`;

type HeroTitle = {
  readonly isStar?: boolean;
};

export const HeroTitle = styled.div<HeroTitle>`
  margin-top: ${({ isStar }) => (isStar ? StyleUtils.Spacing(9) : '0')};
  text-align: center;
  line-height: 1.5;
  transition: margin 0.25s ease;
`;

export const HeroEditIcon = styled(CTA)`
  visibility: hidden;
  position: absolute;
  left: -1.3rem;
  bottom: 100%;
  font-weight: 500;

  img {
    margin-right: 0.3rem;
  }
`;

export const HeroText = styled(Type)`
  position: relative;

  &:hover,
  &:focus {
    ${HeroEditIcon} {
      visibility: visible;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 1em;
      width: 4px;
      margin-left: 4px;
      background: ${({ theme }) => theme.colors.purple};
    }
  }
`;

const fadeTitleIn = keyframes`
  0% {
    transform: translateY(30px) scale(0.2);
    opacity: 0;
  }

  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

export const StarOfTheMonthTitle = styled.div`
  animation: 1s cubic-bezier(0.34, 1.56, 0.64, 1) 0.5s normal backwards 1
    ${fadeTitleIn};
`;

export const StatsContainer = styled.div`
  ${StyleUtils.Flex};
  align-self: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Stat = styled.div`
  ${StyleUtils.FlexColumn};
  ${container}
  justify-content: center;
  text-align: center;
  margin-bottom: ${StyleUtils.Spacing(2)};

  ${up('md')} {
    &.link:hover {
      background: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const StatLabel = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: ${StyleUtils.Spacing(3, 0, 0)};
  margin-top: ${StyleUtils.Spacing(3)};
  width: 100%;
`;

export const StatLevelInner = styled.div`
  ${StyleUtils.Flex};
  ${StyleUtils.CenterContent};
`;

export const Trophy = styled.img`
  width: 60px;
  margin-right: 10px;
  height: auto;
`;

export const Field = styled.input`
  background: ${props => props.theme.colors.white};
  border: none;
  font-size: 16px;
  margin-right: 20px;
  padding: ${StyleUtils.Spacing(2, 3)};
  width: 40%;
`;
