import styled from 'styled-components';
import { my } from 'styled-components-spacing';
import { Type } from '../../../../../../components/Typography';

export * from '../../styles';

export const InviteLabel = styled.div`
  margin-right: 10px;
  width: 160px;
`;

export const Field = styled.input`
  background: ${props => props.theme.colors.white};
  border: none;
  font-size: 16px;
  height: 55px;
  ${my({ xs: 2 })};
  padding: 20px;
  width: 40%;
`;

export const ShowInviteLink = styled.a`
  color: ${props => props.theme.colors.mint};
  cursor: pointer;
`;

export const AddButton = styled.button`
  background: ${props => props.theme.colors.mint};
  color: ${props => props.theme.colors.white};
  height: 100%;
  text-align: center;
  width: 130px;
  border-radius: 50px;
`;

export const InviteText = styled(Type).attrs({ min: 12, max: 14 })`
  margin-top: 1em;
  max-width: 400px;
  line-height: 1.5;
`;

export const Container = styled.div`
  max-width: 400px;
`;
