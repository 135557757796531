import React from 'react';

import * as Styled from './styles';
import { Spacer } from 'components';

type CardProps = {
  label: string;
  value: number;
  image?: string;
};

const StatCard = ({ label, value, image }: CardProps) => {
  return (
    <Styled.Card>
      <Styled.Value>
        {image && <Styled.Image src={image} alt="" />}
        {value}
      </Styled.Value>
      <Spacer v={2} />
      <Styled.Label>{label}</Styled.Label>
    </Styled.Card>
  );
};

export default StatCard;
