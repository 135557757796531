import styled from 'styled-components';
import * as StyleUtils from '../../styles';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.borderRadius.br3};

  margin-bottom: ${StyleUtils.Spacing(3)};
  padding: 24px;
  width: 100%;
`;

export const Header = styled.div`
  ${StyleUtils.Flex};
  align-items: center;
`;

export const Icon = styled.div`
  height: 46px;
  width: 46px;
`;

export const Meta = styled.div`
  margin-left: ${StyleUtils.Spacing(2)};
`;

export const Bottom = styled.div`
  border-top: 1px solid #e1e1e1;
  padding-top: 16px;
`;
