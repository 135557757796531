import gql from 'graphql-tag';

export const GET_ME_NOTIFICATIONS = gql`
  {
    me {
      user {
        id
        notifications {
          read
          type
          user {
            name
          }
          compliment {
            id
          }
        }
      }
    }
  }
`;
