import React from 'react';
import * as Styled from './styles';
import { Tada } from '../../icons';
import { Type, ComplimentActionButton } from 'components';
import { formatDate } from 'utils';
import { useTranslation } from 'react-i18next';

type SimpleComplimentProps = {
  sender: string;
  date: string | null;
  message: string;
  isReceiver: boolean;
  liked: boolean;
  onLike: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SimpleCompliment: React.FC<SimpleComplimentProps> = ({
  sender,
  date,
  message,
  isReceiver,
  liked,
  onLike,
}) => {
  const { i18n } = useTranslation();
  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Icon>
          <Tada />
        </Styled.Icon>
        <Styled.Meta>
          <Type min={14} max={16} weight={700}>
            {sender}
          </Type>
          <Type min={12} max={14} weight={400} top={0.5}>
            {formatDate(date, i18n.language)}
          </Type>
        </Styled.Meta>
      </Styled.Header>
      <Type
        min={14}
        max={16}
        weight={400}
        top={3}
        bottom={3}
        maxWidth={800}
        lineHeight={1.5}
      >
        {message}
      </Type>
      <Styled.Bottom>
        <ComplimentActionButton
          action="like"
          disabled={liked}
          isReceiver={isReceiver}
          liked={liked}
          onClick={onLike}
        />
      </Styled.Bottom>
    </Styled.Container>
  );
};

export default SimpleCompliment;
