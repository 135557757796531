import React, { useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Col, Row } from 'react-styled-flexboxgrid';
import { Type, Loading, Spacer, Image, Role } from 'components';
import ModalComplimentEditor from '../components/ModalComplimentEditor';
import { ErrorPage, NotFound } from 'pages/errors';

import { useTranslation } from 'react-i18next';

import * as Styled from './styles';

import { GET_MORE_PROFILE_FEED } from './gql';

import Trophy from './images/trophy.svg';
import Medal from './images/medal.svg';
import EditPencil from './images/pencil.svg';
import Pencil from '../Home/components/NewComplimentInput/icons/pencil.svg';
import StarMedal from './images/star.svg';
import { useUserState } from 'store/User/UserStore';
import InfiniteScroll from 'react-infinite-scroller';
import Compliment from '../components/Compliment';
import { useGetProfileQuery } from '../../../../graphql';
import RenameModal from './RenameModal';

const calculateLevel = (
  received: number | undefined,
  sent: number | undefined,
  stars: number | undefined,
): number => {
  if (
    typeof received === 'undefined' ||
    typeof sent === 'undefined' ||
    typeof stars === 'undefined'
  ) {
    return 0;
  }

  const r = received * 2;
  const s = sent;
  const m = stars * 10;
  return r + s + m;
};

const Profile = () => {
  const complimentEditor = useRef<ModalComplimentEditor>(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const { id: meId, role: meRole } = useUserState();
  const [isEditingUserName, setIsEditingUserName] = useState(false);

  const {
    loading,
    data: profileData,
    error,
    refetch,
    fetchMore,
  } = useGetProfileQuery({
    variables: {
      input: {
        id: (id && parseInt(id)) || -1,
        email: '',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <Loading isPurple />
      </Styled.LoadingContainer>
    );
  }

  if (error) {
    if (
      error.message ===
      'GraphQL error: Cannot return null for non-nullable field UserPayload.user.'
    ) {
      return <NotFound />;
    }

    return <ErrorPage />;
  }

  const fetchMoreFeed = () =>
    fetchMore({
      query: GET_MORE_PROFILE_FEED,
      variables: {
        input: {
          id: (id && parseInt(id)) || -1,
          email: '',
        },
        cursor: profileData?.user.user.feed.pageInfo.cursor,
      },
      updateQuery: (previousResults, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResults;
        }
        const previousEntries = previousResults.user.user.feed.entries;
        const newEntries = fetchMoreResult.user.user.feed.entries;
        const newPageInfo = fetchMoreResult.user.user.feed.pageInfo;
        return {
          ...previousResults,
          user: {
            ...previousResults.user,
            user: {
              ...previousResults.user.user,
              feed: {
                ...previousResults.user.user.feed,
                entries: [...previousEntries, ...newEntries],
                pageInfo: newPageInfo,
              },
            },
          },
        };
      },
    });

  const ctaClick = async (isStar: boolean) => {
    const modalEditor = complimentEditor?.current;
    if (modalEditor && profileData) {
      const complimented = await modalEditor.show(
        [
          {
            id: profileData.user.user.id,
            isDepartment: false,
            name: profileData.user.user.name as string,
          },
        ],
        isStar,
      );
      if (complimented) {
        // Hm what feedback to show? confetti?
        refetch();
      }
    }
  };

  const isMyData = meId === profileData?.user.user.id;
  const isAdmin = meRole === 'ADMIN';

  return (
    <Styled.ProfileContainer>
      <ModalComplimentEditor ref={complimentEditor} />
      <Row>
        <Col xs={12}>
          <Styled.Hero>
            {profileData?.user.user.isStar && <Styled.Medal src={Medal} />}
            <Styled.HeroTitle isStar={profileData?.user.user.isStar}>
              <Styled.HeroText
                min={24}
                max={40}
                weight={700}
                onClick={() => {
                  setIsEditingUserName(true);
                }}
              >
                {profileData?.user.user.name}
                {(isAdmin || isMyData) && (
                  <Styled.HeroEditIcon>
                    <Image src={EditPencil} min={16} max={18} alt="" />
                    {t('dashboard.profile.rename')}
                  </Styled.HeroEditIcon>
                )}
              </Styled.HeroText>
              {profileData?.user.user.isStar && (
                <Styled.StarOfTheMonthTitle>
                  <Type min={18} max={24} weight={400}>
                    {t('dashboard.profile.starOfTheMonth')}
                  </Type>
                </Styled.StarOfTheMonthTitle>
              )}
            </Styled.HeroTitle>
          </Styled.Hero>
        </Col>
      </Row>

      <RenameModal
        isOpen={isEditingUserName}
        onCancel={() => {
          setIsEditingUserName(false);
        }}
        onOk={async () => {
          refetch();
          setIsEditingUserName(false);
        }}
        userToEdit={{
          id: profileData!.user.user.id,
          name: `${profileData?.user.user.name}`,
        }}
      />

      <Spacer v={3} />

      {!isMyData && (
        <>
          <Styled.CTAs>
            <Styled.CTA
              onClick={() => {
                ctaClick(false);
              }}
            >
              <Image src={Pencil} min={16} max={18} />
              <Spacer h={1} />
              {t('dashboard.profile.sendCompliment')}
            </Styled.CTA>

            {!profileData?.user.user.isStar && (
              <Role expectedRole="ADMIN">
                <Spacer v={2} mdv={0} mdh={4} />
                <Styled.CTA
                  onClick={() => {
                    ctaClick(true);
                  }}
                >
                  <Image src={StarMedal} min={26} max={28} />
                  <Spacer h={1} />
                  {t('dashboard.profile.setStar')}
                </Styled.CTA>
              </Role>
            )}
          </Styled.CTAs>
          <Spacer v={3} />
        </>
      )}

      <Row>
        <Col xs={6} md={4}>
          {(isMyData || isAdmin) &&
          profileData &&
          profileData?.user.user.complimentsReceived.length > 0 ? (
            <Styled.Stat as={HashLink} to={`#feed`} className="link" smooth>
              <Type min={24} max={60} weight={700}>
                {profileData?.user.user.complimentsReceived.length}
              </Type>
              <Styled.StatLabel>
                <Type min={14} max={16} weight={400}>
                  {t('dashboard.profile.received')}
                </Type>
              </Styled.StatLabel>
            </Styled.Stat>
          ) : (
            <Styled.Stat>
              <Type min={24} max={60} weight={700}>
                {profileData?.user.user.complimentsReceived.length}
              </Type>
              <Styled.StatLabel>
                <Type min={14} max={16} weight={400}>
                  {t('dashboard.profile.received')}
                </Type>
              </Styled.StatLabel>
            </Styled.Stat>
          )}
        </Col>
        <Col xs={6} md={4}>
          {(isMyData || isAdmin) &&
          profileData &&
          profileData?.user.user.complimentsSent.length > 0 ? (
            <Styled.Stat
              as={Link}
              to={`/compliments/sent/${profileData?.user.user.id}`}
              className="link"
            >
              <Type min={24} max={60} weight={700}>
                {profileData?.user.user.complimentsSent.length}
              </Type>
              <Styled.StatLabel>
                <Type min={14} max={16} weight={400}>
                  {t('dashboard.profile.sent')}
                </Type>
              </Styled.StatLabel>
            </Styled.Stat>
          ) : (
            <Styled.Stat>
              <Type min={24} max={60} weight={700}>
                {profileData?.user.user.complimentsSent.length}
              </Type>
              <Styled.StatLabel>
                <Type min={14} max={16} weight={400}>
                  {t('dashboard.profile.sent')}
                </Type>
              </Styled.StatLabel>
            </Styled.Stat>
          )}
        </Col>
        <Col xs={12} md={4}>
          <Styled.Stat>
            <Styled.StatLevelInner>
              <Styled.Trophy src={Trophy} alt="Trophy" />
              <Type min={24} max={60} weight={700}>
                {calculateLevel(
                  profileData?.user.user.complimentsReceived.length,
                  profileData?.user.user.complimentsSent.length,
                  profileData?.user.user.stars.stars.length,
                )}
              </Type>
            </Styled.StatLevelInner>
            <Styled.StatLabel>
              <Type min={14} max={16} weight={400}>
                {t('dashboard.profile.level')}
              </Type>
            </Styled.StatLabel>
          </Styled.Stat>
        </Col>
      </Row>
      {profileData && profileData.user.user.feed.entries.length > 0 ? (
        <>
          <Spacer v={3} />
          <Row center="xs">
            <Col xs={12} lg={9} id="feed">
              <InfiniteScroll
                loadMore={fetchMoreFeed}
                hasMore={profileData?.user.user.feed.pageInfo.hasNextPage}
              >
                {profileData?.user.user.feed.entries
                  .filter(compliment => !compliment.isStar)
                  .map(compliment => (
                    <Compliment key={compliment.id} compliment={compliment} />
                  ))}
              </InfiniteScroll>
            </Col>
          </Row>
        </>
      ) : null}
    </Styled.ProfileContainer>
  );
};

export default Profile;
