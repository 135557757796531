import React from 'react';
import isUrl from 'is-url';
import { Editor, Range, Transforms } from 'slate';
import { SlatePlugin } from '@slate-plugin-system/core';
import { ReactEditor } from 'slate-react';

const LINK = 'link';

export const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: n => n.type === LINK,
  });

  return !!link;
};

export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, { match: n => n.type === LINK });
};

export const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: LINK,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const withLink = <T extends ReactEditor>(editor: T) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = element => {
    return element.type === LINK ? true : isInline(element);
  };

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const LinkPlugin = (): SlatePlugin<ReactEditor> => ({
  renderElement: ({ element, attributes, children }) => {
    if (element.type === LINK) {
      return (
        <a {...attributes} href={element.url as string}>
          {children}
        </a>
      );
    }
  },
  editorOverrides: withLink,
});

export default LinkPlugin;
