import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Sidebar, TopNav, ActiveView } from './components';

import * as Styled from './styles';
import { useUserState } from '../../store/User';

const Dashboard = () => {
  const [isSidebarShowing, setIsSidebarShowing] = useState(false);
  const { isOnboard } = useUserState();

  useEffect(() => {
    const resizeListener = () => {
      setIsSidebarShowing(false);
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarShowing(!isSidebarShowing);
  };

  if (isOnboard === false) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <Styled.DashboardContainer>
      <Styled.SidebarOverlay
        isShowing={isSidebarShowing}
        onClick={toggleSidebar}
      />
      <Styled.SidebarContainer isShowing={isSidebarShowing}>
        <Sidebar
          onSectionPress={() => {
            toggleSidebar();
          }}
        />
      </Styled.SidebarContainer>
      <Styled.MainContainer>
        <Styled.TopNavContainer>
          <TopNav onToggleSidebar={toggleSidebar} />
        </Styled.TopNavContainer>

        <ActiveView />
      </Styled.MainContainer>
    </Styled.DashboardContainer>
  );
};

export default Dashboard;
