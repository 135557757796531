import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import Modal from '../../components/Modal';

import * as Styled from './styles';
import * as Form from '../../../../../components/Form';
import * as gql from './gql';
import { Type } from 'components';

import { Department, InviteUserInput } from '../../../../../@types/graphql';
import Log from 'utils/Log';

type InviteUsersModal = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  defaultDepartment?: number;
};

type InviteUser = {
  name: string;
  email: string;
  hasError?: boolean;
};

const InviteUsersModal = ({
  isOpen,
  onCancel,
  onOk,
  defaultDepartment,
}: InviteUsersModal) => {
  const { t } = useTranslation();

  const defaultFields = [
    { name: '', email: '', hasError: false },
    { name: '', email: '', hasError: false },
  ];

  const [usersToInvite, setUsersToInvite] = useState<InviteUser[]>(
    defaultFields as InviteUser[],
  );

  const [departmentToInviteTo, setDepartmentToInviteTo] = useState(
    defaultDepartment?.toString() || '',
  );

  const [inviteUsers] = useMutation(gql.INVITE_USERS);

  const { data: departmentsData, error } = useQuery(gql.GET_DEPARTMENTS);

  if (error) {
    Log.error(error.message, 'InviteUsersModal');
    return null;
  }

  const handleInputChange = (
    index: number,
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const values = [...usersToInvite];
    const { value, name } = e.currentTarget;

    if (name === 'name') {
      values[index].name = value;
    } else {
      values[index].email = value;
      values[index].hasError = false;
    }

    setUsersToInvite(values);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...usersToInvite];
    values.splice(index, 1);
    setUsersToInvite(values);
  };

  const handleAddFields = () => {
    const values = [...usersToInvite];
    values.push({ name: '', email: '' });
    setUsersToInvite(values);
  };

  const handleSelectDepartment = (e: React.FormEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setDepartmentToInviteTo(value);
  };

  const handleSubmit = async () => {
    const department =
      departmentToInviteTo !== '' ? parseInt(departmentToInviteTo, 10) : null;
    const inviteList = usersToInvite.reduce(
      (result: InviteUserInput[], user: InviteUser) => {
        const { email, name } = user;
        if (user.email !== '') {
          result.push({
            email,
            name,
            department,
          });
        }
        return result;
      },
      [],
    );

    if (inviteList.length === 0) {
      onCancel();
      return;
    }

    await inviteUsers({
      variables: {
        input: {
          users: inviteList,
        },
      },
    });
    onOk();
  };

  const validate = () => {
    let hasErrors = false;
    const validatedUsers = usersToInvite.map(user => {
      if (!user.email) {
        return user;
      } else if (!Form.isValidEmail(user.email)) {
        hasErrors = true;
        return { ...user, hasError: true };
      }
      return user;
    });

    if (hasErrors) {
      setUsersToInvite(validatedUsers);
      return;
    }

    handleSubmit();
  };

  return (
    <Modal
      title={t('dashboard.inviteUsers.title')}
      buttonLabel={t('dashboard.inviteUsers.buttonLabel')}
      cancelButtonLabel={t('dashboard.modals.cancel')}
      isOpen={isOpen}
      onCancel={() => {
        onCancel();

        setUsersToInvite(defaultFields);
      }}
      onOk={validate}
      largeWidth={600}
    >
      <Styled.Form>
        {usersToInvite.map((user: InviteUser, index: number) => (
          <Form.FieldRow key={index}>
            <Form.InputField
              id={`email.${index}`}
              name="email"
              label={t('dashboard.inviteUsers.email')}
              value={user.email}
              onChange={event => handleInputChange(index, event)}
              error={user.hasError}
            />
            <Form.InputField
              id={`name.${index}`}
              name="name"
              label={t('dashboard.inviteUsers.name')}
              value={user.name}
              onChange={event => handleInputChange(index, event)}
            />

            <Form.RemoveButton
              alt="Remove row"
              onClick={() => {
                handleRemoveFields(index);
              }}
            />
          </Form.FieldRow>
        ))}
        <Form.AddButton onClick={handleAddFields}>
          {t('dashboard.inviteUsers.addAnother')}
        </Form.AddButton>
      </Styled.Form>
      <Styled.AssignDepartmentsContainer>
        <Type min={16} max={16} weight={400}>
          {t('dashboard.inviteUsers.assignToDepartment')}
        </Type>
        <Type min={13} max={13} weight={400} style={{ fontStyle: 'italic' }}>
          {t('dashboard.inviteUsers.assignLaterBlurb')}
        </Type>
        <Styled.SelectField
          id="department"
          label={t('dashboard.inviteUsers.departmentLabel')}
          placeholder={t('dashboard.inviteUsers.departmentPlaceholder')}
          value={departmentToInviteTo}
          onChange={handleSelectDepartment}
        >
          {departmentsData &&
            departmentsData.departments.departments.map(
              (department: Department, index: number) => (
                <option value={department.id} key={index}>
                  {department.name}
                </option>
              ),
            )}
        </Styled.SelectField>
      </Styled.AssignDepartmentsContainer>
    </Modal>
  );
};

export default InviteUsersModal;
