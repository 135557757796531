import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import { ErrorPage, NotFound } from 'pages/errors';

import * as Styled from './styles';
import Compliment from '../components/Compliment';
import { useGetComplimentQuery } from '../../../../graphql';
import Log from 'utils/Log';

type Params = {
  id: string;
};

const ComplimentScreen = () => {
  const { id } = useParams<Params>();

  const { loading, data, error } = useGetComplimentQuery({
    variables: { input: { id: parseInt(id, 10) || 0 } },
  });

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <Loading isPurple />
      </Styled.LoadingContainer>
    );
  }

  if (error) {
    Log.error(error.message, 'ComplimentScreen');
    return <ErrorPage />;
  }

  return (
    <Styled.Feed>
      <Styled.Compliments>
        {data && data.compliment.compliment ? (
          <Compliment compliment={data.compliment.compliment} />
        ) : (
          <NotFound />
        )}
      </Styled.Compliments>
    </Styled.Feed>
  );
};

export default ComplimentScreen;
