import React, { useEffect, useRef, useState } from 'react';
import ModalComplimentEditor from '../components/ModalComplimentEditor';
import NewComplimentInput from './components/NewComplimentInput';
import InfiniteScroll from 'react-infinite-scroller';
import { ErrorPage } from 'pages/errors';

import * as Styled from './styles';
import { Suggestion, UserOrDepartment } from './types';
import { useGetDashboardHomeQuery } from '../../../../graphql';
import Compliment from '../components/Compliment';
import { GET_MORE_FEED } from '../../../../graphql/views/DashboardHome';
import { SimpleCompliment, Loading } from 'components';
import { useUserDispatch, useUserState } from 'store/User';
import { useTranslation } from 'react-i18next';
import Log from 'utils/Log';

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useUserDispatch();
  const { botMessageLiked, createdAt } = useUserState();
  const complimentEditor = useRef<ModalComplimentEditor>(null);
  // let [defaultMessage, setDefaultMessage] = useState('');

  const [suggestions, setSuggestions] = useState<Suggestion[]>(
    [] as Suggestion[],
  );

  const { loading, data, error, refetch, fetchMore } = useGetDashboardHomeQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const fetchMoreFeed = () =>
    fetchMore({
      query: GET_MORE_FEED,
      variables: {
        cursor: data?.me.user.feed.pageInfo.cursor,
      },
      updateQuery: (previousResults, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResults;
        }
        const previousEntries = previousResults.me.user.feed.entries;
        const newEntries = fetchMoreResult.me.user.feed.entries;
        const newPageInfo = fetchMoreResult.me.user.feed.pageInfo;
        return {
          ...previousResults,
          me: {
            ...previousResults.me,
            user: {
              ...previousResults.me.user,
              feed: {
                ...previousResults.me.user.feed,
                entries: [...previousEntries, ...newEntries],
                pageInfo: newPageInfo,
              },
            },
          },
        };
      },
    });

  useEffect(() => {
    if (data) {
      // setDefaultMessage(
      //   `Great job signing up to Hrosarinn ${
      //     (data.me.user.name || '').split(' ')[0]
      //   }! When your co-workers send you a compliment it will show up in this feed!`,
      // );

      const departmentsSuggestions = data.departments.departments.map(d => {
        return { id: d.id, name: d.name, isDepartment: true };
      });

      const employeesSuggestions = data.users.users
        .filter(u => u.name && u.id !== data.me.user.id && u.isOnboard)
        .map(u => {
          return { id: u.id, name: u.name!, isDepartment: false };
        });

      setSuggestions([...employeesSuggestions, ...departmentsSuggestions]);
    }
  }, [data]);

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <Loading isPurple />
      </Styled.LoadingContainer>
    );
  }

  if (error) {
    Log.error(error.message, 'Dashboard/screens/Home');
    return <ErrorPage />;
  }

  return (
    <Styled.Feed>
      <Styled.Compliments>
        <ModalComplimentEditor ref={complimentEditor} />
        <NewComplimentInput
          suggestions={suggestions}
          onNewButtonClick={async (toCompliment: UserOrDepartment[]) => {
            const modalEditor = complimentEditor?.current;
            if (modalEditor) {
              const to = toCompliment.map((entry: UserOrDepartment) => {
                return {
                  id: entry.id,
                  isDepartment: entry.isDepartment,
                  name: entry.name,
                };
              });

              await modalEditor.show(to);

              refetch();
            }
          }}
        />

        {data && (
          <InfiniteScroll
            loadMore={fetchMoreFeed}
            hasMore={data.me.user.feed.pageInfo.hasNextPage}
          >
            {data.me.user.feed.entries.map(compliment => (
              <Compliment key={compliment.id} compliment={compliment} />
            ))}
          </InfiniteScroll>
        )}
        {data?.me.user.feed.entries.length === 0 ? (
          <SimpleCompliment
            sender={t('dashboard.feed.botMessage.sender')}
            date={createdAt}
            message={t('dashboard.feed.botMessage.message')}
            isReceiver={true}
            liked={botMessageLiked}
            onLike={() => dispatch({ type: 'toggleLikeBotMessage' })}
          />
        ) : null}
      </Styled.Compliments>
    </Styled.Feed>
  );
};

export default Home;
