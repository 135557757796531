import styled from 'styled-components';
import { mb } from 'styled-components-spacing';
import { Link } from 'react-router-dom';

import { Type } from '../../components';

import * as StyleUtils from '../../styles/utils';

export const HomeContainer = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};

  background-color: ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.white};

  min-height: 100vh;
`;

export const Smiley = styled.img`
  height: auto;
  ${mb({ xs: 4, md: 5 })};
  max-width: 240px;
  width: 100%;
`;

export const Headline = styled(Type)`
  ${mb({ xs: 4, md: 5 })};
  line-height: 1.25;
  text-align: center;
`;

export const SignInLink = styled(Link)`
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  ${mb({ xs: 4 })};

  max-width: 256px;
  width: 100%;
`;

export const Button = styled(Link)`
  ${StyleUtils.PrimaryButtonContainer};
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.purple};
`;
