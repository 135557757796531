import React from 'react';

export const Tada = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 77 79">
      <path
        fill="#EFD358"
        d="M62.397 55.855L22.86 16.319a1.746 1.746 0 00-2.893.682L.199 76.308a1.748 1.748 0 002.209 2.209l59.308-19.768a1.748 1.748 0 00.681-2.894z"
      ></path>
      <path
        fill="#E2AC4B"
        d="M62.397 58.327c3.412-3.412-3.227-15.582-14.827-27.182-11.6-11.6-23.77-18.24-27.182-14.827C16.976 19.73 23.614 31.9 35.214 43.5c11.6 11.6 23.77 18.239 27.183 14.827z"
      ></path>
      <path
        fill="#31C5AE"
        d="M50.781 41.914a1.748 1.748 0 01-1.572-2.492c4.396-9.346 15.385-12.58 26.716-7.863a1.747 1.747 0 11-1.345 3.226c-9.525-3.967-18.651-1.447-22.209 6.115a1.748 1.748 0 01-1.59 1.014z"
      ></path>
      <path
        fill="#EC3C69"
        d="M61.264 19.199a5.242 5.242 0 100-10.484 5.242 5.242 0 000 10.484z"
      ></path>
      <path
        fill="#474747"
        d="M68.254 69.872a3.495 3.495 0 100-6.99 3.495 3.495 0 000 6.99zM45.54 33.178a3.495 3.495 0 100-6.99 3.495 3.495 0 000 6.99z"
      ></path>
      <path
        fill="#31C5AE"
        d="M70.874 47.156a2.621 2.621 0 100-5.242 2.621 2.621 0 000 5.242z"
      ></path>
      <path
        fill="#EC3C69"
        d="M27.19 8.715a2.621 2.621 0 100-5.242 2.621 2.621 0 000 5.242zM33.306 31.43a1.748 1.748 0 01-1.048-3.146 21.013 21.013 0 006.431-25.871 1.747 1.747 0 013.212-1.377 24.42 24.42 0 01-7.547 30.044c-.302.227-.67.35-1.048.35z"
      ></path>
      <path
        fill="#31C5AE"
        d="M3.903 22.393c-.464 0-.908-.184-1.236-.512A8.735 8.735 0 0112.185 7.57a8.736 8.736 0 012.836 1.958 1.748 1.748 0 01-2.47 2.47 5.368 5.368 0 00-7.413 0 5.242 5.242 0 000 7.413 1.747 1.747 0 01-1.235 2.982z"
      ></path>
    </svg>
  );
};

export default Tada;
