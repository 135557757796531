import styled from 'styled-components';

import * as StyleUtils from '../../../../styles/utils';

export * from '../styles';

export const InviteLabel = styled.div`
  margin-right: 10px;
  width: 160px;
`;

export const Field = styled.input`
  background: ${props => props.theme.colors.white};
  border: none;
  font-size: 16px;
  margin-right: 20px;
  padding: ${StyleUtils.Spacing(2, 3)};
  width: 40%;
`;

export const AddButton = styled.button`
  ${StyleUtils.PrimaryButtonContainer};
  width: 130px;
`;
