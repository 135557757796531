import React from 'react';
import Loading from 'components/Loading';
import { ErrorPage, NotFound } from 'pages/errors';

import { useTranslation } from 'react-i18next';

import Log from 'utils/Log';

import * as Styled from './styles';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Type, Spacer } from 'components';
import { useGetSentComplimentsQuery } from '../../../../graphql';
import Compliment from '../components/Compliment';
import Medal from '../Profile/images/medal.svg';
import { useParams } from 'react-router-dom';
import { useUserState } from 'store/User/UserStore';

const SentComplimentScreen = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { id: meId, role: meRole } = useUserState();

  const { loading, data, error } = useGetSentComplimentsQuery({
    variables: {
      input: {
        id: (id && parseInt(id)) || -1,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (id && parseInt(id) !== meId && meRole !== 'ADMIN') {
    return <NotFound />;
  }

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <Loading isPurple />
      </Styled.LoadingContainer>
    );
  }

  if (error) {
    Log.error(error.message, 'SentComplimentScreen');
    return <ErrorPage />;
  }

  return (
    <Styled.SentContainer>
      <Row>
        <Col xs={12}>
          <Styled.Hero>
            <Styled.Medal src={Medal} />
            <Styled.HeroTitle>
              <Type min={24} max={40} weight={700}>
                {t('dashboard.profile.sent')}
              </Type>
              <Type min={24} max={40} weight={400}>
                {data && data.user.user.name}
              </Type>
            </Styled.HeroTitle>
          </Styled.Hero>
        </Col>
      </Row>
      <Spacer v={3} />
      <Styled.Feed>
        <Styled.Compliments>
          {data &&
            data.user.user.complimentsSent.length > 0 &&
            data.user.user.complimentsSent.map(compliment => (
              <Compliment key={compliment.id} compliment={compliment} />
            ))}
        </Styled.Compliments>
      </Styled.Feed>
    </Styled.SentContainer>
  );
};

export default SentComplimentScreen;
