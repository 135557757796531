import React from 'react';
import likeIcon from '../../icons/like.svg';
import likeOutlineIcon from '../../icons/like-outline.svg';
import comment from '../../icons/comment.svg';

import * as Styled from './styles';
import { useTranslation } from 'react-i18next';

type ActionType = 'like' | 'comment';

interface ActionButton {
  action: ActionType;
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface LikeButtonProps extends ActionButton {
  action: 'like';
  liked: boolean;
  isReceiver: boolean;
}

interface CommentButtonProps extends ActionButton {
  action: 'comment';
}

const LikeButton: React.FC<LikeButtonProps> = ({
  liked,
  isReceiver,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Button
      disabled={disabled}
      onClick={onClick}
      aria-label={t('compliment.like')}
    >
      <Styled.Icon src={liked ? likeIcon : likeOutlineIcon} />
      <Styled.Label min={14} max={16}>
        {t('compliment.like')}
      </Styled.Label>
    </Styled.Button>
  );
};

const CommentButton: React.FC<CommentButtonProps> = ({ onClick, disabled }) => {
  return (
    <Styled.Button onClick={onClick} aria-label="comment" disabled={disabled}>
      <Styled.Icon src={comment} />
      <Styled.Label min={14} max={16}>
        Comment
      </Styled.Label>
    </Styled.Button>
  );
};

export const ComplimentActionButton: React.FC<
  LikeButtonProps | CommentButtonProps
> = props => {
  switch (props.action) {
    case 'like':
      return <LikeButton {...props} />;
    case 'comment':
      return <CommentButton {...props} />;
  }
};

export default ComplimentActionButton;
