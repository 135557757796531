import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { Role } from 'components';
import SidebarEntry from './SidebarEntry';

import * as Styled from './styles';
import * as gql from './gql';

import HomeIcon from './icons/Home.svg';
import DepartmentsIcon from './icons/Departments.svg';
import EmployeesIcon from './icons/Employees.svg';
import LeaderboardIcon from './icons/Leaderboard.svg';
import Log from 'utils/Log';

interface SidebarProps {
  onSectionPress: () => void;
}

const Sidebar = ({ onSectionPress }: SidebarProps) => {
  const { t } = useTranslation();
  const [organisationName, setOrganisationName] = useState('');
  const { data: organisationData, error } = useQuery(gql.GET_ORGANISATION);

  useEffect(() => {
    if (organisationData) {
      setOrganisationName(organisationData.organisation.organisation.url);
    }
  }, [organisationData]);

  if (error) {
    Log.error(error.message, 'Sidebar');
  }

  return (
    <Styled.Sidebar>
      <div>
        <Styled.OrgNameContainer>
          <Styled.OrgName min={18} max={24} weight={700}>
            {organisationName}
          </Styled.OrgName>
        </Styled.OrgNameContainer>

        <Styled.MainEntries>
          <SidebarEntry
            onPress={() => onSectionPress()}
            icon={HomeIcon}
            title={t('dashboard.screens.home')}
            to="/"
          />
          <Role expectedRole="ADMIN">
            <SidebarEntry
              onPress={() => onSectionPress()}
              icon={LeaderboardIcon}
              title={t('dashboard.screens.stats')}
              to="/leaderboard"
            />
          </Role>
          <SidebarEntry
            onPress={() => onSectionPress()}
            icon={DepartmentsIcon}
            title={t('dashboard.screens.departments')}
            to="/departments"
          />
          <SidebarEntry
            onPress={() => onSectionPress()}
            icon={EmployeesIcon}
            title={t('dashboard.screens.employees')}
            to="/employees"
          />
        </Styled.MainEntries>
      </div>
      {/* TODO: This section is not needed for V1, but it will comeback later */}
      {/* <Role expectedRole="ADMIN">
        <Styled.SettingsEntry>
          <Styled.SettingsEntryInner>
            <SidebarEntry
              onPress={() => onSectionPress()}
              icon={SettingsIcon}
              title={t('dashboard.screens.settings')}
              to="/settings"
            />
          </Styled.SettingsEntryInner>
        </Styled.SettingsEntry>
      </Role> */}
    </Styled.Sidebar>
  );
};

export default Sidebar;
