import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../screens/components/Modal';

import * as Styled from './styles';
import { IMPORT_USERS } from 'graphql/user';
import ErrorMessage from 'components/ErrorMessage';

import { motion } from 'framer-motion';
import { Type, Spacer, Loading } from 'components';

type ImportModal = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const textAnimation = { scale: [0.75, 1], opacity: [0, 1] };
const textTransition = { ease: 'backOut', duration: 1 };

const ImportModal = ({ isOpen, onCancel, onOk }: ImportModal) => {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState(false);

  const [importUsers] = useMutation(IMPORT_USERS);

  return (
    <Modal
      title={t('dashboard.profile.importModal.title')}
      buttonLabel={
        success
          ? t('dashboard.modals.done')
          : t('dashboard.profile.importModal.buttonLabel')
      }
      isOpen={isOpen}
      onCancel={() => {
        setError(undefined);
        setSuccess(false);
        onCancel();
      }}
      onOk={async () => {
        if (success) {
          setSuccess(false);
          onOk();
          return;
        }

        if (
          fileInput.current &&
          fileInput.current.files &&
          fileInput.current.files.length
        ) {
          try {
            setLoading(true);
            await importUsers({
              variables: {
                input: {
                  file: fileInput.current.files[0],
                },
              },
            });
            setLoading(false);
            setSuccess(true);
            setError(undefined);
          } catch (e) {
            setLoading(false);
            setError(e);
          }
        }
      }}
    >
      <Styled.File
        ref={fileInput}
        id="importUsers"
        type="file"
        accept=".csv"
        required
        style={{ width: '100%' }}
      />
      {loading && <Loading isPurple width="100%" height="150px" />}
      {error && <Spacer v={6} mdv={5} />}
      <ErrorMessage error={error} />
      {success && (
        <Styled.Inner>
          <motion.div
            initial={{ opacity: 0 }}
            animate={textAnimation}
            transition={{ ...textTransition, delay: 0.2 }}
          >
            <Type min={14} max={18} weight={700}>
              <Styled.Purple>
                {t('dashboard.profile.importModal.congratulations')}
              </Styled.Purple>
            </Type>
          </motion.div>
          <Spacer v={3} mdv={2} />
          <motion.div
            initial={{ opacity: 0 }}
            animate={textAnimation}
            transition={{ ...textTransition, delay: 0.4 }}
          >
            <Type
              min={14}
              max={18}
              weight={700}
              style={{ whiteSpace: 'pre-line' }}
            >
              {t('dashboard.profile.importModal.blurb')}
            </Type>
          </motion.div>
        </Styled.Inner>
      )}
    </Modal>
  );
};

export default ImportModal;
