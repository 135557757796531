import React, { useState } from 'react';
import ReactTags from 'react-tag-autocomplete';
import { useTranslation } from 'react-i18next';

import { Type } from 'components';

import * as Styled from './styles';

import newComplimentDecoration from './icons/new-compliment-decoration.svg';
import newComplimentDecorationBottom from './icons/new-compliment-decoration-bottom.svg';
import starEyesAvatar from '../../icons/star-eyes-avatar.svg';
import pencilIcon from './icons/pencil.svg';
import searchIcon from './icons/search.svg';

import { Suggestion, UserOrDepartment } from '../../types';
import VisuallyHidden from 'components/VisuallyHidden';

type NewComplimentInput = {
  onNewButtonClick: (toCompliment: UserOrDepartment[]) => Promise<void>;
  suggestions: Suggestion[];
};

const NewComplimentInput = ({
  onNewButtonClick,
  suggestions,
}: NewComplimentInput) => {
  const { t } = useTranslation();

  const [toCompliment, setToCompliment] = useState<UserOrDepartment[]>(
    [] as UserOrDepartment[],
  );

  const handleAdd = (member: UserOrDepartment) => {
    const assigned = [...toCompliment, member];
    setToCompliment(assigned);
  };

  const handleDelete = (i: number) => {
    const assignedMembers = toCompliment.slice(0);
    assignedMembers.splice(i, 1);
    setToCompliment(assignedMembers);
  };

  return (
    <Styled.Container>
      <Styled.TopDecoration src={newComplimentDecoration} alt="" />
      <Styled.BottomDecoration src={newComplimentDecorationBottom} alt="" />

      <Styled.LargeHeader>
        <Type min={14} max={18} weight={700}>
          {t('dashboard.feed.new.title')}
        </Type>
      </Styled.LargeHeader>

      <Styled.BodyOuter>
        <Styled.SmallHeader>
          <Styled.SmallHeaderInner>
            <Type min={14} max={18} weight={700}>
              {t('dashboard.feed.new.title')}
            </Type>
          </Styled.SmallHeaderInner>
        </Styled.SmallHeader>

        <Styled.BlurbLarge>
          <Styled.ComplimentMetaAvatar src={starEyesAvatar} alt="" />
          <Styled.ComplimentMetaTextContainer>
            <Styled.ComplimentMetaTitle>
              <Type min={14} max={16} weight={700}>
                {t('dashboard.feed.new.innerTitle')}
              </Type>
            </Styled.ComplimentMetaTitle>
            <Styled.ComplimentMetaSubTitle>
              <Type min={12} max={14} weight={400}>
                {t('dashboard.feed.new.innerDescription')}
              </Type>
            </Styled.ComplimentMetaSubTitle>
          </Styled.ComplimentMetaTextContainer>
        </Styled.BlurbLarge>

        <Styled.BodyInner htmlFor="name-search">
          <Styled.SearchIcon src={searchIcon} alt="" aria-hidden={true} />
          <VisuallyHidden>{t('dashboard.feed.new.placeholder')}</VisuallyHidden>
          <div style={{ width: '100%', height: 'auto' }}>
            <ReactTags
              ariaLabel={t('dashboard.feed.new.a11y.ariaLabel')}
              inputAttributes={{ id: 'name-search' }}
              tags={toCompliment}
              suggestions={suggestions}
              noSuggestionsText={t('dashboard.feed.new.noResults')}
              handleDelete={handleDelete}
              handleAddition={handleAdd}
              placeholder={t('dashboard.feed.new.placeholder')}
              autofocus={false}
              minQueryLength={1}
            />
          </div>
        </Styled.BodyInner>

        <Styled.FooterSmall>
          <Styled.FooterSmallButton
            disabled={toCompliment.length === 0}
            onClick={async () => {
              if (toCompliment.length === 0) return;
              await onNewButtonClick(toCompliment);
              setToCompliment([]);
            }}
          >
            {t('dashboard.feed.new.write')}
          </Styled.FooterSmallButton>
        </Styled.FooterSmall>

        <Styled.FooterLarge>
          <Styled.ComposeButtonLarge
            disabled={toCompliment.length === 0}
            onClick={async () => {
              if (toCompliment.length === 0) return;
              await onNewButtonClick(toCompliment);
              setToCompliment([]);
            }}
          >
            <Styled.Like src={pencilIcon} />
            <Type min={14} max={16} weight={700}>
              {t('dashboard.feed.new.compose')}
            </Type>
          </Styled.ComposeButtonLarge>
        </Styled.FooterLarge>
      </Styled.BodyOuter>
    </Styled.Container>
  );
};

export default NewComplimentInput;
