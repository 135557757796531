import styled from 'styled-components';

import * as StyleUtils from 'styles/utils';

export const LoadingContainer = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};

  background-color: #7d5ad7;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
`;
