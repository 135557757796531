import { SlatePlugin } from '@slate-plugin-system/core';
import { ReactEditor } from 'slate-react';
import * as Styled from '../styles';
import { toggleMark } from '../format';
import { Transforms } from 'slate';
import withBreakEmptyReset from '../withBreakEmptyReset';
import withDeleteStartReset from '../withDeleteStartReset';
import withBreakReset from '../withBreakReset';
import React from 'react';

const FormatPlugin = (): SlatePlugin<ReactEditor> => ({
  // eslint-disable-next-line react/display-name
  renderElement: props => <Styled.Element {...props} />,
  // eslint-disable-next-line react/display-name
  renderLeaf: props => <Styled.Leaf {...props} />,
  onDOMBeforeInput: (event, editor) => {
    // @ts-ignore
    switch (event.inputType) {
      case 'formatBold':
        return toggleMark(editor, 'bold');
      case 'formatItalic':
        return toggleMark(editor, 'italic');
      case 'formatUnderline':
        return toggleMark(editor, 'underline');
    }
  },
  editorOverrides: editor => {
    const nestedTypes = ['block_quote', 'list_bulleted', 'list_numbered'];
    const onUnwrap = () => {
      Transforms.unwrapNodes(editor, {
        match: n => nestedTypes.includes(String(n.type)),
        split: true,
      });
    };
    editor = withBreakEmptyReset({
      types: nestedTypes,
      onUnwrap,
    })(editor);
    editor = withDeleteStartReset({
      types: nestedTypes,
      onUnwrap,
    })(editor);
    editor = withBreakReset({
      types: ['heading'],
    })(editor);
    return editor;
  },
});

export default FormatPlugin;
