import React from 'react';

import { Spacer } from 'components';
import MenuIndicator from './MenuIndicator';
import Avatar from './Avatar';
import Notifications from './Notifications';

import * as Styled from './styles';

interface TopNavProps {
  onToggleSidebar: () => void;
}

const TopNav = ({ onToggleSidebar }: TopNavProps) => {
  return (
    <Styled.TopNav>
      <div>
        <MenuIndicator onClick={onToggleSidebar} />
      </div>

      <Styled.Right>
        <Notifications />
        <Spacer h={4} />
        <Avatar />
      </Styled.Right>
    </Styled.TopNav>
  );
};

export default TopNav;
