import styled from 'styled-components';
import { fluidProp } from 'styles/utils';

interface ImageProps {
  min: number;
  max: number;
}

export const Image = styled.img<ImageProps>`
  ${props =>
    fluidProp(
      'width',
      props.min,
      props.max,
      props.theme.breakpoints.xs,
      props.theme.breakpoints.xl,
      'px',
    )};

  height: auto;
`;
