import { Editor, Text, Element, Transforms } from 'slate';

const LIST_TYPES = ['list_numbered', 'list_bulleted'];

export const isTitle = (editor: Editor) => {
  const [match] = Editor.nodes(editor, {
    match: n => Element.isElement(n) && n.type === 'title',
    mode: 'all',
  });
  return !!match;
};

export const isMarkActive = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: n => n[format] === true,
    mode: 'all',
  });
  return !!match;
};

export const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: Text.isText, split: true },
  );
};

export const isBlockActive = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!match;
};

export const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  const isBlockquote = format === 'block_quote';

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(String(n.type)),
    split: true,
  });

  Transforms.unwrapNodes(editor, {
    match: n => n.type === 'block_quote',
    split: true,
  });

  Transforms.setNodes(editor, {
    type:
      isActive || isBlockquote ? 'paragraph' : isList ? 'list_item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }

  if (!isActive && isBlockquote) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
