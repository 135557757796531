import 'url-search-params-polyfill';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom';
import AuthProvider from './context/AuthProvider';
import './i18n';
import App from './App';

import { ThemeProvider } from 'styled-components';
import ErrorPage from './pages/errors/ErrorPage';
import HrosarinnTheme from './styles/HrosarinnTheme';
import GlobalStyle from './styles/GlobalStyles';
import { BrowserRouter as Router } from 'react-router-dom';
import TryAuthenticate from './components/TryAuthenticate';
import HttpsRedirect from './HttpsRedirect';

const bugsnagClient = bugsnag('a377e424b4e2f862f811215fd455bac3');
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');
const isDev = process.env.NODE_ENV !== 'production';

if (isDev) {
  localStorage.setItem('debug', 'hrosarinn:*');
}

ReactDOM.render(
  <ThemeProvider theme={HrosarinnTheme}>
    <GlobalStyle />
    <HttpsRedirect disabled={isDev}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <AuthProvider>
          <Router>
            <TryAuthenticate>
              <App />
            </TryAuthenticate>
          </Router>
        </AuthProvider>
      </ErrorBoundary>
    </HttpsRedirect>
  </ThemeProvider>,
  document.getElementById('root'),
);
