import styled, { keyframes } from 'styled-components';
import { up } from 'styled-breakpoints';
import { pt } from 'styled-components-spacing';

import * as StyleUtils from 'styles/utils';
import HeroPattern from '../Profile/images/hero-pattern.svg';

export * from '../styles';

export const SentContainer = styled.div`
  padding-top: ${StyleUtils.Spacing(5)};
  width: 100%;
  ${up('md')} {
    width: 80%;
    margin: 0 auto;
  }
`;

export const Feed = styled.div`
  ${StyleUtils.Flex};
  flex-wrap: wrap;
  ${pt({ xs: 4 })};
  justify-content: center;
  align-items: center;
`;

export const Hero = styled.div`
  ${StyleUtils.Flex};
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  align-self: center;
  background-image: url(${HeroPattern});
  background-position: center;
  background-repeat: repeat-x;
  height: 380px;
  position: relative;
  overflow: hidden;
`;

const slideMedalIn = keyframes`
  0% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
  }

  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

export const Medal = styled.img`
  animation: 1s cubic-bezier(0.34, 1.56, 0.64, 1) 0.25s normal backwards 1
    ${slideMedalIn};
  height: auto;
  position: absolute;
  top: 0;
`;

export const HeroTitle = styled.div`
  margin-top: ${StyleUtils.Spacing(9)};
  text-align: center;
  line-height: 1.5;
  transition: margin 0.25s ease;
`;

export const Compliments = styled.div`
  width: 100%;
  ${up('md')} {
    width: 80%;
  }
  ${up('lg')} {
    width: 60%;
  }
`;
