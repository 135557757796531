import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import theme from '../../../styles/HrosarinnTheme';
import * as Wizard from '../../../components/Wizard';
import { useUserState } from '../../../store/User';

import StarSmiley from '../../../icons/star-smiley.svg';
import Progress from '../../../components/Progress';
import { LanguagePicker } from 'components';

const Start = () => {
  const { t } = useTranslation();
  const { name } = useUserState();

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley src={StarSmiley} alt="Smiley" />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form>
          <Wizard.Fields>
            <Wizard.Header>
              <Wizard.Heading>
                {t('onboarding.start.title', { name })}
              </Wizard.Heading>
              <Wizard.Subheading>
                {t('onboarding.start.copy')}
              </Wizard.Subheading>
            </Wizard.Header>
          </Wizard.Fields>
          <Wizard.Button as={Link} to="/onboarding/departments">
            {t('onboarding.start.cta')}
          </Wizard.Button>
        </Wizard.Form>
        <Wizard.ProgressContainer>
          <Progress current={1} total={4} />
        </Wizard.ProgressContainer>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

export default Start;
