import React from 'react';

import { Type } from 'components';

import * as Styled from './styles';

interface SidebarEntryProps {
  icon?: $FixMe;
  title: string;
  active?: boolean;
  onPress: () => void;
  to: string;
}

const SidebarEntry: React.FC<SidebarEntryProps> = ({
  icon,
  title,
  onPress,
  to,
}) => (
  <Styled.SidebarEntry onClick={onPress} to={to} exact>
    <Styled.EntryInner>
      <Styled.EntryIcon src={icon} aria-hidden />
      <Styled.EntryLabel>
        <Type min={14} max={16} weight={500}>
          {title}
        </Type>
      </Styled.EntryLabel>
    </Styled.EntryInner>
  </Styled.SidebarEntry>
);

export default SidebarEntry;
