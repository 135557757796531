import React, { useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Type, Role, Loading } from 'components';
import { Entry } from '../components';
import EditRole from './EditRole';
import AssignDepartment from './AssignDepartment';
import ConfirmModal from '../components/ConfirmModal';
import ModalComplimentEditor from '../components/ModalComplimentEditor';
import InvitePeopleModal from '../components/InvitePeopleModal';
import { ErrorPage } from 'pages/errors';

import * as Styled from './styles';
import * as gql from './gql';
import { useUserState } from 'store/User/UserStore';

import ComplimentCta from '../components/ComplimentCta';
import ComplimentCtaIcon from '../icons/ComplimentCtaIcon.svg';
import { User, Department } from '../../../../@types/graphql';
import Log from 'utils/Log';

const listDepartmentsAsString = (deparments: Department[] | null) => {
  let result = '';
  if (deparments) {
    deparments.forEach((department, index) => {
      result += `${department.name}${
        index < deparments.length - 1 ? ', ' : ''
      }`;
    });
  }
  return result;
};

type to = {
  id: number;
  isDepartment?: boolean;
  name: string;
};

const Employees = () => {
  const complimentEditor = useRef<ModalComplimentEditor>(null);
  const confirmDeleteModal = useRef<ConfirmModal>(null);
  const confirmInviteModal = useRef<ConfirmModal>(null);
  const assignDepartmentModal = useRef<AssignDepartment>(null);
  const editRoleModal = useRef<EditRole>(null);

  const { t } = useTranslation();
  const { id: meId } = useUserState();
  const history = useHistory();

  const [selectedEntries, setSelectedEntries] = useState<to[]>([] as to[]);

  const { loading, data: employeesData, error, refetch } = useQuery(
    gql.GET_EMPLOYEES,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [isInvitingUsers, setIsInvitingUsers] = useState(false);

  const [deleteUser] = useMutation(gql.DELETE_USER);
  const [inviteUser] = useMutation(gql.INVITE_USER);

  const [assignDepartment] = useMutation(gql.ASSIGN_DEPARTMENT);
  const [updateRole] = useMutation(gql.UPDATE_ROLE);

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <Loading isPurple />
      </Styled.LoadingContainer>
    );
  }

  if (error) {
    return <ErrorPage />;
  }

  const employees = () => {
    if (!employeesData) return;

    return employeesData.users.users
      .sort((a: User, b: User) => {
        if (!a.isOnboard && b.isOnboard) {
          return 1;
        }
        if (a.isOnboard && !b.isOnboard) {
          return -1;
        }
        return 0;
      })
      .map(
        (
          { name, email, id, role, departments, isOnboard, isStar }: User,
          index: number,
        ) => {
          return (
            <Entry
              isMe={id === meId}
              key={`${index}_${id}`}
              id={id}
              isEmployee
              isStar={isStar}
              isOnboard={isOnboard}
              setSelected={(id: number) => {
                if (selectedEntries.findIndex(i => i.id === id) < 0) {
                  const to = {
                    id,
                    isDepartment: false,
                    name: name as string,
                  };
                  setSelectedEntries([...selectedEntries, to]);
                } else {
                  setSelectedEntries([
                    ...selectedEntries.filter(i => i.id !== id),
                  ]);
                }
              }}
              isSelected={selectedEntries.findIndex(i => i.id === id) > -1}
              name={name === '' ? email : name || email}
              department={listDepartmentsAsString(
                departments as Department[] | null,
              )}
              role={role}
              onCompliment={async () => {
                const modalEditor = complimentEditor?.current;
                if (modalEditor) {
                  await modalEditor.show([
                    { id, isDepartment: false, name: name as string },
                  ]);
                }
              }}
              menuOptions={[
                {
                  shouldSkip: isStar || id === meId,
                  title: t('dashboard.employees.menu.setStar'),
                  onClick: async () => {
                    const modalEditor = complimentEditor?.current;
                    if (modalEditor) {
                      const complimented = await modalEditor.show(
                        [{ id, isDepartment: false, name: name as string }],
                        true,
                      );
                      if (complimented) {
                        const profileLink = `/profile/${id}`;
                        history.push(profileLink);
                      }
                    }
                  },
                },
                {
                  title: t('dashboard.employees.menu.editDepartments'),
                  onClick: async () => {
                    const modal = assignDepartmentModal?.current;
                    if (modal) {
                      const selectedDepartments = await modal.show(
                        departments as Department[] | null,
                      );
                      if (selectedDepartments) {
                        try {
                          await assignDepartment({
                            variables: {
                              input: {
                                users: [id],
                                departments: selectedDepartments,
                                append: false,
                              },
                            },
                          });
                          refetch();
                        } catch (e) {
                          Log.error(e.message, 'Employees');
                        }
                      }
                    }
                  },
                },
                {
                  shouldSkip: id === meId,
                  title: t('dashboard.employees.menu.editRole'),
                  onClick: async () => {
                    const modal = editRoleModal?.current;
                    if (modal) {
                      const selectedRole = await modal.show(role);
                      if (selectedRole) {
                        try {
                          await updateRole({
                            variables: {
                              input: {
                                id,
                                role: selectedRole,
                              },
                            },
                          });
                          refetch();
                        } catch (e) {
                          Log.error(e.message, 'Employees');
                        }
                      }
                    }
                  },
                },
                {
                  shouldSkip: id === meId,
                  title: t('dashboard.employees.menu.delete'),
                  onClick: async () => {
                    const modal = confirmDeleteModal?.current;
                    if (modal) {
                      const confirm = await modal.show();
                      if (confirm) {
                        await deleteUser({
                          variables: {
                            input: {
                              id,
                            },
                          },
                        });
                        refetch();
                      }
                    }
                  },
                },
                {
                  shouldSkip: id === meId || isOnboard,
                  title: t('dashboard.employees.menu.invite'),
                  onClick: async () => {
                    const modal = confirmInviteModal?.current;
                    if (modal) {
                      const confirm = await modal.show();
                      if (confirm) {
                        await inviteUser({
                          variables: {
                            input: {
                              name,
                              email,
                              resend: true,
                            },
                          },
                        });
                      }
                    }
                  },
                },
              ]}
            />
          );
        },
      );
  };

  return (
    <Styled.Container>
      <ModalComplimentEditor ref={complimentEditor} />
      <InvitePeopleModal
        isOpen={isInvitingUsers}
        onCancel={() => {
          setIsInvitingUsers(false);
        }}
        onOk={async () => {
          refetch();
          setIsInvitingUsers(false);
        }}
      />
      <ConfirmModal
        ref={confirmDeleteModal}
        body={t('dashboard.employees.deleteModal.body')}
        cancelText={t('dashboard.employees.deleteModal.cancelText')}
        okText={t('dashboard.employees.deleteModal.okText')}
        onCancel={() => {}}
        title={t('dashboard.employees.deleteModal.title')}
        onOk={() => {}}
      />
      <ConfirmModal
        ref={confirmInviteModal}
        body={t('dashboard.employees.inviteModal.body')}
        cancelText={t('dashboard.employees.inviteModal.cancelText')}
        okText={t('dashboard.employees.inviteModal.okText')}
        onCancel={() => {}}
        title={t('dashboard.employees.inviteModal.title')}
        onOk={() => {}}
      />
      <AssignDepartment
        ref={assignDepartmentModal}
        departments={employeesData.departments.departments}
      />
      <EditRole ref={editRoleModal} />

      <Role expectedRole="ADMIN">
        <Styled.AddButton
          onClick={() => {
            setIsInvitingUsers(true);
          }}
        >
          <Type min={16} max={18} weight={700}>
            {t('dashboard.employees.inviteUsers')}
          </Type>
        </Styled.AddButton>
      </Role>

      <Styled.Table>
        <thead>
          <Styled.Row isHeader>
            <Styled.SelectColumn></Styled.SelectColumn>
            <Styled.NameColumn>
              <Type min={16} max={18} weight={500}>
                {t('dashboard.employees.name')}
              </Type>
            </Styled.NameColumn>
            <Styled.DepartmentsColumn>
              <Type min={16} max={18} weight={500}>
                {t('dashboard.employees.departments')}
              </Type>
            </Styled.DepartmentsColumn>
            <Role expectedRole="ADMIN">
              <Styled.RoleColumn>
                <Type min={16} max={18} weight={500}>
                  {t('dashboard.employees.role')}
                </Type>
              </Styled.RoleColumn>
            </Role>
            <Role expectedRole="ADMIN">
              <Styled.StatusColumn>
                <Type min={16} max={18} weight={500}>
                  {t('dashboard.employees.status')}
                </Type>
              </Styled.StatusColumn>
            </Role>
            <Styled.ComplimentsColumn />
            <Styled.OptionsColumn />
          </Styled.Row>
        </thead>
        <tbody>{employees()}</tbody>
      </Styled.Table>

      <ComplimentCta
        isActive={selectedEntries.length > 0}
        onClick={async () => {
          const modalEditor = complimentEditor?.current;
          if (modalEditor) {
            const to = selectedEntries.map(entry => {
              return { id: entry.id, isDepartment: false, name: entry.name };
            });

            await modalEditor.show(to);
            setSelectedEntries([]);
          }
        }}
      >
        <Type min={14} max={16} weight={700}>
          {t('dashboard.complimentCta')}
        </Type>
        <img src={ComplimentCtaIcon} alt={t('dashboard.complimentCta')} />
      </ComplimentCta>
    </Styled.Container>
  );
};

export default Employees;
