import React, { FC } from 'react';
import styled from 'styled-components';
import { p } from 'styled-components-spacing';
import { up } from 'styled-breakpoints';
import { RenderElementProps, RenderLeafProps } from 'slate-react';
import { Editable as SlateEditable } from '@slate-plugin-system/core';

import * as StyleUtils from 'styles/utils';

type EditableProps = {
  fontFamily: string;
};

export const Editable = styled(SlateEditable)<EditableProps>`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
  flex: 1;
  padding: ${StyleUtils.Spacing(3, 0)};

  &:focus {
    outline: none;
  }

  h2 {
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: 34px;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;

    ${up('md')} {
      font-size: 48px;
    }
  }

  h3 {
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: 24px;
    line-height: 1.4;
    padding-top: 1em;
  }

  p,
  li,
  blockquote {
    margin-top: 1em;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  a {
    text-decoration: underline;
  }

  blockquote {
    border-left: 3px solid #fff;
    padding-left: 2em;
  }

  ol {
    border-left: 3px solid transparent;
    list-style-type: decimal;
    padding-left: 2em;
  }

  ul {
    border-left: 3px solid transparent;
    list-style-type: disc;
    padding-left: 2em;
  }

  li {
  }

  span {
    user-select: text;
  }
`;

export const Element: FC<RenderElementProps> = props => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'title':
      return <h2 {...attributes}>{children}</h2>;
    case 'paragraph':
      return <p {...attributes}>{children}</p>;
    case 'heading':
      return <h3 {...attributes}>{children}</h3>;
    case 'block_quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'list_numbered':
      return <ol {...attributes}>{children}</ol>;
    case 'list_bulleted':
      return <ul {...attributes}>{children}</ul>;
    case 'list_item':
      return <li {...attributes}>{children}</li>;
  }
  return null;
};

export const Leaf: FC<RenderLeafProps> = props => {
  const { attributes, leaf } = props;
  let children = props.children;
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underlined) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
