import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Trans, useTranslation } from 'react-i18next';

import ReactTags from 'react-tag-autocomplete';
import Modal from '../../../components/Modal';

import * as Styled from './styles';
import * as gql from '../../gql';
import { Type } from 'components';

import { User } from '../../../../../../@types/graphql';
import Log from 'utils/Log';

type AddPeopleModal = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  departmentToEdit: {
    id: number;
    name: string;
  };
  onInviteLinkClick: () => void;
};

const AddPeopleModal = ({
  isOpen,
  onCancel,
  onOk,
  departmentToEdit,
  onInviteLinkClick,
}: AddPeopleModal) => {
  const { t } = useTranslation();

  const [members, setMembers] = useState<User[]>([] as User[]);
  const [suggestions, setSuggestions] = useState([]);

  const [assignDepartment] = useMutation(gql.ASSIGN_DEPARTMENT);

  const { data: employeesData, error } = useQuery(gql.GET_EMPLOYEES);

  useEffect(() => {
    if (employeesData) setSuggestions(employeesData.users.users);
  }, [employeesData]);

  if (error) {
    Log.error(error.message, 'AddPeopleModal');
    return <></>;
  }

  const handleAdd = (member: User) => {
    const assignedMembers = [...members, member];

    setMembers(assignedMembers);
  };

  const handleDelete = (i: number) => {
    const assignedMembers = members.slice(0);

    assignedMembers.splice(i, 1);

    setMembers(assignedMembers);
  };

  return (
    <Modal
      title={t('dashboard.departments.addPeople.title')}
      buttonLabel={t('dashboard.departments.addPeople.okButtonLabel')}
      cancelButtonLabel={t('dashboard.modals.cancel')}
      isOpen={isOpen}
      onCancel={() => {
        onCancel();
        setMembers([]);
      }}
      onOk={async () => {
        await assignDepartment({
          variables: {
            input: {
              users: members.map(member => member.id),
              departments: [departmentToEdit.id],
              append: true,
            },
          },
        });
        onOk();
      }}
    >
      <Styled.Container>
        <Type min={14} max={15} style={{ position: 'relative', top: '-14px' }}>
          {t('dashboard.departments.addPeople.toDepartment')}
          <span style={{ fontStyle: 'italic' }}>{departmentToEdit.name}</span>
        </Type>
        {employeesData && suggestions && (
          <ReactTags
            tags={members}
            suggestions={suggestions}
            handleDelete={handleDelete}
            handleAddition={handleAdd}
            placeholder={t('dashboard.departments.addPeople.placeholder')}
            noSuggestionsText={t('dashboard.departments.addPeople.noResults')}
            minQueryLength={1}
          />
        )}
        <Styled.InviteText>
          <Trans
            i18nKey={'dashboard.departments.addPeople.inviteText'}
            components={[
              <Styled.ShowInviteLink
                key={0}
                tabIndex={0}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    onInviteLinkClick();
                  }
                }}
                onClick={onInviteLinkClick}
              />,
            ]}
          />
        </Styled.InviteText>
      </Styled.Container>
    </Modal>
  );
};

export default AddPeopleModal;
