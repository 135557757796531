import React, { useState, useEffect, useMemo, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import { useHistory } from 'react-router-dom';
import { Type } from 'components';

import { LOGOUT_USER } from 'graphql/user';

import { useUserState } from 'store/User/UserStore';
import { useAuthDispatch } from 'context/AuthProvider';
import * as Styled from './styles';

import ProfileIcon from './icons/profile.svg';
import LanguageIcon from './icons/language.svg';
import LogoutIcon from './icons/log-out.svg';
import ImportIcon from './icons/user-plus.svg';
import { useChangeLocale } from 'hooks';

import ImportModal from './ImportModal';

type Menu = {
  onClick: () => void;
};

const Menu = ({ onClick }: Menu) => {
  const { t } = useTranslation();
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const [logoutMutation] = useMutation(LOGOUT_USER);
  const { id, role, locale } = useUserState();
  const profileLink = `/profile/${id}`;
  const changeLocale = useChangeLocale();
  const [selected, setSelected] = useState(0);
  const [showImportModal, setShowImportModal] = useState(false);

  // Handlers
  const gotoProfilePage = () => {
    history.push(profileLink);
    onClick();
  };

  const logout = async () => {
    await logoutMutation();
    if (process.env.REACT_APP_PUBLIC_URL) {
      window.location.href = process.env.REACT_APP_PUBLIC_URL;
    } else {
      dispatch({ type: 'logout' });
    }
    onClick();
  };

  const items = [
    {
      onClick: gotoProfilePage,
      icon: ProfileIcon,
      label: t('dashboard.avatarMenu.viewProfile'),
    },
    {
      onClick: changeLocale,
      icon: LanguageIcon,
      label:
        locale === 'is'
          ? t('dashboard.avatarMenu.language.en')
          : t('dashboard.avatarMenu.language.is'),
      withSeparator: true,
    },
    ...(role === 'ADMIN'
      ? [
          {
            onClick: () => setShowImportModal(true),
            icon: ImportIcon,
            label: t('dashboard.avatarMenu.importUsers'),
            withSeparator: true,
          },
        ]
      : []),
    {
      onClick: logout,
      icon: LogoutIcon,
      label: t('dashboard.avatarMenu.logOut'),
    },
  ];

  const refs = useMemo(() => items.map(() => createRef<HTMLDivElement>()), []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          const prev = Math.max(0, selected - 1);
          setSelected(prev);
          refs[prev]?.current?.focus();
          break;
        case 'ArrowDown':
          const next = Math.min(items.length - 1, selected + 1);
          setSelected(next);
          refs[next]?.current?.focus();
          break;
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [selected, refs]);

  return (
    <>
      <Styled.MenuContainer>
        {items.map((i: any, index) => (
          <React.Fragment key={index}>
            <Styled.MenuItem
              ref={refs[index]}
              role="menuitem"
              tabIndex={0}
              onClick={i.onClick}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  i.onClick();
                }
              }}
              isSelected={selected === index}
              onFocus={() => setSelected(index)}
            >
              <Styled.MenuIconContainer>
                <Styled.MenuIcon src={i.icon} alt="" />
              </Styled.MenuIconContainer>
              <Styled.Spacer h={2} />
              <Type min={14} max={16}>
                {i.label}
              </Type>
            </Styled.MenuItem>
            {i.withSeparator && <Styled.Separator />}
          </React.Fragment>
        ))}
      </Styled.MenuContainer>
      <ImportModal
        isOpen={showImportModal}
        onCancel={() => {
          setShowImportModal(false);
        }}
        onOk={() => {
          setShowImportModal(false);
        }}
      />
    </>
  );
};

export default Menu;
