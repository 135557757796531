import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguagePicker } from 'components';

import * as Styled from './styles';

import HeartSmiley from '../../icons/heart-smiley.svg';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Styled.HomeContainer>
      <LanguagePicker invert />
      <Styled.Smiley src={HeartSmiley} alt="Smiley" />

      <Styled.Headline min={24} max={36} weight={700} as="h1">
        {t('home.title')}
      </Styled.Headline>

      <Styled.ButtonContainer>
        <Styled.Button to="/login">{t('home.signIn')}</Styled.Button>
      </Styled.ButtonContainer>
    </Styled.HomeContainer>
  );
};

export default Home;
