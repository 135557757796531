import React, { FC, useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../styles/HrosarinnTheme';
import * as Wizard from '../../../components/Wizard';
import Progress from '../../../components/Progress';
import { InputField, isValidEmail } from '../../../components/Form';
import { useLoginMutation } from '../../../graphql';
import StarSmiley from '../../../icons/star-smiley.svg';
import WelcomeWave from '../Welcome/icons/WelcomeWave';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthState } from '../../../context/AuthProvider';
import { ApolloError } from 'apollo-client';
import { useLocation } from 'react-router-dom';
import { LanguagePicker } from 'components';
import Log from 'utils/Log';

const LoginForm: FC<{
  login: (email: string) => void;
  error: ApolloError | undefined;
}> = ({ login, error }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const { loginError } = useAuthState();
  const [emailError, setEmailError] = useState<Error | null>(null);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      setEmailError(new Error('invalidEmail'));
      return;
    }
    login(email);
  };

  const errorToUse = emailError ? emailError : loginError || error;

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley src={StarSmiley} alt="Smiley" />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form onSubmit={onSubmit}>
          <Wizard.Fields>
            <Wizard.Header>
              <Wizard.Heading>{t('signIn.title')}</Wizard.Heading>
              {/* <Wizard.Subheading>
                {t('signIn.notAMember')}&nbsp;
                <BodyLink to="/register">{t('signIn.signUp')}</BodyLink>
              </Wizard.Subheading> */}
            </Wizard.Header>

            <InputField
              id="email"
              value={email}
              onChange={e => {
                if (emailError) {
                  setEmailError(null);
                }
                setEmail(e.target.value);
              }}
              type="email"
              required
              label={t('signIn.form.email')}
            />
            <ErrorMessage error={errorToUse} />
          </Wizard.Fields>
          <Wizard.Button type="submit">{t('signIn.form.cta')}</Wizard.Button>
        </Wizard.Form>
        <Wizard.ProgressContainer>
          <Progress current={1} total={2} />
        </Wizard.ProgressContainer>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

const LoginDone = () => {
  const { t } = useTranslation();

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley src={StarSmiley} alt="Smiley" />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form>
          <Wizard.Fields>
            <WelcomeWave />
            <Wizard.Header>
              <Wizard.Heading>{t('signIn.title')}</Wizard.Heading>
              <Wizard.Subheading>
                {t('signIn.verifyDescription')}
              </Wizard.Subheading>
            </Wizard.Header>
          </Wizard.Fields>
        </Wizard.Form>
        <Wizard.ProgressContainer>
          <Progress current={2} total={2} />
        </Wizard.ProgressContainer>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

const Login = () => {
  const query = new URLSearchParams(useLocation().search);
  const redirectPath = query.get('redirect');
  const [login, { data, error }] = useLoginMutation();
  const { i18n } = useTranslation();

  return data ? (
    <LoginDone />
  ) : (
    <LoginForm
      login={email => {
        login({
          variables: {
            input: {
              email,
              redirectPath,
              locale: i18n.language,
            },
          },
        }).catch((res: ApolloError) => {
          const errors = res.graphQLErrors.map((error: { message: string }) => {
            return error.message;
          });
          Log.error(errors[0], 'Login');
        });
      }}
      error={error}
    />
  );
};

export default Login;
