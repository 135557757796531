import gql from 'graphql-tag';

export const GET_STATS = gql`
  query Stars($filter: FilterOptions) {
    stats(filter: $filter) {
      stats {
        totalCompliments
        totalLikes
        users {
          name
          id
          sentCount
          receivedCount
        }
      }
    }
  }
`;
