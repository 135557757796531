import React from 'react';
import { Translation } from 'react-i18next';

import Modal from '../../components/Modal';
import { Type } from 'components';

import * as Styled from './styles';

import EntryDeselectedIcon from '../../icons/EntryDeselected.svg';
import EntrySelectedIcon from '../../icons/EntrySelected.svg';

import { Department } from '../../../../../@types/graphql';

type AssignDepartmentProps = {
  departments: Department[];
};

type AssignDepartmentState = {
  isOpen: boolean;
  selectedDepartments: number[];
};

let resolve: (value?: number[] | PromiseLike<number[]> | undefined) => void;

export default class AssignDepartment extends React.Component<
  AssignDepartmentProps,
  AssignDepartmentState
> {
  constructor(props: AssignDepartmentProps) {
    super(props);

    this.state = {
      isOpen: false,
      selectedDepartments: [],
    };
  }

  preselectExistingDepartments = () => {};

  show(usersDepartments: Department[] | null) {
    const selected: number[] = [];

    if (usersDepartments && usersDepartments.length > 0) {
      usersDepartments.forEach(dept => selected.push(dept.id));
    }

    this.setState({ isOpen: true, selectedDepartments: selected });

    return new Promise<number[] | undefined>(res => {
      resolve = res;
    });
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
    resolve(undefined);
  };

  handleConfirm = () => {
    const { selectedDepartments } = this.state;
    this.setState({ isOpen: false });
    resolve(selectedDepartments);
  };

  entryCLicked = (id: number) => {
    const { selectedDepartments } = this.state;
    const index = selectedDepartments.indexOf(id);

    if (index < 0) {
      selectedDepartments.push(id);
    } else {
      selectedDepartments.splice(index, 1);
    }

    this.setState({ selectedDepartments });
  };

  render() {
    const { departments } = this.props;
    const { isOpen, selectedDepartments } = this.state;

    const isSelected = (id: number) => selectedDepartments.indexOf(id) > -1;

    const listDepartments = () =>
      departments.map((department, index) => (
        <Styled.Entry
          key={index}
          onClick={() => this.entryCLicked(department.id)}
          onKeyPress={e => {
            e.preventDefault();
            if (e.key === ' ') {
              // Spacebar
              this.entryCLicked(department.id);
            }
          }}
          tabIndex={0}
        >
          <Styled.SelectionImage
            src={
              isSelected(department.id)
                ? EntrySelectedIcon
                : EntryDeselectedIcon
            }
          />
          <Type min={14} max={16}>
            {department.name}
          </Type>
        </Styled.Entry>
      ));

    return (
      <>
        <Translation>
          {t => (
            <Modal
              title={t('dashboard.employees.assignDepartments.title')}
              buttonLabel={t('dashboard.modals.done')}
              cancelButtonLabel={t('dashboard.modals.cancel')}
              isOpen={isOpen}
              onCancel={this.handleCancel}
              onOk={this.handleConfirm}
            >
              <Styled.Entries>{listDepartments()}</Styled.Entries>
            </Modal>
          )}
        </Translation>
      </>
    );
  }
}
