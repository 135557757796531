import React from 'react';

import * as Styled from './styles';
import { Spacer } from 'components';

import { ReactComponent as MedalIcon } from '../../images/medal-gray.svg';
import { Link } from 'react-router-dom';

type User = {
  id: number;
  name: string;
  count: number;
};

type ListCardProps = {
  label: string;
  list: User[];
  startNumber?: number;
  highlightFirst?: boolean;
  highlightColor?: string;
};

const ListCard = ({
  list,
  label,
  startNumber = 0,
  highlightFirst = false,
  highlightColor = undefined,
}: ListCardProps) => {
  const isHighlight = (index: number) => index === 0 && highlightFirst;

  return (
    <Styled.CardListContainer>
      {(list.length > 0 && (
        <Styled.OL
          start={startNumber}
          className={highlightFirst ? 'withHighlight' : ''}
          color={highlightColor}
        >
          {list.map((user: User, index: number) => (
            <li key={index}>
              <Styled.LiInner
                color={isHighlight(index) ? highlightColor : undefined}
              >
                <Styled.Name isHighlight={isHighlight(index)}>
                  <Link to={`profile/${user.id}`}>{user.name}</Link>
                </Styled.Name>
                <Styled.Count isHighlight={isHighlight(index)}>
                  {user.count}
                </Styled.Count>
              </Styled.LiInner>
            </li>
          ))}
        </Styled.OL>
      )) || (
        <Styled.EmptyState>
          <MedalIcon />
        </Styled.EmptyState>
      )}
      <Spacer v={2} />
      <Styled.Label>{label}</Styled.Label>
    </Styled.CardListContainer>
  );
};

export default ListCard;
