import React, { useState } from 'react';

import AddPeopleModal from '../AddPeopleModal';
import InvitePeopleModal from '../../../components/InvitePeopleModal';

type AddPeopleModalContainer = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  departmentToEdit: {
    id: number;
    name: string;
  };
};

const AddPeopleModalContainer = ({
  isOpen,
  onCancel,
  onOk,
  departmentToEdit,
}: AddPeopleModalContainer) => {
  const [showInviteScreen, setShowInviteScreen] = useState(false);

  const onClose = (fn: () => void) => {
    setShowInviteScreen(false);
    fn();
  };

  if (showInviteScreen) {
    return (
      <InvitePeopleModal
        isOpen={isOpen}
        onCancel={() => onClose(onCancel)}
        onOk={() => onClose(onOk)}
        defaultDepartment={departmentToEdit.id}
      />
    );
  }

  return (
    <AddPeopleModal
      isOpen={isOpen}
      onCancel={() => onClose(onCancel)}
      onOk={() => onClose(onOk)}
      departmentToEdit={departmentToEdit}
      onInviteLinkClick={() => {
        setShowInviteScreen(true);
      }}
    />
  );
};

export default AddPeopleModalContainer;
