import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset-advanced';

import '../fonts/fonts.css';
import './ReactTags.css';

const GlobalStyle = createGlobalStyle`
  ${reset}

  a {
    text-decoration: none;
  }

  body {
    font-family: 'Avant Garde Gothic ITC', Poppins, sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, button {
    font-family: 'Avant Garde Gothic ITC', sans-serif;
    font-weight: 700;
  }
  
  input::-webkit-input-placeholder {
    color: ${props => props.theme.colors.placeholderGray};
  }

  :focus {
    outline: ${props => props.theme.colors.purpleActive} auto 5px;
  }

  input:focus {
    outline-offset: -2px;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
`;

export default GlobalStyle;
