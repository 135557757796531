import styled from 'styled-components';
import * as StyleUtils from '../../styles/utils';
import selectArrow from './icons/select-arrow.svg';
import removeIcon from './icons/remove-row.svg';
import React, {
  FC,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactNode,
  SelectHTMLAttributes,
} from 'react';
import { Type } from '../Typography';
import VisuallyHidden from '../VisuallyHidden';
import { up } from 'styled-breakpoints';

export * from './utils';

export const Input = styled.input`
  background: ${props => props.theme.colors.white};
  border: 1px solid transparent;
  border-radius: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  margin: ${StyleUtils.Spacing(1)} 0;
  padding: ${StyleUtils.Spacing(2)};
  width: 100%;

  &[aria-invalid='true'] {
    border: 1px solid ${props => props.theme.colors.pink};
  }
`;

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  id: string;
  error?: boolean;
};

export const InputField: FC<InputFieldProps> = ({
  label,
  placeholder,
  id,
  name,
  error,
  ...props
}) => (
  <>
    <VisuallyHidden as="label" htmlFor={id}>
      {label}
    </VisuallyHidden>
    <Input
      {...props}
      name={name || id}
      id={id}
      placeholder={placeholder || label}
      aria-invalid={error}
    />
  </>
);

export const SelectContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const SelectLabel = styled.span`
  color: ${props => props.theme.colors.placeholderGray};
  font-size: 16px;
  line-height: 20px;
  padding: ${StyleUtils.Spacing(2)};
  pointer-events: none;
  position: absolute;
  text-align: left;
  width: 100%;
`;

export const Select = styled.select`
  background: url(${selectArrow}) no-repeat 95%
    ${props => props.theme.colors.white};
  border: none;
  border-radius: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  padding: ${StyleUtils.Spacing(2)};
  padding-right: ${StyleUtils.Spacing(3)};
  appearance: none;
  width: 100%;
`;

type SelectFieldProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  placeholder?: string;
  id: string;
};

export const SelectField: FC<SelectFieldProps> = ({
  label,
  placeholder,
  children,
  value,
  id,
  name,
  className,
  ...props
}) => (
  <SelectContainer className={className}>
    <VisuallyHidden as="label" htmlFor={id}>
      {label}
    </VisuallyHidden>
    {!value && <SelectLabel aria-hidden>{placeholder || label}</SelectLabel>}
    <Select {...props} name={name || id} id={id} value={value}>
      <option />
      {children}
    </Select>
  </SelectContainer>
);

export const FieldRow = styled.div`
  ${StyleUtils.Flex};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${StyleUtils.Spacing(-1)};

  & > ${Input}, & > ${SelectContainer} {
    margin: ${StyleUtils.Spacing(1)};
  }

  ${up('sm')} {
    flex-direction: row;
  }
`;

export const RemoveRowButton = styled.button`
  padding: ${StyleUtils.Spacing(0.5)};
  cursor: pointer;
  margin-right: -4px;
`;

export const RemoveRowIcon = styled.img`
  display: block;
`;

type RemoveButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  alt: string;
};

export const RemoveButton: FC<RemoveButtonProps> = ({ onClick, alt }) => (
  <RemoveRowButton type="button" onClick={onClick}>
    <RemoveRowIcon src={removeIcon} alt={alt} />
  </RemoveRowButton>
);

export const AddRowButton = styled.button`
  cursor: pointer;
  color: ${props => props.theme.colors.mint};
  margin-top: ${StyleUtils.Spacing(1)};
  margin-bottom: ${StyleUtils.Spacing(2)};
`;

type AddButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
};

export const AddButton: FC<AddButtonProps> = ({ onClick, children }) => (
  <AddRowButton type="button" onClick={onClick}>
    <Type min={16} max={16} weight={500}>
      {children}
    </Type>
  </AddRowButton>
);
