import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import theme from '../../../styles/HrosarinnTheme';
import * as Wizard from '../../../components/Wizard';
import { useUserState } from '../../../store/User';

import StarSmiley from '../../../icons/star-smiley.svg';
import Progress from '../../../components/Progress';
import { useUpdateUserMutation } from '../../../graphql';
import { assertNonNull } from '../../../types/assert';
import { LanguagePicker } from 'components';

const Start = () => {
  const { t } = useTranslation();
  const { id, name } = useUserState();
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    (async () => {
      assertNonNull(id, 'User id missing');
      await updateUser({
        variables: { input: { id, locale: null, isOnboard: true } },
      });
    })();
  }, [updateUser, id]);

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley src={StarSmiley} alt="Smiley" />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form>
          <Wizard.Fields>
            <Wizard.Header>
              <Wizard.Heading>
                {t('onboarding.finish.title', { name })}
              </Wizard.Heading>
              <Wizard.Subheading>
                {t('onboarding.finish.copy')}
              </Wizard.Subheading>
            </Wizard.Header>
          </Wizard.Fields>
          <Wizard.Button as={Link} to="/">
            {t('onboarding.finish.cta')}
          </Wizard.Button>
        </Wizard.Form>
        <Wizard.ProgressContainer>
          <Progress current={4} total={4} />
        </Wizard.ProgressContainer>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

export default Start;
