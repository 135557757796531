import React, { useState } from 'react';
import Loading from 'components/Loading';
import { ErrorPage } from 'pages/errors';
import theme from 'styles/HrosarinnTheme';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';

import { Image, Spacer } from 'components';
import ListCard from './components/ListCard';
import StatCard from './components/StatCard';

import { useTranslation } from 'react-i18next';

import Log from 'utils/Log';

import * as Styled from './styles';
import { Col, Row } from 'react-styled-flexboxgrid';

// TODO: Eventually change these to SVG components and parametrize the fill color.
import MedalPurple from './images/medal-purple.svg';
import MedalPink from './images/medal-pink.svg';
import MedalMint from './images/medal-mint.svg';
import MailPurple from './images/mail-purple.svg';
import MailPink from './images/mail-pink.svg';
import MailMint from './images/mail-mint.svg';

import Heart from './images/heart.svg';
import Jelly from './images/jelly.svg';

import * as gql from './gql';
import { useQuery } from '@apollo/react-hooks';

const MIN_TEAM_SIZE_FOR_BOTTOM_LIST = 5;

type User = {
  id: number;
  name: string;
  sentCount: number;
  receivedCount: number;
};

const tabs = {
  last: {
    hero: MedalPurple,
    mail: MailPurple,
    color: theme.colors.purple,
  },
  this: {
    hero: MedalPink,
    mail: MailPink,
    color: theme.colors.pink,
  },
  allTime: {
    hero: MedalMint,
    mail: MailMint,
    color: theme.colors.mint,
  },
};

const StatsScreen = () => {
  const { t } = useTranslation();
  const { loading, data, error, refetch } = useQuery(gql.GET_STATS, {
    variables: { filter: { cursor: dayjs().format('YYYY-MM-DD') } },
    fetchPolicy: 'cache-and-network',
  });

  const [activeTab, setActiveTab] = useState('this');

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <Loading isPurple />
      </Styled.LoadingContainer>
    );
  }

  if (error) {
    Log.error(error.message, 'StatsScreen');
    return <ErrorPage />;
  }

  // teams of 6 show 5 in the top list and 1 in the bottom list
  // teams og 7 show 5 in the top list and 2 in the bottom list
  // ...
  const bottomListLength =
    MIN_TEAM_SIZE_FOR_BOTTOM_LIST - data.stats.stats.users.length >= -5
      ? MIN_TEAM_SIZE_FOR_BOTTOM_LIST - data.stats.stats.users.length
      : -5;

  const sortedReceived = orderBy(
    data.stats.stats.users,
    'receivedCount',
    'desc',
  ).map((user: User) => {
    return {
      id: user.id,
      name: user.name,
      count: user.receivedCount,
    };
  });

  const topRecived = sortedReceived.slice(0, 5);
  const bottomRecived = sortedReceived.slice(bottomListLength);

  const sortedSent = orderBy(data.stats.stats.users, 'sentCount', 'desc').map(
    (user: User) => {
      return {
        id: user.id,
        name: user.name,
        count: user.sentCount,
      };
    },
  );

  const topSent = sortedSent.slice(0, 5);
  const bottomSent = sortedSent.slice(bottomListLength);

  const swithcTab = (tab: string, options?: $FixMe) => {
    setActiveTab(tab);
    refetch({
      filter: {
        ...options,
      },
    });
  };

  return (
    <Styled.StatsContainer>
      <Spacer v={6} />
      <Row between="xs">
        <Styled.Tab>
          <Styled.TabLabel
            as="button"
            tabIndex={0}
            activeColor={tabs.last.color}
            isActive={activeTab === 'last'}
            onClick={() => {
              const now = dayjs();
              const lastMonth = now.subtract(1, 'month');
              const cursor = dayjs(lastMonth).format('YYYY-MM-DD');
              swithcTab('last', { cursor });
            }}
          >
            {t('dashboard.stats.tabs.last')}
          </Styled.TabLabel>
        </Styled.Tab>
        <Styled.Tab>
          <Styled.TabLabel
            as="button"
            tabIndex={0}
            activeColor={tabs.this.color}
            isActive={activeTab === 'this'}
            onClick={() => {
              const now = dayjs();
              const cursor = now.format('YYYY-MM-DD');
              swithcTab('this', { cursor });
            }}
          >
            {t('dashboard.stats.tabs.this')}
          </Styled.TabLabel>
        </Styled.Tab>
        <Styled.Tab>
          <Styled.TabLabel
            as="button"
            tabIndex={0}
            activeColor={tabs.allTime.color}
            isActive={activeTab === 'allTime'}
            onClick={() => swithcTab('allTime')}
          >
            {t('dashboard.stats.tabs.allTime')}
          </Styled.TabLabel>
        </Styled.Tab>
      </Row>
      <Spacer v={8} />
      <Styled.Hero>
        <Image
          min={100}
          max={171}
          // @ts-ignore
          src={tabs[activeTab].hero}
          alt=""
        />
      </Styled.Hero>
      <Spacer v={3} />
      <Styled.TitleContainer>
        <Styled.PageTitle>{t('dashboard.stats.title')}</Styled.PageTitle>
      </Styled.TitleContainer>
      <Spacer v={3} />
      <Row>
        <Col xs={12} md={6}>
          <ListCard
            list={topRecived}
            label={t('dashboard.stats.received')}
            highlightFirst
            //@ts-ignore
            highlightColor={tabs[activeTab].color}
          />
        </Col>
        <Col xs={12} md={6}>
          <ListCard
            list={topSent}
            label={t('dashboard.stats.sent')}
            highlightFirst
            //@ts-ignore
            highlightColor={tabs[activeTab].color}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <StatCard
            value={data.stats.stats.totalCompliments}
            label={t('dashboard.stats.totalCompliments')}
            //@ts-ignore
            image={tabs[activeTab].mail}
          />
        </Col>
        <Col xs={12} md={6}>
          <StatCard
            value={data.stats.stats.totalLikes}
            label={t('dashboard.stats.totalLikes')}
            image={Heart}
          />
        </Col>
      </Row>

      {data.stats.stats.users.length >= MIN_TEAM_SIZE_FOR_BOTTOM_LIST && (
        <>
          <Styled.TitleContainer>
            <img src={Jelly} alt="" />
            <Styled.BottomSectionTitle>
              {t('dashboard.stats.bottomTitle')}
            </Styled.BottomSectionTitle>
          </Styled.TitleContainer>
          <Row>
            <Col xs={12} md={6}>
              <ListCard
                list={bottomRecived}
                label={t('dashboard.stats.received')}
                startNumber={data.stats.stats.users.length - 5}
              />
            </Col>
            <Col xs={12} md={6}>
              <ListCard
                list={bottomSent}
                label={t('dashboard.stats.sent')}
                startNumber={data.stats.stats.users.length - 5}
              />
            </Col>
          </Row>
        </>
      )}
    </Styled.StatsContainer>
  );
};

export default StatsScreen;
