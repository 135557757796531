import React from 'react';
import { FocusOn } from 'react-focus-on';

import * as Styled from './styles';
import { Type } from 'components';

type Modal = {
  title: string;
  buttonLabel: string;
  cancelButtonLabel?: string;
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  children: React.ReactNode;
  largeWidth?: number;
};

const Modal = ({
  title,
  buttonLabel,
  cancelButtonLabel,
  isOpen,
  onCancel,
  onOk,
  children,
  largeWidth = 450,
}: Modal) => {
  return (
    <Styled.ModalContainer isShowing={isOpen}>
      <Styled.ModalOverlay isShowing={isOpen} />

      <Styled.ModalDialog largeWidth={largeWidth}>
        <FocusOn
          onClickOutside={onCancel}
          onEscapeKey={onCancel}
          enabled={isOpen}
        >
          <Type min={20} max={24} weight={700}>
            {title}
          </Type>
          <Styled.ModalContent>{children}</Styled.ModalContent>
          <Styled.ButtonContainer>
            {cancelButtonLabel && (
              <Styled.CancelButton onClick={onCancel}>
                <Type min={14} max={16} weight={700}>
                  {cancelButtonLabel}
                </Type>
              </Styled.CancelButton>
            )}
            <Styled.DialogOkButton onClick={onOk}>
              <Type min={14} max={16} weight={700}>
                {buttonLabel}
              </Type>
            </Styled.DialogOkButton>
          </Styled.ButtonContainer>
        </FocusOn>
      </Styled.ModalDialog>
    </Styled.ModalContainer>
  );
};

export default Modal;
