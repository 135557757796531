import React, { FC } from 'react';
import styled from 'styled-components';

export const ProgressContainer = styled.div`
  display: flex;
`;

type ProgressPartProps = {
  isActive?: boolean;
};

export const ProgressPart = styled.div<ProgressPartProps>`
  border-top: 4px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.mint : theme.colors.charcoal};
  opacity: ${props => (props.isActive ? 1 : 0.5)};
  flex: 1;
  margin: 0 5px;
`;

type ProgressProps = {
  current: number;
  total: number;
};

const Progress: FC<ProgressProps> = ({ current, total }) => (
  <ProgressContainer>
    {Array.from({ length: total }).map((_, index) => (
      <ProgressPart key={index} isActive={index < current} />
    ))}
  </ProgressContainer>
);

export default Progress;
