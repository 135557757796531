import React, { ReactNode, createContext, useContext, useReducer } from 'react';

type Role = 'USER' | 'MANAGER' | 'ADMIN';
type SetNamePayload = { newName: string };
type SetLocalePayload = { newLocale: string };
type SetRolePayload = { newRole: Role };
type LoadPayload = {
  id: number;
  role: Role;
  name: string | null;
  isOnboard: boolean;
  locale: string;
  createdAt: string | null;
  hasUnread: boolean;
};

type Action =
  | { type: 'setName'; payload: SetNamePayload }
  | { type: 'setLocale'; payload: SetLocalePayload }
  | { type: 'setRole'; payload: SetRolePayload }
  | { type: 'load'; payload: LoadPayload }
  | { type: 'onboard' }
  | { type: 'clearUnread' }
  | { type: 'toggleLikeBotMessage' };

type Dispatch = (action: Action) => void;
type UserState = {
  name: string;
  role: Role | null;
  id: number | null;
  isOnboard: boolean | null;
  locale: string;
  hasUnread: boolean;
  createdAt: string | null;
  botMessageLiked: boolean;
};

const userReducer = (state: UserState, action: Action) => {
  switch (action.type) {
    case 'load':
      return {
        ...state,
        name: action.payload.name || '',
        role: action.payload.role,
        id: action.payload.id,
        isOnboard: action.payload.isOnboard,
        locale: action.payload.locale,
        hasUnread: action.payload.hasUnread,
        createdAt: action.payload.createdAt,
      };
    case 'setName':
      return {
        ...state,
        name: action.payload.newName,
      };
    case 'setLocale':
      return {
        ...state,
        locale: action.payload.newLocale,
      };
    case 'setRole':
      return {
        ...state,
        role: action.payload.newRole,
      };
    case 'onboard':
      return {
        ...state,
        isOnboard: true,
      };
    case 'clearUnread':
      return {
        ...state,
        hasUnread: false,
      };
    case 'toggleLikeBotMessage':
      return {
        ...state,
        botMessageLiked: !state.botMessageLiked,
      };
    default:
      return state;
  }
};

const UserStateContext = createContext<UserState | undefined>(undefined);
const UserDispatchContext = createContext<Dispatch | undefined>(undefined);

interface UserStoreProps {
  children: ReactNode;
}

export const UserStore = ({ children }: UserStoreProps) => {
  const initialState: UserState = {
    name: '',
    role: null,
    id: null,
    isOnboard: null,
    locale: '',
    hasUnread: false,
    createdAt: null,
    botMessageLiked: false,
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

// TODO: bring this back
// export const useUserStore = () => useContext<$FixMe>(UserStateContext);

export const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserStore provider');
  }
  return context;
};

export const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserStore provider');
  }
  return context;
};
