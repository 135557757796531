import gql from 'graphql-tag';

export const DELETE_USER = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      id
    }
  }
`;

export const ASSIGN_DEPARTMENT = gql`
  mutation AssignDepartment($input: AssignDepartmentInput!) {
    assignDepartment(input: $input) {
      success
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query getEmployees {
    users {
      users {
        id
        name
        email
        role
        createdAt
        isOnboard
        departments {
          id
          name
        }
        isStar
      }
    }
    departments {
      departments {
        id
        name
      }
    }
  }
`;
