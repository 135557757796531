import React, { useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { Type, Role, Loading } from 'components';
import ModalComplimentEditor from '../components/ModalComplimentEditor';
import { Entry } from '../components';
import RenameModal from './RenameModal';
import AddPeopleModalContainer from './components/AddPeopleModalContainer';
import ConfirmModal from '../components/ConfirmModal';
import ComplimentCta from '../components/ComplimentCta';
import { ErrorPage } from 'pages/errors';

import * as Styled from './styles';

import { Department } from '../../../../@types/graphql';
import * as gql from './gql';

import ComplimentCtaIcon from '../icons/ComplimentCtaIcon.svg';
import Log from 'utils/Log';

type to = {
  id: number;
  isDepartment?: boolean;
  name: string;
};

const Departments = () => {
  const complimentEditor = useRef<ModalComplimentEditor>(null);
  const confirmModal = useRef<ConfirmModal>(null);

  const { t } = useTranslation();
  const { loading, data: departmentsData, error, refetch } = useQuery(
    gql.GET_DEPARTMENTS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [department, setDepartment] = useState('');

  const [selectedEntries, setSelectedEntries] = useState<to[]>([] as to[]);

  const [departmentToEdit, setDepartmentToEdit] = useState({
    id: -1,
    name: '',
  });

  const [isEditingDepartmentName, setIsEditingDepartmentName] = useState(false);
  const [isAddingPeopleToDepartment, setIsAddingPeopleToDepartment] = useState(
    false,
  );

  const [createDept] = useMutation(gql.CREATE_DEPTARTMENT, {
    update() {
      refetch();
    },
  });

  const [deleteDept] = useMutation(gql.DELETE_DEPTARTMENT, {
    update() {
      refetch();
    },
  });

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <Loading isPurple />
      </Styled.LoadingContainer>
    );
  }

  if (error) {
    Log.error(error.message, 'Departments');
    return <ErrorPage />;
  }

  const departments = () => {
    if (!departmentsData) return;

    return departmentsData.departments.departments.map(
      ({ name, id }: Department, index: number) => {
        return (
          <Entry
            isMe={false}
            key={`${index}_${id}`}
            id={id}
            setSelected={(id: number) => {
              if (selectedEntries.findIndex(i => i.id === id) < 0) {
                const to = {
                  id,
                  isDepartment: true,
                  name: name as string,
                };
                setSelectedEntries([...selectedEntries, to]);
              } else {
                setSelectedEntries([
                  ...selectedEntries.filter(i => i.id !== id),
                ]);
              }
            }}
            isSelected={selectedEntries.findIndex(i => i.id === id) > -1}
            name={name}
            onCompliment={async () => {
              const modalEditor = complimentEditor?.current;
              if (modalEditor) {
                await modalEditor.show([
                  { id, isDepartment: true, name: name as string },
                ]);
              }
            }}
            menuOptions={[
              {
                title: t('dashboard.departments.menu.inviteUsers'),
                onClick: () => {
                  setDepartmentToEdit({ id, name });
                  setIsAddingPeopleToDepartment(true);
                },
              },
              {
                title: t('dashboard.departments.menu.rename'),
                onClick: () => {
                  setDepartmentToEdit({ id, name });
                  setIsEditingDepartmentName(true);
                },
              },
              {
                title: t('dashboard.departments.menu.delete'),
                onClick: async () => {
                  const modal = confirmModal?.current;
                  if (modal) {
                    const confirm = await modal.show();
                    if (confirm) {
                      deleteDept({
                        variables: {
                          input: {
                            id,
                          },
                        },
                      });
                    }
                  }
                },
              },
            ]}
          />
        );
      },
    );
  };

  return (
    <Styled.Container>
      <ModalComplimentEditor ref={complimentEditor} />
      <ConfirmModal
        ref={confirmModal}
        body={t('dashboard.departments.deleteModal.body')}
        cancelText={t('dashboard.departments.deleteModal.cancelText')}
        okText={t('dashboard.departments.deleteModal.okText')}
        onCancel={() => {}}
        title={t('dashboard.departments.deleteModal.title')}
        onOk={() => {}}
      />
      <RenameModal
        isOpen={isEditingDepartmentName}
        onCancel={() => {
          setIsEditingDepartmentName(false);
        }}
        onOk={async () => {
          refetch();
          setIsEditingDepartmentName(false);
        }}
        departmentToEdit={departmentToEdit}
      />
      <AddPeopleModalContainer
        isOpen={isAddingPeopleToDepartment}
        onCancel={() => {
          setIsAddingPeopleToDepartment(false);
        }}
        onOk={async () => {
          refetch();
          setIsAddingPeopleToDepartment(false);
        }}
        departmentToEdit={departmentToEdit}
      />
      <Role expectedRole="ADMIN">
        <Styled.Form
          onSubmit={e => {
            e.preventDefault();
            createDept({
              variables: {
                input: {
                  name: department,
                },
              },
            });
            setDepartment('');
          }}
        >
          <Styled.InviteLabel>
            <Type min={14} max={16} weight={700}>
              {t('dashboard.departments.createDepartment')}
            </Type>
          </Styled.InviteLabel>

          <Styled.Field
            id="department"
            value={department}
            onChange={e => setDepartment(e.target.value)}
            type="text"
            required
            placeholder={t('dashboard.departments.createPlaceholder')}
          />

          <Styled.AddButton type="submit">
            {t('dashboard.departments.createCta')}
          </Styled.AddButton>
        </Styled.Form>
      </Role>

      <Styled.Table>
        <thead>
          <Styled.Row isHeader>
            <Styled.SelectColumn />
            <Styled.NameColumn>
              <Type min={16} max={18} weight={500}>
                {t('dashboard.departments.name')}
              </Type>
            </Styled.NameColumn>
            <Styled.ComplimentsColumn />
            <Styled.OptionsColumn />
          </Styled.Row>
        </thead>
        <tbody>{departments()}</tbody>
      </Styled.Table>

      <ComplimentCta
        isActive={selectedEntries.length > 0}
        onClick={async () => {
          const modalEditor = complimentEditor?.current;
          if (modalEditor) {
            const to = selectedEntries.map(entry => {
              return { id: entry.id, isDepartment: true, name: entry.name };
            });
            await modalEditor.show(to);
            setSelectedEntries([]);
          }
        }}
      >
        <Type min={14} max={16} weight={700}>
          {t('dashboard.complimentCta')}
        </Type>
        <img src={ComplimentCtaIcon} alt={t('dashboard.complimentCta')} />
      </ComplimentCta>
    </Styled.Container>
  );
};

export default Departments;
