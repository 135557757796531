import styled from 'styled-components';
import { p, mr } from 'styled-components-spacing';

import * as StyleUtils from '../../../../../styles/utils';

export * from '../../styles';

export const Entries = styled.div`
  width: 100%;
  height: 280px;
  overflow-y: auto;
  border-top: 1px solid #e1e1e1;
`;

export const Entry = styled.div`
  ${StyleUtils.Flex};
  ${p({ xs: 3 })};
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
`;

export const SelectionImage = styled.img`
  width: 18px;
  ${mr({ xs: 3 })};
`;
