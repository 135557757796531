import gql from 'graphql-tag';

export const GET_ORGANISATION = gql`
  {
    organisation {
      organisation {
        id
        url
      }
    }
  }
`;
