import React from 'react';
import { Translation } from 'react-i18next';

import Modal from '../../components/Modal';
import { Type } from 'components';

import * as Styled from './styles';

import EntryDeselectedIcon from '../../icons/EntryDeselected.svg';
import EntrySelectedIcon from '../../icons/EntrySelected.svg';

// We can't seem to import the Role enum, so re-creating it for now.
// https://lukasbehal.com/2017-05-22-enums-in-declaration-files/
// import {Role} from '../../../../../@types/graphql'
enum Role {
  User = 'USER',
  Manager = 'MANAGER',
  Admin = 'ADMIN',
}

type EditRoleProps = {};

interface EditRoleState {
  isOpen: boolean;
  selectedRole: Role;
}

let resolve: (value?: Role | PromiseLike<Role> | undefined) => void;

export default class EditRole extends React.Component<
  EditRoleProps,
  EditRoleState
> {
  constructor(props: EditRoleProps) {
    super(props);

    this.state = {
      isOpen: false,
      selectedRole: Role.User,
    };
  }

  show(role: Role) {
    this.setState({ isOpen: true, selectedRole: role });

    return new Promise<Role | undefined>(res => {
      resolve = res;
    });
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
    resolve(undefined);
  };

  handleConfirm = () => {
    const { selectedRole } = this.state;
    this.setState({ isOpen: false });
    resolve(selectedRole);
  };

  entryCLicked = (role: Role) => {
    this.setState({ selectedRole: role });
  };

  render() {
    const { isOpen, selectedRole } = this.state;
    const roles = Object.values(Role);

    const isSelected = (role: Role) => selectedRole === role;

    const listRoles = () =>
      roles.map((role: Role, index: number) => (
        <Styled.Entry
          key={index}
          onClick={() => this.entryCLicked(role)}
          onKeyPress={e => {
            e.preventDefault();
            if (e.key === ' ') {
              // Spacebar
              this.entryCLicked(role);
            }
          }}
          tabIndex={0}
        >
          <Styled.SelectionImage
            src={isSelected(role) ? EntrySelectedIcon : EntryDeselectedIcon}
          />
          <Type min={14} max={16}>
            {role}
          </Type>
        </Styled.Entry>
      ));

    return (
      <>
        <Translation>
          {t => (
            <Modal
              title={t('dashboard.employees.editRole.title')}
              buttonLabel={t('dashboard.modals.done')}
              cancelButtonLabel={t('dashboard.modals.cancel')}
              isOpen={isOpen}
              onCancel={this.handleCancel}
              onOk={this.handleConfirm}
            >
              <Styled.Entries>{listRoles()}</Styled.Entries>
            </Modal>
          )}
        </Translation>
      </>
    );
  }
}
