import gql from 'graphql-tag';

export const CREATE_DEPTARTMENT = gql`
  mutation CreateDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      department {
        id
        name
      }
    }
  }
`;

export const DELETE_DEPTARTMENT = gql`
  mutation DeleteDepartment($input: DeleteDepartmentInput!) {
    deleteDepartment(input: $input) {
      id
    }
  }
`;

export const GET_DEPARTMENTS = gql`
  query GetDepartments {
    departments {
      departments {
        id
        name
      }
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment($input: UpdateDepartmentInput!) {
    updateDepartment(input: $input) {
      id
    }
  }
`;

export const ASSIGN_DEPARTMENT = gql`
  mutation AssignDepartment($input: AssignDepartmentInput!) {
    assignDepartment(input: $input) {
      success
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query GetEmployees {
    users {
      users {
        id
        name
        email
        role
        createdAt
      }
    }
  }
`;
