import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { pb, pt } from 'styled-components-spacing';

import * as StyleUtils from 'styles/utils';
export * from '../styles';

export const Feed = styled.div`
  ${StyleUtils.Flex};
  flex-wrap: wrap;
  ${pt({ xs: 4 })};
  justify-content: center;
  align-items: center;
`;

export const Compliments = styled.div`
  width: 100%;
  ${up('md')} {
    width: 80%;
  }
  ${up('lg')} {
    width: 60%;
  }
`;

export const Compliment = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  margin-bottom: ${StyleUtils.Spacing(3)};
  width: 100%;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  ${pb({ xs: 2 })};
`;

export const Body = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  line-height: 1.5;
  min-height: 300px;
  background: ${({ theme }) => theme.colors.gray};
  padding: ${StyleUtils.Spacing(6, 2)};
  position: relative;
  overflow: hidden;
  text-align: center;

  ${up('md')} {
    padding: ${StyleUtils.Spacing(12, 6)};
  }
`;

export const ComplimentIcon = styled.img`
  width: 10%;
  height: auto;
  margin-bottom: ${StyleUtils.Spacing(2)};
`;

export const AnimatedComplimentIconContainer = styled.div`
  width: 20%;
  margin-bottom: ${StyleUtils.Spacing(2)};
`;

export const StarComplimentIcon = styled.img`
  width: 20%;
  height: auto;
  margin-bottom: ${StyleUtils.Spacing(2)};
`;

export const Deco1 = styled.img`
  position: absolute;
  right: 5%;
  top: 5%;
`;

export const Deco2 = styled.img`
  position: absolute;
  left: 5%;
  top: 8%;
`;

export const Deco3 = styled.img`
  position: absolute;
  left: 7%;
  bottom: -4%;
`;

export const Reciver = styled.div`
  opacity: 0.5;
  font-family: ${({ theme }) => theme.fonts.decoration};
`;

export const Message = styled.div``;

export const ComplimentMeta = styled.div`
  ${StyleUtils.Flex};
  padding: ${StyleUtils.Spacing(3)};
`;

export const ComplimentMetaAvatar = styled.img`
  margin-right: ${StyleUtils.Spacing(2)};
`;

export const ComplimentMetaTextContainer = styled.div`
  line-height: 1.5;
`;

export const ComplimentMetaTitle = styled.div`
  padding-bottom: ${StyleUtils.Spacing(0.5)};
  &.withHover:hover {
    text-decoration: underline;
  }
`;

export const ComplimentMetaSubTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.body};
  &.withHover:hover {
    text-decoration: underline;
  }
`;

export const Footer = styled.div`
  ${StyleUtils.Flex};
  align-items: center;
  padding: ${StyleUtils.Spacing(2)};
`;

type FooterInner = {
  readonly excludeBorder?: boolean;
};

export const FooterInner = styled.div<FooterInner>`
  ${StyleUtils.Flex};
  border-top: ${({ excludeBorder }) => (excludeBorder ? 0 : '1px')} solid
    ${({ theme }) => theme.colors.borderGray};
  justify-content: space-between;
  padding: ${StyleUtils.Spacing(2, 1, 0)};
  padding-top: ${({ excludeBorder }) =>
    excludeBorder ? 0 : StyleUtils.Spacing(2)};
  width: 100%;
`;

export const FooterIconAndText = css`
  ${StyleUtils.Flex};
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.body};
`;

export const LikeButton = styled.button`
  ${FooterIconAndText};
  padding: 1px;
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
`;

export const Like = styled.img`
  width: 24px;
  margin-right: ${StyleUtils.Spacing(1)};
`;
