import React from 'react';
import { theme } from '../styles';

export const Icon = ({
  width = 22,
  height = 22,
  color = theme.colors.white,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke={color}
      width={width}
      height={height}
    >
      <path strokeMiterlimit="10" d="M12 1v22M1 12h22"></path>
      <path
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M12 23c3.038 0 5.5-4.925 5.5-11S15.038 1 12 1 6.5 5.925 6.5 12 8.962 23 12 23z"
      ></path>
      <path
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
      ></path>
    </svg>
  );
};

export default Icon;
