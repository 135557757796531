import gql from 'graphql-tag';

export const COMPLIMENT_FRAGMENT = gql`
  fragment ComplimentFragment on Compliment {
    id
    message
    hasReacted
    isStar
    isAnonymous
    sender {
      name
      id
    }
    receiver {
      name
      id
    }
    createdAt
    theme
  }
`;

export const GET_COMPLIMENT = gql`
  query GetCompliment($input: ComplimentInput!) {
    compliment(input: $input) {
      compliment {
        ...ComplimentFragment
      }
    }
  }

  ${COMPLIMENT_FRAGMENT}
`;

export const GET_SENT_COMPLIMENTS = gql`
  query GetSentCompliments($input: UserInput) {
    user(input: $input) {
      user {
        id
        name
        complimentsSent {
          ...ComplimentFragment
        }
      }
    }
  }

  ${COMPLIMENT_FRAGMENT}
`;
