import styled, { keyframes } from 'styled-components';
import { p, py, mt, ml } from 'styled-components-spacing';

import * as StyleUtils from '../../../../../styles/utils';
import { up } from 'styled-breakpoints';

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

type ContainerProps = {
  readonly isShowing?: boolean;
};

export const ModalContainer = styled.div<ContainerProps>`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${({ theme: { layers } }) => layers.l5};

  display: ${({ isShowing }) => (!isShowing ? 'none' : 'flex')};
`;

export const ModalOverlay = styled.div<ContainerProps>`
  animation: ${({ isShowing }) => (!isShowing ? 'none' : fade)} 0.2s ease;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${({ theme: { layers } }) => layers.l5};
`;

type ModalDialog = {
  readonly largeWidth?: number;
};

export const ModalDialog = styled.div<ModalDialog>`
  ${StyleUtils.FlexColumn};

  background: ${props => props.theme.colors.gray};
  border-radius: 5px;
  min-width: 90%;
  ${p({ xs: 4 })};
  z-index: ${({ theme: { layers } }) => layers.l6};

  ${up('sm')} {
    min-width: ${props =>
      props.largeWidth ? props.largeWidth + 'px' : '450px'};
  }
`;

export const ModalContent = styled.div`
  ${py({ xs: 4 })};
  flex: 1;
`;

export const ButtonContainer = styled.div`
  ${StyleUtils.Flex};
  ${mt({ xs: 2 })};
  justify-content: flex-end;
  align-items: center;
`;

export const DialogOkButton = styled.button`
  ${StyleUtils.PrimaryButtonContainer};

  align-self: flex-end;
  ${ml({ xs: 3 })};
  padding: ${StyleUtils.Spacing(2, 2)};

  min-width: 35%;
`;

export const CancelButton = styled.button`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};

  background: transparent;
  color: ${props => props.theme.colors.mint};
  cursor: pointer;
  padding: ${StyleUtils.Spacing(2, 2)};
  text-align: center;
  min-width: 25%;
`;
