import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { mt, px } from 'styled-components-spacing';

import * as StyleUtils from '../../../../../styles/utils';

export * from '../../styles';

type ContainerProps = {
  readonly isShowing?: boolean;
};

export const EditorContainer = styled.div<ContainerProps>`
  ${StyleUtils.Flex};
  ${StyleUtils.FlexColumn};
  align-items: center;
  background: ${({ theme }) => theme.colors.purple};
  color: ${({ theme }) => theme.colors.white};

  position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ${px({ xs: 3 })};

  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;

  display: ${({ isShowing }) => (isShowing ? 'flex' : 'none')};
  transform: translate(${({ isShowing }) => (!isShowing ? '99999px' : '0')});
  transition: opacity 0.2s ease;
  opacity: ${({ isShowing }) => (isShowing ? '1' : '0')};

  z-index: ${({ theme: { layers } }) => layers.l6};
`;

export const Editor = styled.form`
  ${StyleUtils.Flex};
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};

  width: 100%;
  flex: 1 0 auto;

  ${up('md')} {
    width: 70%;
    max-width: 700px;
  }
`;

export const EditorCancelButton = styled.button`
  cursor: pointer;
  position: fixed;
  left: 10px;
  top: 10px;
`;

export const HeroImage = styled.img`
  width: auto;
  margin-top: 15%;

  ${props =>
    StyleUtils.fluidProp(
      'height',
      80,
      160,
      props.theme.breakpoints.xs,
      props.theme.breakpoints.lg,
      'px',
    )}
`;

export const AnimatedIconContainer = styled.div`
  ${props =>
    StyleUtils.fluidProp(
      'width',
      100,
      180,
      props.theme.breakpoints.xs,
      props.theme.breakpoints.lg,
      'px',
    )}

    ${props =>
      StyleUtils.fluidProp(
        'height',
        100,
        180,
        props.theme.breakpoints.xs,
        props.theme.breakpoints.lg,
        'px',
      )}

  ${props =>
    StyleUtils.fluidProp(
      'margin-top',
      10,
      10,
      props.theme.breakpoints.xs,
      props.theme.breakpoints.lg,
      '%',
    )}
`;

export const ToLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.decoration};
  color: ${({ theme }) => theme.colors.gray};
  opacity: 0.75;
  padding: ${StyleUtils.Spacing(2)};
`;

export const InputContainer = styled.div`
  ${StyleUtils.FlexColumn};
  width: 100%;
  padding: 0 ${StyleUtils.Spacing(2)};

  flex: 1 0 auto;

  ${up('md')} {
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};

  margin: ${StyleUtils.Spacing(3)} 0;
  flex: 0 0 auto;

  ${up('md')} {
    margin: ${StyleUtils.Spacing(10)} 0;
  }
`;

export const SendButton = styled.button`
  ${StyleUtils.PrimaryButtonContainer};
  width: 100%;
`;

export const SendAnonymousButton = styled.button`
  ${StyleUtils.PrimaryButtonContainer};

  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  width: 100%;

  ${mt({ xs: 3 })};
`;

export const ThemeUIContainer = styled.div`
  ${StyleUtils.Flex};
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: ${StyleUtils.Spacing(1)};
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
`;

export const ThemeUIButton = styled.button`
  cursor: pointer;
  padding: ${StyleUtils.Spacing(2)};
  margin: ${StyleUtils.Spacing(0, 2)};
`;
