import styled from 'styled-components';
import { pl, pr, py } from 'styled-components-spacing';
import { up } from 'styled-breakpoints';

export * from '../../styles';

interface MenuItemProps {
  readonly withBorder?: boolean;
}

export const MenuItem = styled.div<MenuItemProps>`
  border-top: ${({ withBorder }) => (withBorder ? '1px' : '0px')} solid
    rgba(255, 255, 255, 0.2);
  cursor: pointer;
  ${pl({ xs: 3 })};
  ${pr({ xs: 4 })};
  ${py({ xs: 3 })};

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.purpleActive};
  }
  &:focus {
    outline: none;
  }
`;

export const SelectionImage = styled.img`
  width: 18px;
  height: auto;
  ${up('md')} {
    width: 24px;
  }
`;

export const StarImage = styled.img`
  width: 22px;
  height: auto;
  ${up('md')} {
    width: 21px;
  }
  margin-left: 8px;
  margin-bottom: -4px;
`;
