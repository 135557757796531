import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import * as StyleUtils from '../../styles/utils';

export const DashboardContainer = styled.div`
  ${StyleUtils.Flex};
  background-color: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.charcoal};
`;

export const TopNavContainer = styled.div`
  display: flex;
  margin: ${StyleUtils.Spacing(-2, -2, 2, -2)};
  position: relative;
  position: sticky;
  top: 0;
  z-index: ${({ theme: { layers } }) => layers.l4};
`;

interface SidebarContainerProps {
  readonly isShowing?: boolean;
}

export const SidebarOverlay = styled.div<SidebarContainerProps>`
  background: ${({ isShowing }) =>
    isShowing ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.0)'};
  bottom: 0;
  left: 0;
  pointer-events: ${({ isShowing }) => (isShowing ? 'auto' : 'none')};
  position: fixed;
  right: 0;
  top: 0;
  transition: background 0.3s ease-out;
  z-index: ${({ theme: { layers } }) => layers.l5};

  ${up('md')} {
    display: none;
  }
`;

export const SidebarContainer = styled.div<SidebarContainerProps>`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;

  transition: ${({ isShowing }) =>
    isShowing ? `transform .3s ease-out` : `none`};
  transform: ${({ isShowing }) =>
    isShowing ? `translate3d(0%, 0, 0)` : `translate3d(-100%, 0, 0)`};

  width: 80%;
  z-index: ${({ theme: { layers } }) => layers.l6};

  ${up('md')} {
    display: flex;
    min-width: 240px;
    position: relative;
    position: sticky;
    transform: translateX(0%);
    transition: transform 0s;
    width: 16.6%;
    z-index: ${({ theme: { layers } }) => layers.l5};
  }
`;

export const MainContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: ${StyleUtils.Spacing(2)};
  // Avoid graphical elements overflowing page.
  overflow: hidden;
`;

export const TopContiner = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.borderGray};
  display: none;
  width: 100%;

  ${up('md')} {
    display: block;
  }
`;
