import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import * as StyleUtils from 'styles/utils';

type Spacer = {
  h?: number;
  mdh?: number;
  v?: number;
  mdv?: number;
};

export const Spacer = styled.div<Spacer>`
  height: ${({ v }) => (v ? StyleUtils.Spacing(v) : 0)};
  width: ${({ h }) => (h ? StyleUtils.Spacing(h) : 0)};

  ${up('md')} {
    height: ${({ mdv, v = 0 }) =>
      mdv ? StyleUtils.Spacing(mdv) : StyleUtils.Spacing(v)};
    width: ${({ mdh, h = 0 }) =>
      mdh ? StyleUtils.Spacing(mdh) : StyleUtils.Spacing(h)};
  }
`;
