import styled from 'styled-components';

import * as StyleUtils from 'styles/utils';

import * as FormStyled from 'components/Form';
import { up } from 'styled-breakpoints';

export const Form = styled.form`
  height: 220px;
  overflow-y: auto;
  padding: ${StyleUtils.Spacing(1)} ${StyleUtils.Spacing(2)};
  margin: 0 ${StyleUtils.Spacing(-2)};
`;

export const AssignDepartmentsContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.borderGray};
  padding-top: ${StyleUtils.Spacing(2)};
  width: 100%;
  line-height: 1.5;
`;

export const SelectField = styled(FormStyled.SelectField)`
  margin-bottom: ${StyleUtils.Spacing(1)};
  width: 100%;
  margin-top: ${StyleUtils.Spacing(2)};

  ${up('sm')} {
    width: 45%;
  }
`;
