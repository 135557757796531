import React from 'react';

import Modal from './Modal';

import { Type } from '../../../../components';

type ConfirmModalProps = {
  onCancel: () => void;
  onOk: () => void;
  title: string;
  body: string;
  okText: string;
  cancelText: string;
};

type ConfirmModalState = {
  isOpen: boolean;
};

let resolve: (value?: boolean | PromiseLike<boolean> | undefined) => void;

export default class ConfirmModal extends React.Component<
  ConfirmModalProps,
  ConfirmModalState
> {
  constructor(props: ConfirmModalProps) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  show() {
    this.setState({ isOpen: true });
    return new Promise<boolean>(res => {
      resolve = res;
    });
  }

  handleCancel() {
    this.setState({ isOpen: false });
    resolve(false);
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    resolve(true);
  }

  render() {
    const { isOpen } = this.state;

    const { title, cancelText, okText, body } = this.props;

    return (
      <Modal
        title={title}
        buttonLabel={okText}
        isOpen={isOpen}
        onCancel={this.handleCancel}
        onOk={this.handleConfirm}
        cancelButtonLabel={cancelText}
      >
        <Type min={16} max={18}>
          {body}
        </Type>
      </Modal>
    );
  }
}
