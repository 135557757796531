import { css } from 'styled-components';
import { createSpacing } from 'spacing-helper';
import { stripUnit } from 'polished';
import { up } from 'styled-breakpoints';

export const Spacing = createSpacing({ factor: 8 }); // 8 is default scaling factor

export const Flex = css`
  display: flex;
`;

export const FlexColumn = css`
  ${Flex};
  flex-direction: column;
`;

export const CenterContent = css`
  align-items: center;
  justify-content: center;
`;

export const PrimaryButtonContainer = css`
  ${Flex};
  ${CenterContent};

  background: ${({ theme }) => theme.colors.mint};
  border-radius: ${({ theme }) => theme.borderRadius.br0};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-weight: 700;
  padding: ${Spacing(2, 3)};
  text-align: center;
`;

/**
 * Based on: https://www.madebymike.com.au/writing/precise-control-responsive-typography/
 *
 */
export const fluidProp = (
  propName: string,
  minSize: number,
  maxSize: number,
  minScreenSize: number,
  maxScreenSize: number,
  unit: string,
) => {
  const minScreenSizeInt = stripUnit(minScreenSize);
  const maxScreenSizeInt = stripUnit(maxScreenSize);

  return css`
  ${propName}: ${`${minSize}${unit}`};

  ${up('xs')} {
      ${propName}: calc(
      ${minSize}${unit} + (${maxSize} - ${minSize}) *
        (
          (100vw - ${minScreenSize}${unit}) /
            (${maxScreenSizeInt} - ${minScreenSizeInt})
        )
    );
  }

  @media screen and (min-width: ${maxScreenSize}px) {
      ${propName}: ${`${maxSize}${unit}`};
  }
`;
};

/**
 * Based on: https://www.madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * @return styled-components css`` containing a fluid font-size property that is set between a min and a max.
 */
export const fluidType = (
  minFontSize: number,
  maxFontSize: number,
  minScreenSize: number,
  maxScreenSize: number,
  unit: string,
) => {
  const minScreenSizeInt = stripUnit(minScreenSize);
  const maxScreenSizeInt = stripUnit(maxScreenSize);

  return css`
    font-size: ${`${minFontSize}${unit}`};

    ${up('xs')} {
      font-size: calc(
        ${minFontSize}px + (${maxFontSize} - ${minFontSize}) *
          (
            (100vw - ${minScreenSize}px) /
              (${maxScreenSizeInt} - ${minScreenSizeInt})
          )
      );
    }

    @media screen and (min-width: ${maxScreenSize}) {
      font-size: ${`${maxFontSize}${unit}`};
    }
  `;
};
