import styled from 'styled-components';

import { Type } from 'components/Typography';

import * as StyleUtils from 'styles/utils';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: ${StyleUtils.Spacing(2, 2, 1)};
  border-radius: ${({ theme }) => theme.borderRadius.br3};
  margin-bottom: ${StyleUtils.Spacing(4)};
  text-align: center;
`;

export const Value = styled(Type).attrs({ min: 24, max: 60, weight: 700 })`
  ${StyleUtils.Flex}
  ${StyleUtils.CenterContent}
`;

export const Image = styled.img`
  display: inline-block;
  margin-right: ${StyleUtils.Spacing(2)};
`;

export const Label = styled(Type).attrs({ min: 16, max: 16 })`
  flex: 1;
  text-align: center;
  padding: ${StyleUtils.Spacing(3, 2, 2)};
  border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
  font-family: ${({ theme }) => theme.fonts.body};
`;
