import * as React from 'react';

function OptionsMenuIcon() {
  return (
    <svg width={4} height={22} viewBox="0 0 4 22" fill="none">
      <path
        d="M0 11a2 2 0 104 0 2 2 0 00-4 0zM0 2a2 2 0 104 0 2 2 0 00-4 0zM0 20a2 2 0 104 0 2 2 0 00-4 0z"
        fill="#31C5AE"
      />
    </svg>
  );
}

export default OptionsMenuIcon;
