import React, { FC } from 'react';
import styled from 'styled-components';
import { Type } from './Typography';
import * as StyleUtils from '../styles/utils';
import { ApolloError } from 'apollo-client';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const formatError = (error: Error | ApolloError, t: TFunction) => {
  if (error instanceof ApolloError) {
    if (error.graphQLErrors[0]) {
      return t(`serverErrors.${error.graphQLErrors[0].extensions.code}`, {
        default: error.graphQLErrors[0].message,
      });
    }
  }
  return t(`serverErrors.${error.message}`, { default: error.message });
};

const ErrorIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.707 12.6L31.407 0.3C31.3147 0.205869 31.2048 0.130906 31.0835 0.0794208C30.9621 0.0279357 30.8318 0.000944779 30.7 0H13.3C13.0348 5.66374e-05 12.7805 0.105451 12.593 0.293L0.293 12.6C0.107156 12.7858 0.00190241 13.0372 0 13.3V30.7C5.66374e-05 30.9652 0.105451 31.2195 0.293 31.407L12.593 43.707C12.7805 43.8946 13.0348 43.9999 13.3 44H30.7C30.9652 43.9999 31.2195 43.8946 31.407 43.707L43.707 31.407C43.8946 31.2195 43.9999 30.9652 44 30.7V13.3C43.9981 13.0372 43.8928 12.7858 43.707 12.6Z"
      fill="#FDCD4F"
    />
    <path
      d="M22 35.9993C21.6044 35.9993 21.2178 35.882 20.8889 35.6623C20.56 35.4425 20.3036 35.1301 20.1522 34.7647C20.0009 34.3992 19.9613 33.9971 20.0384 33.6091C20.1156 33.2212 20.3061 32.8648 20.5858 32.5851C20.8655 32.3054 21.2219 32.1149 21.6098 32.0378C21.9978 31.9606 22.3999 32.0002 22.7654 32.1516C23.1308 32.3029 23.4432 32.5593 23.6629 32.8882C23.8827 33.2171 24 33.6038 24 33.9993C24 34.5298 23.7893 35.0385 23.4142 35.4135C23.0391 35.7886 22.5304 35.9993 22 35.9993ZM23.045 28.1703H20.994L20 7.48633H24.043L23.045 28.1703Z"
      fill="#474747"
    />
  </svg>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${StyleUtils.Spacing(2)} 0;
  justify-content: center;
`;

const Icon = styled.div`
  flex: 0;
`;

const Label = styled(Type).attrs({ min: 16, max: 16, weight: 700 })`
  padding-left: ${StyleUtils.Spacing(2)};
  text-align: left;
`;

type ErrorMessageProps = {
  error?: Error;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ children, error }) => {
  const { t } = useTranslation();

  return error || children ? (
    <Container>
      <Icon>
        <ErrorIcon />
      </Icon>
      <Label>{children || formatError(error!, t)}</Label>
    </Container>
  ) : null;
};

export default ErrorMessage;
