import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import theme from '../../../styles/HrosarinnTheme';
import * as Form from '../../../components/Form';
import * as Wizard from '../../../components/Wizard';

import RockOn from '../../../icons/rock-on.svg';
import Progress from '../../../components/Progress';
import { useCreateDepartmentMutation } from '../../../graphql';
import { LanguagePicker } from 'components';

const NEXT_STEP = '/onboarding/users';

const AddDepartments = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const defaultDepartments = ['', ''];
  const [departments, setDepartments] = useState(defaultDepartments);

  const [createDepartment] = useCreateDepartmentMutation();

  const handleInputChange = (
    index: number,
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const values = [...departments];
    const { value } = e.currentTarget;
    values[index] = value;

    setDepartments(values);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...departments];
    values.splice(index, 1);
    setDepartments(values);
  };

  const handleAddFields = () => {
    const values = [...departments];
    values.push('');
    setDepartments(values);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const departmentsToCreate = departments.filter(name => name);

    for (const name of departmentsToCreate) {
      await createDepartment({
        variables: {
          input: {
            name,
          },
        },
      });
    }
    history.push(NEXT_STEP);
  };

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley
          src={RockOn}
          alt="Smiley"
          style={{ maxHeight: '300px' }}
        />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form onSubmit={onSubmit}>
          <Wizard.Fields>
            <Wizard.Header>
              <Wizard.Heading>
                {t('onboarding.addDepartments.title')}
              </Wizard.Heading>
            </Wizard.Header>
            {departments.map((department, index) => (
              <Form.FieldRow key={index}>
                <Form.InputField
                  id={`name.${index}`}
                  name="name"
                  label={t('onboarding.addDepartments.placeholder')}
                  value={department}
                  onChange={event => handleInputChange(index, event)}
                />

                <Form.RemoveButton
                  onClick={() => {
                    handleRemoveFields(index);
                  }}
                  alt={t('onboarding.addDepartments.removeRow')}
                />
              </Form.FieldRow>
            ))}
            <Form.AddButton onClick={handleAddFields}>
              {t('onboarding.addDepartments.addAnother')}
            </Form.AddButton>
          </Wizard.Fields>
          <Wizard.Footer>
            <Wizard.Button type="submit">
              {t('onboarding.addDepartments.cta')}
            </Wizard.Button>
            <Wizard.SkipLink to={NEXT_STEP}>
              {t('onboarding.addDepartments.skip')}
            </Wizard.SkipLink>
          </Wizard.Footer>
        </Wizard.Form>
        <Wizard.ProgressContainer>
          <Progress current={2} total={4} />
        </Wizard.ProgressContainer>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

export default AddDepartments;
