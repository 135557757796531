import React, { useState, useRef } from 'react';

import { Type } from 'components';
import Menu from './components/Menu';

import { useUserState } from 'store/User/UserStore';

import * as Styled from './styles';

import { AvatarIcon } from './icons';
import { FocusOn } from 'react-focus-on';

const Avatar = () => {
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);
  const avatarRef = useRef<HTMLDivElement>(null);

  const { name } = useUserState();
  const firstName = name.split(' ')[0];

  const close = () => {
    if (!avatarRef.current) {
      return;
    }

    setIsMenuShowing(false);
  };

  return (
    <Styled.Avatar
      role="menu"
      ref={avatarRef}
      onBlur={(e: React.FocusEvent<HTMLDivElement>) => {
        setTimeout(() => {
          // Check if the new activeElement is a child of the original container
          if (!avatarRef?.current?.contains(document.activeElement as Node)) {
            // Outside focus
            if (isMenuShowing) setIsMenuShowing(!isMenuShowing);
          }
        }, 0);
      }}
    >
      <Styled.InnerContainer
        tabIndex={0}
        onClick={() => setIsMenuShowing(!isMenuShowing)}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            setAutoFocus(true);
            setIsMenuShowing(!isMenuShowing);
          }
        }}
      >
        <Styled.AvatarLabel>
          <Type min={14} max={16} weight={700}>
            {firstName}
          </Type>
        </Styled.AvatarLabel>
        <AvatarIcon />
      </Styled.InnerContainer>
      <Styled.MenuContainer isShowing={isMenuShowing}>
        <FocusOn
          enabled={isMenuShowing}
          onEscapeKey={() => setIsMenuShowing(false)}
          onClickOutside={() => setIsMenuShowing(false)}
        >
          <Menu onClick={close} />
        </FocusOn>
      </Styled.MenuContainer>
    </Styled.Avatar>
  );
};

export default Avatar;
