import React, { FC, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Lottie } from '@crello/react-lottie';

import { Type, Spacer } from 'components';

import * as Styled from './styles';

import celebrate from './images/celebrate.json';

import deco1 from './images/deco1.svg';
import deco2 from './images/deco2.svg';
import deco3 from './images/deco3.svg';

type SentScreenProps = {
  onClose: () => void;
};

const SentScreen: FC<SentScreenProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const animationRef = useRef<$FixMe>();

  const containerAnimation = { bottom: ['100%', '0%'] };
  const textAnimation = { scale: [0.75, 1], opacity: [0, 1] };
  const textTransition = { ease: 'backOut', duration: 1 };
  const decoTransition = { duration: 2.95, ease: 'easeOut' };

  useEffect(() => {
    setTimeout(() => {
      if (animationRef && animationRef.current) {
        animationRef.current.goToAndPlay(1, true);
      }
    }, 350);
  }, []);

  return (
    <Styled.SentScreenContainer
      animate={containerAnimation}
      transition={{ duration: 1, delay: 0.2, ease: 'easeInOut' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(var(--vh, 1vh) * 100)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '45%',
            top: 0,
            right: 0,
            height: '35vh',
          }}
        >
          <Styled.HeroInnerContainer>
            <Lottie
              animationRef={animationRef}
              style={{ position: 'absolute', left: 0, bottom: 0 }}
              playingState="paused"
              config={{
                loop: false,
                animationData: celebrate,
              }}
            />
          </Styled.HeroInnerContainer>
        </div>
        <Spacer v={8} mdv={10} />
        <Styled.Inner>
          <Styled.DecoContainer
            animate={{
              top: ['30%', '0%'],
              left: ['30%', '0%'],
              opacity: [0, 1],
            }}
            transition={decoTransition}
          >
            <Styled.DecoImage src={deco1} />
          </Styled.DecoContainer>
          <Styled.DecoContainer
            animate={{
              top: ['30%', '-30%'],
              right: ['30%', '0%'],
              opacity: [0, 1],
            }}
            transition={decoTransition}
          >
            <Styled.DecoImage src={deco2} />
          </Styled.DecoContainer>
          <Styled.DecoContainer
            animate={{
              bottom: ['30%', '-40%'],
              right: ['30%', '15%'],
              opacity: [0, 1],
            }}
            transition={decoTransition}
          >
            <Styled.DecoImage src={deco3} />
          </Styled.DecoContainer>

          <motion.div
            animate={textAnimation}
            transition={{ ...textTransition, delay: 0.85 }}
          >
            <Type min={18} max={48} weight={700}>
              <Styled.Purple>
                {t('dashboard.editor.sent.congratulations')}
              </Styled.Purple>
            </Type>
          </motion.div>
          <motion.div
            animate={textAnimation}
            transition={{ ...textTransition, delay: 1.05 }}
            style={{ zIndex: 1 }}
          >
            <Type
              min={18}
              max={48}
              style={{ whiteSpace: 'pre-line' }}
              weight={700}
            >
              {t('dashboard.editor.sent.blurb')}
            </Type>
          </motion.div>
        </Styled.Inner>
        <Spacer v={6} mdv={5} />
        <Styled.CTA onClick={onClose} tabIndex={0}>
          {t('dashboard.editor.sent.buttonLabel')}
        </Styled.CTA>
      </div>
    </Styled.SentScreenContainer>
  );
};

export default SentScreen;
