import gql from 'graphql-tag';

export const SEND_COMPLIMENTS = gql`
  mutation SendCompliments($input: SendComplimentsInput!) {
    sendCompliments(input: $input) {
      success
    }
  }
`;

export const SET_STAR = gql`
  mutation AssignStar($input: AssignStarInput!) {
    assignStar(input: $input) {
      success
    }
  }
`;
