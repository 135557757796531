import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { pt } from 'styled-components-spacing';

import * as StyleUtils from 'styles/utils';

export * from '../styles';

export const Feed = styled.div`
  ${StyleUtils.Flex};
  flex-wrap: wrap;
  ${pt({ xs: 4 })};
  justify-content: center;
  align-items: center;
`;

export const Compliments = styled.div`
  width: 100%;
  ${up('md')} {
    width: 80%;
  }
  ${up('lg')} {
    width: 60%;
  }
`;
