import styled from 'styled-components';

import * as StyleUtils from 'styles/utils';

export const Avatar = styled.div`
  position: relative;
`;

export const InnerContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: flex-end;
  position: relative;
`;

export const AvatarLabel = styled.div`
  margin-right: ${StyleUtils.Spacing(1)};
`;

type MenuContainer = {
  isShowing?: boolean;
};

export const MenuContainer = styled.div<MenuContainer>`
  position: absolute;
  top: 135%;
  right: -10px;
  display: ${({ isShowing }) => (isShowing ? 'flex' : 'none')};
`;
