import React from 'react';

import * as Styled from './styles';
import { useTranslation } from 'react-i18next';

import { Type, Spacer, Image } from 'components';

import explodingHead from './images/exploding-head.svg';

import deco1 from '../images/deco1.svg';
import deco2 from '../images/deco2.svg';
import deco3 from '../images/deco3.svg';

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <Styled.ErrorContainer>
      <Styled.Inner>
        <Image src={explodingHead} alt="" min={170} max={230} />
        <Spacer v={8} />
        <Type min={28} max={50} weight={700}>
          {t('errors.error.title')}
        </Type>
        <Spacer v={3} />
        <Type min={18} max={40} style={{ maxWidth: '800px' }}>
          {t('errors.error.body')}
        </Type>
        <Styled.DecoImage
          src={deco1}
          left={'0'}
          top={'40%'}
          min={42}
          max={80}
        />
        <Styled.DecoImage
          src={deco2}
          right={'0'}
          top={'30%'}
          min={42}
          max={80}
        />
        <Styled.DecoImage
          src={deco3}
          right={'0'}
          bottom={'-50px'}
          min={42}
          max={80}
        />
      </Styled.Inner>
    </Styled.ErrorContainer>
  );
};

export default ErrorPage;
