import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import Menu from './components/Menu';

import { useUserState, useUserDispatch } from 'store/User/UserStore';

import * as Styled from './styles';
import * as gql from './gql';

import bellRead from './icons/bell-read.svg';
import bellUnread from './icons/bell-unread.svg';
import { FocusOn } from 'react-focus-on';

const Notifications = () => {
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const bellRef = useRef<HTMLDivElement>(null);

  const { hasUnread } = useUserState();
  const dispatch = useUserDispatch();
  const [clearUnread] = useMutation(gql.CLEAR_UNREAD);

  const toggleMenu = (e: MouseEvent) => {
    if (bellRef?.current?.contains(e.target as Node)) {
      // Inside click
      return;
    }

    // Outside click
    setIsMenuShowing(false);
  };

  useEffect(() => {
    if (isMenuShowing) {
      document.addEventListener('mousedown', toggleMenu);
    } else {
      document.removeEventListener('mousedown', toggleMenu);
    }

    return () => {
      document.removeEventListener('mousedown', toggleMenu);
    };
  }, [isMenuShowing]);

  return (
    <Styled.Notifications ref={bellRef}>
      <Styled.InnerContainer
        aria-label="Notifications"
        role="menu"
        tabIndex={0}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            setIsMenuShowing(!isMenuShowing);
          }
        }}
        isShowing={isMenuShowing}
        onClick={async () => {
          setIsMenuShowing(!isMenuShowing);
          if (hasUnread) {
            const success = await clearUnread();
            if (success) {
              dispatch({
                type: 'clearUnread',
              });
            }
          }
        }}
      >
        <Styled.BellIcon src={hasUnread ? bellUnread : bellRead} />
      </Styled.InnerContainer>
      <Styled.MenuContainer isShowing={isMenuShowing}>
        <FocusOn
          enabled={isMenuShowing}
          onEscapeKey={() => setIsMenuShowing(false)}
          onClickOutside={() => setIsMenuShowing(false)}
        >
          <Menu
            onClick={() => {
              setIsMenuShowing(false);
            }}
          />
        </FocusOn>
      </Styled.MenuContainer>
    </Styled.Notifications>
  );
};

export default Notifications;
