// eslint-disable-next-line
import { DefaultTheme } from 'styled-components';

const HrosarinnTheme: DefaultTheme = {
  borderRadius: {
    br0: '0px',
    br1: '3px',
    br2: '5px',
    br3: '8px',
  },
  breakpoints: {
    xs: 1,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    borderGray: '#e1e1e1',
    charcoal: '#474747',
    darkGray: '#202020',
    placeholderGray: '#757575',
    gray: '#F2F2F2',
    lemon: '#F9C754',
    lime: '#99CC33',
    mint: '#31C5AE',
    pink: '#E52E64',
    pinkHover: '#D6144D',
    purple: '#7D5AD7',
    purpleActive: '#6045A5',
    white: '#fff',
  },
  fonts: {
    title: 'Avant Garde Gothic ITC',
    body: 'Poppins',
    decoration: 'Bitter',
  },
  layers: {
    l1: 0,
    l2: 1,
    l3: 2,
    l4: 3,
    l5: 4,
    l6: 5,
  },
};

export default HrosarinnTheme;
