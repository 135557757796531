import { SlatePlugin } from '@slate-plugin-system/core';
import { ReactEditor } from 'slate-react';
import { Node, Text, Transforms } from 'slate';

const withLayout = (editor: ReactEditor, placeholder = '') => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length < 1) {
        const title = { type: 'title', children: [{ text: placeholder }] };
        Transforms.insertNodes(editor, title, { at: path.concat(0) });
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        const shouldBeTitle = childPath[0] === 0;
        const isTitle = child.type === 'title';

        if (shouldBeTitle !== isTitle) {
          Transforms.setNodes(
            editor,
            { type: shouldBeTitle ? 'title' : 'paragraph' },
            { at: childPath },
          );
        }
      }
    }

    if (path.length > 1 && path[0] === 0 && Text.isText(node)) {
      if (node.bold || node.italic || node.underlined) {
        Transforms.setNodes(
          editor,
          { bold: null, italic: null, underlined: null },
          { at: path },
        );
        return;
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

const LayoutPlugin = (): SlatePlugin<ReactEditor> => ({
  editorOverrides: withLayout,
});
export default LayoutPlugin;
