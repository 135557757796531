import React, { useCallback, useMemo } from 'react';
import * as Styled from './styles';
import { Editor, Node, Transforms } from 'slate';
import { ReactEditor, Slate } from 'slate-react';
import HoveringToolbar from './HoveringToolbar';
import { SlatePlugin, useCreateEditor } from '@slate-plugin-system/core';
import LinkPlugin from './plugins/Link';
import LayoutPlugin from './plugins/Layout';
import HistoryPlugin from './plugins/History';
import FormatPlugin from './plugins/Format';
import PlaceholderPlugin from './plugins/Placeholder';

type Props = {
  value: Node[];
  onChange?: (value: Node[]) => void;
  placeholder?: string;
  fontFamily: string;
  readOnly?: boolean;
};

const ComplimentInput = React.forwardRef(
  (
    {
      value,
      fontFamily,
      placeholder,
      onChange = () => {},
      readOnly = false,
    }: Props,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const plugins = useMemo(
      () => [
        LayoutPlugin(),
        HistoryPlugin(),
        FormatPlugin(),
        LinkPlugin(),
        PlaceholderPlugin(placeholder),
      ],
      [placeholder],
    );
    const editor = useCreateEditor(
      (plugins as unknown) as SlatePlugin<Editor>[],
    ) as ReactEditor;

    // Make sure the editor always has some selection so it can be focused using
    // the keyboard.
    const onFocus = useCallback(() => {
      if (!editor.selection) {
        Transforms.select(editor, [0, 0]);
      }
    }, [editor]);

    return (
      <Slate editor={editor} value={value} onChange={onChange}>
        <HoveringToolbar ref={ref} />
        <Styled.Editable
          plugins={plugins}
          fontFamily={fontFamily}
          readOnly={readOnly}
          tabIndex={0}
          onFocus={onFocus}
        />
      </Slate>
    );
  },
);

export const isEmpty = (value: Node[]) => {
  return (
    value
      .map(n => Node.string(n))
      .join('')
      .trim() === ''
  );
};

export const fromPlain = (value: string) => [
  {
    type: 'title',
    children: [{ text: value }],
  },
];

export const emptyValue = fromPlain('');

export default ComplimentInput;
