import gql from 'graphql-tag';

export const IMPORT_USERS = gql`
  mutation ImportUsers($input: ImportUsersInput!) {
    importUsers(input: $input) {
      success
    }
  }
`;

export const INVITE_USERS = gql`
  mutation InviteUsers($input: InviteUsersInput!) {
    inviteUsers(input: $input) {
      success
    }
  }
`;

export const GET_ORGANISATION_USERS = gql`
  query GetOrganisationUsers {
    users {
      users {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        name
        locale
        isOnboard
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      register {
        id
      }
    }
  }
`;

export const LOGIN_USER = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      login {
        id
      }
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation Logout {
    logout {
      logout {
        id
      }
    }
  }
`;

export const VERIFY = gql`
  mutation Verify($input: VerifyInput!) {
    verify(input: $input) {
      verify {
        token
        expiry
      }
    }
  }
`;

export const REFRESH_USER = gql`
  mutation Refresh {
    refresh {
      refresh {
        token
        expiry
      }
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      user {
        id
        name
        role
        isOnboard
        locale
        hasUnread
        createdAt
      }
    }
    organisation {
      organisation {
        id
        url
        origin
      }
    }
  }
`;
