import { useTranslation } from 'react-i18next';
import * as Styled from '../../Home/styles';
import starEyesAvatar from '../../Home/icons/star-eyes-avatar.svg';
import { Type } from '../../../../../components/Typography';
import ComplimentMessage from '../ComplimentMessage';
import React, { FC, useMemo, useRef, useEffect } from 'react';
import deco1 from './deco/deco1.svg';
import deco2 from './deco/deco2.svg';
import deco3 from './deco/deco3.svg';

import {
  Compliment as ComplimentModel,
  Maybe,
  useAddReactionMutation,
  User,
} from '../../../../../graphql';

import signOfTheHornsIcon from '../../../../../complimentThemes/icons/sign-of-the-horns.svg';
import grinningFaceWithSweatIcon from '../../../../../complimentThemes/icons/grinning-face-with-sweat.svg';
import starStruckIcon from '../../../../../complimentThemes/icons/star-struck.svg';
import starOfTheMonthIcon from '../../../../../complimentThemes/icons/star-of-the-month.svg';

import signOfTheHornsAnimation from 'complimentThemes/animations/sign-of-the-horns.json';
import grinningFaceWithSweatAnimation from 'complimentThemes/animations/grinning-face-with-sweat.json';
import starStruckIconAnimation from 'complimentThemes/animations/star-struck.json';

import { Link } from 'react-router-dom';
import { useUserState } from 'store/User';
import { ComplimentActionButton } from 'components';
import { formatDate } from 'utils';
import { Lottie } from '@crello/react-lottie';
import { useInView } from 'react-intersection-observer';

type ComplimentPartial = Pick<
  ComplimentModel,
  | 'id'
  | 'message'
  | 'hasReacted'
  | 'createdAt'
  | 'theme'
  | 'isStar'
  | 'isAnonymous'
> & {
  sender: Maybe<Pick<User, 'name' | 'id'>>;
  receiver: Pick<User, 'name' | 'id'>;
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

const decos = [deco1, deco2, deco3];

type animations = {
  [key: string]: object;
};

type icons = {
  [key: string]: string;
};

type fonts = {
  [key: string]: string;
};

const animations: animations = {
  SIGN_OF_THE_HORNS: signOfTheHornsAnimation,
  GRINNING_FACE_WITH_SWEAT: grinningFaceWithSweatAnimation,
  STAR_STRUCK: starStruckIconAnimation,
};

const icons: icons = {
  SIGN_OF_THE_HORNS: signOfTheHornsIcon,
  GRINNING_FACE_WITH_SWEAT: grinningFaceWithSweatIcon,
  STAR_STRUCK: starStruckIcon,
  STAR_OF_THE_MONTH: starOfTheMonthIcon,
};

const fonts: fonts = {
  BITTER: 'BITTER',
  AVANT_GARDE: 'Avant Garde Gothic ITC',
};

const defaultTheme = {
  color: '#7D5AD7',
  icon: 'GRINNING_FACE_WITH_SWEAT',
  font: fonts.BITTER,
};

type Props = {
  compliment: ComplimentPartial;
};

const Compliment: FC<Props> = ({ compliment }) => {
  const { id } = useUserState();
  const [addReaction] = useAddReactionMutation();
  const { t, i18n } = useTranslation();
  let theme: $FixMe = {};

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const animationRef = useRef<$FixMe>();

  useEffect(() => {
    if (inView) {
      if (animationRef && animationRef.current) {
        animationRef.current.goToAndPlay(1, true);
      }
    }
  }, [inView]);

  if (compliment.theme && compliment.theme !== 'default') {
    theme = JSON.parse(compliment.theme as string);
  } else {
    theme = defaultTheme;
  }

  const onReact = async (type: string) => {
    await addReaction({
      variables: {
        input: {
          compliment: compliment.id,
          type,
        },
      },
    });
  };

  const decoElements = useMemo(
    () => (
      <>
        <Styled.Deco1 src={decos[getRandomInt(3)]} alt="" />
        <Styled.Deco2 src={decos[getRandomInt(3)]} alt="" />
        <Styled.Deco3 src={decos[getRandomInt(3)]} alt="" />
      </>
    ),
    [],
  );

  const isSender = compliment.sender?.id === id;
  const isReceiver = compliment.receiver.id === id;

  type MetaTitleProps = {
    isAnon: boolean;
    to?: string;
  };

  return (
    <Styled.Compliment>
      <Styled.ComplimentMeta>
        <Styled.ComplimentMetaAvatar src={starEyesAvatar} alt="" />
        <Styled.ComplimentMetaTextContainer>
          <Styled.ComplimentMetaTitle
            className={!compliment.isAnonymous ? 'withHover' : ''}
            as={compliment.isAnonymous ? undefined : Link}
            to={`/profile/${compliment.sender?.id}`}
          >
            <Type min={14} max={16} weight={700}>
              {compliment.sender?.name || t('compliment.anonymousNinja')}
            </Type>
          </Styled.ComplimentMetaTitle>
          <Styled.ComplimentMetaSubTitle
            className={!compliment.isAnonymous ? 'withHover' : ''}
            as={compliment.isAnonymous ? undefined : Link}
            to={`/compliments/${compliment.id}`}
          >
            <Type min={12} max={14} weight={400} as="span">
              {formatDate(compliment.createdAt as string, i18n.language)}
            </Type>
          </Styled.ComplimentMetaSubTitle>
        </Styled.ComplimentMetaTextContainer>
      </Styled.ComplimentMeta>

      <Styled.Body
        style={{
          backgroundColor: theme.color,
          color: '#FFF',
        }}
      >
        {decoElements}
        {compliment.isStar ? (
          <Styled.StarComplimentIcon src={icons[theme.icon]} />
        ) : (
          <Styled.AnimatedComplimentIconContainer ref={ref}>
            <Lottie
              animationRef={animationRef}
              config={{
                loop: false,
                animationData: animations[theme.icon],
              }}
            />
          </Styled.AnimatedComplimentIconContainer>
        )}
        <Styled.Reciver>
          <Type min={14} max={18}>
            {compliment.isStar ? (
              <>
                {t('compliment.starOfTheMonth')}
                <br />
              </>
            ) : (
              t('compliment.to')
            )}
            {compliment.receiver.name}
          </Type>
        </Styled.Reciver>
        <Styled.Message>
          <ComplimentMessage
            value={compliment.message}
            fontFamily={theme.font}
          />
        </Styled.Message>
      </Styled.Body>

      {!isSender && (
        <Styled.Footer>
          <Styled.FooterInner excludeBorder>
            <ComplimentActionButton
              action="like"
              isReceiver={isReceiver}
              liked={compliment.hasReacted}
              onClick={() => onReact('LIKE')}
              disabled={compliment.hasReacted}
            />
          </Styled.FooterInner>
        </Styled.Footer>
      )}
    </Styled.Compliment>
  );
};

export default Compliment;
