import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import * as StyleUtils from 'styles/utils';

export const MenuContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.br3};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: ${StyleUtils.Spacing(2, 0)};

  &:before {
    content: '';
    background: ${({ theme }) => theme.colors.white};
    width: 16px;
    height: 16px;
    position: absolute;
    top: -8px;
    right: 18px;
    transform: rotate(45deg);
  }
`;

type MenuItem = {
  isSelected?: boolean;
};

export const MenuItem = styled.div<MenuItem>`
  ${StyleUtils.Flex};
  align-items: center;
  min-width: 180px;
  justify-content: flex-start;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.body};
  padding: ${StyleUtils.Spacing(2, 2)};

  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.gray : theme.colors.white};

  &:focus {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.gray : theme.colors.white};
  }
  z-index: 1;
`;

export const MenuIconContainer = styled.div`
  ${StyleUtils.Flex};
  ${StyleUtils.CenterContent};
  width: 22px;
`;

export const MenuIcon = styled.img`
  height: 22px;
  width: auto;
`;

export const Separator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.borderGray};
  width: 80%;
  margin: 0 auto;
`;

type Spacer = {
  h?: number;
  v?: number;
};

export const Spacer = styled.div<Spacer>`
  height: ${({ v }) => (v ? StyleUtils.Spacing(v) : 0)};
  width: ${({ h }) => (h ? StyleUtils.Spacing(h) : 0)};
`;

export const Field = styled.input`
  background: ${props => props.theme.colors.white};
  border: none;
  font-size: 16px;
  margin-right: 20px;
  padding: ${StyleUtils.Spacing(2, 3)};
  width: 40%;
`;

export const File = styled.input`
  background: ${props => props.theme.colors.white};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: ${StyleUtils.Spacing(2)};
`;

export const Inner = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  margin-top: ${StyleUtils.Spacing(4)};
`;

export const Purple = styled.span`
  color: ${({ theme }) => theme.colors.purple};
`;
