import gql from 'graphql-tag';

export const INVITE_USERS = gql`
  mutation InviteUsers($input: InviteUsersInput!) {
    inviteUsers(input: $input) {
      success
    }
  }
`;

export const GET_DEPARTMENTS = gql`
  query GetDepartments {
    departments {
      departments {
        id
        name
      }
    }
  }
`;
