import React from 'react';
import { useTranslation } from 'react-i18next';

import theme from '../../../styles/HrosarinnTheme';
import { useUserState } from '../../../store/User';
import * as Wizard from '../../../components/Wizard';
import Progress from '../../../components/Progress';

import RockOn from '../../../icons/rock-on.svg';
import WelcomeWave from './icons/WelcomeWave';
import { LanguagePicker } from 'components';

const Welcome = () => {
  const { t } = useTranslation();
  const { name } = useUserState();

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley
          src={RockOn}
          alt="Smiley"
          style={{ maxHeight: '300px' }}
        />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form>
          <Wizard.Fields>
            <WelcomeWave />
            <Wizard.Header>
              <Wizard.Heading>
                {t('welcome.title')} {name}!
              </Wizard.Heading>
              <Wizard.Subheading>{t('welcome.confirmEmail')}</Wizard.Subheading>
            </Wizard.Header>
          </Wizard.Fields>
        </Wizard.Form>
        <Wizard.ProgressContainer>
          <Progress current={2} total={2} />
        </Wizard.ProgressContainer>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

export default Welcome;
