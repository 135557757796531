import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import * as StyleUtils from '../../../../styles/utils';

interface ComplimentCtaProps {
  isActive?: boolean;
}

const ComplimentCta = styled.div<ComplimentCtaProps>`
  ${StyleUtils.PrimaryButtonContainer};
  bottom: ${({ isActive }) => (isActive ? '20px' : '-60px')};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

  height: 60px;
  left: 20%;
  position: fixed;
  right: 0;
  transition: bottom 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  width: 60%;
  z-index: ${({ theme: { layers } }) => layers.l4};

  ${up('md')} {
    // sidebar width is 20% + (half of the remainder - half the button width)
    left: calc(20% + 40% - 130px);
    width: 260px;
  }

  & > p {
    display: inline-flex;
    white-space: nowrap;
  }

  & > img {
    height: auto;
    margin: -5px 0 0 10px;
    width: 40px;
  }
`;

export default ComplimentCta;
