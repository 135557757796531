import { useTranslation } from 'react-i18next';
import { useUserDispatch, useUserState } from 'store/User';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_USER } from 'graphql/user';
import Log from 'utils/Log';

export const useChangeLocale = () => {
  const { i18n } = useTranslation();
  const userDispatch = useUserDispatch();
  const [updateUser] = useMutation(UPDATE_USER);
  const { id } = useUserState();

  const changeLocale = async () => {
    const newLocale = i18n.language === 'is' ? 'en' : 'is';
    i18n.changeLanguage(newLocale);
    userDispatch({
      type: 'setLocale',
      payload: { newLocale },
    });

    // if we are signed in, update users locale in database
    if (id) {
      try {
        await updateUser({ variables: { input: { id, locale: newLocale } } });
      } catch (e) {
        Log.error(e.message, 'useChangeLocale');
      }
    }
  };

  return changeLocale;
};
