import React, { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { BodyLink } from '../../../components/Typography';

import { useUserDispatch } from '../../../store/User';
import theme from '../../../styles/HrosarinnTheme';
import * as Wizard from '../../../components/Wizard';
import * as Form from '../../../components/Form';
import ErrorMessage from 'components/ErrorMessage';
import Progress from '../../../components/Progress';
import { useRegisterMutation } from '../../../graphql';

import StarSmiley from '../../../icons/star-smiley.svg';
import { LanguagePicker } from 'components';
import Log from 'utils/Log';

const Register = () => {
  const { t, i18n } = useTranslation();
  const [register, { data, error }] = useRegisterMutation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [code, setCode] = useState('');
  const dispatch = useUserDispatch();
  const [emailError, setEmailError] = useState<Error | null>(null);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!Form.isValidEmail(email)) {
      setEmailError(new Error('invalidEmail'));
      return;
    }

    register({
      variables: {
        input: {
          name,
          email,
          organisation,
          code,
          locale: i18n.language,
        },
      },
    }).catch(res => {
      const errors = res.graphQLErrors.map((error: { message: string }) => {
        return error.message;
      });
      Log.error(errors[0], 'Register');
    });
  };

  if (data) {
    dispatch({
      type: 'setName',
      payload: { newName: name.split(' ')[0] },
    });

    return <Redirect to="/welcome" />;
  }

  const errorToUse = emailError || error;

  return (
    <Wizard.Container>
      <Wizard.SmileyContainer bg={theme.colors.purple}>
        <Wizard.Smiley src={StarSmiley} alt="Smiley" />
      </Wizard.SmileyContainer>
      <Wizard.ContentContainer>
        <LanguagePicker />
        <Wizard.Form onSubmit={onSubmit}>
          <Wizard.Fields>
            <Wizard.Header>
              <Wizard.Heading>{t('signUp.title')}</Wizard.Heading>
            </Wizard.Header>

            <Form.InputField
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              type="text"
              required
              label={t('signUp.form.fullName')}
            />

            <Form.InputField
              id="email"
              value={email}
              onChange={e => {
                if (emailError) {
                  setEmailError(null);
                }
                setEmail(e.target.value);
              }}
              type="email"
              required
              label={t('signUp.form.email')}
            />

            <Form.InputField
              id="organisation"
              value={organisation}
              onChange={e => setOrganisation(e.target.value)}
              type="text"
              required
              label={t('signUp.form.companyName')}
            />
            <Form.InputField
              id="code"
              value={code}
              onChange={e => setCode(e.target.value)}
              type="text"
              required
              label={t('signUp.form.code')}
            />
            <ErrorMessage error={errorToUse} />
          </Wizard.Fields>
          <Wizard.Button type="submit">{t('signUp.form.cta')}</Wizard.Button>
        </Wizard.Form>
        <Wizard.ProgressContainer>
          <Progress current={1} total={2} />
        </Wizard.ProgressContainer>
      </Wizard.ContentContainer>
    </Wizard.Container>
  );
};

export default Register;
