import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { motion } from 'framer-motion';

import { Image } from 'components';

import * as StyleUtils from 'styles/utils';

export const SentScreenContainer = styled(motion.div)`
  background-color: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.charcoal};
  text-align: center;
  position: fixed;

  left: 0;
  top: 0;
  width: 100vw;
  bottom: 0;
  overflow: hidden;

  z-index: ${({ theme: { layers } }) => layers.l6};
`;

export const Inner = styled.div`
  ${StyleUtils.FlexColumn};
  ${StyleUtils.CenterContent};
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin: ${StyleUtils.Spacing(4, 0)};
  padding: ${StyleUtils.Spacing(2, 0)};

  ${up('md')} {
    max-width: 1400px;
    padding: ${StyleUtils.Spacing(8)};
  }
`;

export const HeroInnerContainer = styled.div`
  position: absolute;
  left: -45px;
  bottom: 10px;

  ${up('md')} {
    bottom: -45px;
  }

  ${props =>
    StyleUtils.fluidProp(
      'width',
      280,
      800,
      props.theme.breakpoints.xs,
      props.theme.breakpoints.lg,
      'px',
    )}

  ${props =>
    StyleUtils.fluidProp(
      'height',
      280,
      800,
      props.theme.breakpoints.xs,
      props.theme.breakpoints.lg,
      'px',
    )}
`;

export const CTA = styled.button`
  ${StyleUtils.PrimaryButtonContainer};
  background-color: ${({ theme }) => theme.colors.purple};
  padding: ${StyleUtils.Spacing(2, 7)};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const DecoContainer = styled(motion.div)`
  position: absolute;
`;

export const DecoImage = styled(Image).attrs({ min: 42, max: 120 })``;

export const Purple = styled.span`
  color: ${({ theme }) => theme.colors.purple};
`;
