import React from 'react';
import styled from 'styled-components';

interface MenuContainerProps {
  readonly isShowing: boolean;
  readonly showBelow: boolean;
}

const MenuContainer = styled.div<MenuContainerProps>`
  background-color: ${props => props.theme.colors.purple};
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.25);
  color: ${props => props.theme.colors.white};
  display: ${({ isShowing }) => (isShowing ? 'flex' : 'none')};
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: ${props => props.theme.layers.l2};

  ${({ showBelow }) =>
    showBelow
      ? `
        bottom: 0;
        transform: translateY(100%);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      `
      : `
        top: 0;
        transform: translateY(-100%);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      `}
`;

interface OptionsMenu {
  isShowing?: boolean;
  children: React.ReactNode;
}

const OptionsMenu = ({ isShowing = false, children }: OptionsMenu) => {
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [showBelow, setBelow] = React.useState(true);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (
      isShowing &&
      window &&
      menuRef.current &&
      menuRef.current.parentElement
    ) {
      const windowBoundary = window.innerHeight - 250;
      const menuPosition =
        menuRef.current.parentElement.getBoundingClientRect().top +
        menuRef.current.parentElement.getBoundingClientRect().height;

      setBelow(windowBoundary > menuPosition);
    }
    setShow(isShowing);
  }, [isShowing]);

  return (
    <MenuContainer ref={menuRef} isShowing={show} showBelow={showBelow}>
      {children}
    </MenuContainer>
  );
};

export default OptionsMenu;
