import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import * as StyleUtils from 'styles/utils';

import { ComplimentMeta, FooterIconAndText, Footer } from '../../styles';

import pencilIconFlatWhite from './icons/pencil-flat-white.svg';

export * from '../../styles';

export const Container = styled.div`
  position: relative;
`;

/*
 *  DECORATIONS
 */
const decoration = css`
  display: none;
  position: absolute;
`;

export const TopDecoration = styled.img`
  ${decoration}

  ${up('md')} {
    display: block;
    right: 10%;
    z-index: ${({ theme }) => theme.layers.l3};
    top: -10%;
  }
`;

export const BottomDecoration = styled.img`
  ${decoration}

  ${up('md')} {
    bottom: -15%;
    display: block;
    right: -3%;
    z-index: ${({ theme }) => theme.layers.l1};
  }
`;

export const SmallHeader = styled.div`
  padding: ${StyleUtils.Spacing(1, 2, 0)};

  ${up('md')} {
    display: none;
  }
`;

export const SmallHeaderInner = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: ${StyleUtils.Spacing(2, 0)};
`;

export const LargeHeader = styled.div`
  display: none;
  ${up('md')} {
    display: block;
    margin-bottom: ${StyleUtils.Spacing(2)};
  }
`;

export const BodyOuter = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: ${StyleUtils.Spacing(8)};
  position: relative;
  z-index: ${({ theme }) => theme.layers.l2};
`;

export const BlurbLarge = styled(ComplimentMeta)`
  display: none;
  padding-bottom: ${StyleUtils.Spacing(1)};

  ${up('md')} {
    ${StyleUtils.Flex}
  }
`;

export const BodyInner = styled.label`
  cursor: text;
  ${StyleUtils.Flex};
  line-height: 1.5;
  padding: ${StyleUtils.Spacing(2)};

  ${up('md')} {
    ${StyleUtils.FlexColumn};
    ${StyleUtils.CenterContent};
    padding-left: ${StyleUtils.Spacing(8.5)};
  }
`;

export const SearchIcon = styled.img`
  margin-right: ${StyleUtils.Spacing(1)};
  ${up('md')} {
    display: none;
  }
`;

export const ComposeButtonLarge = styled.button`
  ${FooterIconAndText};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.purple};
  margin-left: auto;

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
`;

export const Button = styled.button`
  ${StyleUtils.PrimaryButtonContainer};
  height: 100%;
  width: 100%;
`;

export const FooterLarge = styled(Footer)`
  display: none;
  ${up('md')} {
    ${StyleUtils.Flex}
    border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
    padding-left: 0;
    padding-right: 0;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const FooterSmall = styled.div`
  padding: ${StyleUtils.Spacing(3)};
  ${up('md')} {
    display: none;
  }
`;

export const FooterSmallButton = styled.button`
  ${StyleUtils.Flex};
  ${StyleUtils.PrimaryButtonContainer};
  background-image: url(${pencilIconFlatWhite});
  background-position: ${StyleUtils.Spacing(2)} center;
  background-repeat: no-repeat;
  width: 100%;

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
`;
