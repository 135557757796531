import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en';
import 'dayjs/locale/is';

dayjs.extend(localizedFormat);

export const formatDate = (date: string | null, locale = 'is') =>
  date
    ? dayjs(parseInt(date))
        .locale(locale)
        .format('LL')
    : '';
