import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import Modal from '../components/Modal';

import * as Styled from './styles';
import { UPDATE_USER } from 'graphql/user';

type RenameModal = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  userToEdit: {
    id: number;
    name: string;
  };
};

const RenameModal = ({ isOpen, onCancel, onOk, userToEdit }: RenameModal) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');

  useEffect(() => {
    setName(userToEdit.name);
  }, [userToEdit]);

  const [updateUser] = useMutation(UPDATE_USER);

  return (
    <Modal
      title={t('dashboard.profile.renameModal.title')}
      buttonLabel={t('dashboard.profile.renameModal.buttonLabel')}
      isOpen={isOpen}
      onCancel={onCancel}
      onOk={async () => {
        await updateUser({
          variables: {
            input: {
              id: userToEdit.id,
              name,
            },
          },
        });
        onOk();
      }}
    >
      <Styled.Field
        id="newUserName"
        value={name}
        onChange={e => setName(e.target.value)}
        type="text"
        required
        placeholder={t('dashboard.profile.renameModal.placeholder')}
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default RenameModal;
