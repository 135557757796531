import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};

export type AddReactionInput = {
  compliment: Scalars['Int'];
  type: Scalars['String'];
};

export type AddReactionPayload = {
  __typename?: 'AddReactionPayload';
  reaction: Reaction;
};

export type AssignDepartmentInput = {
  users: Array<Scalars['Int']>;
  departments: Array<Scalars['Int']>;
  append?: Maybe<Scalars['Boolean']>;
};

export type AssignStarInput = {
  to: Recipient;
  message: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
};

export type AssignStarsInput = {
  to: Array<Recipient>;
  message: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type Compliment = Node & {
  __typename?: 'Compliment';
  id: Scalars['Int'];
  message: Scalars['String'];
  receiver: User;
  sender: Maybe<User>;
  isAnonymous: Maybe<Scalars['Boolean']>;
  icon: Maybe<Scalars['String']>;
  theme: Maybe<Scalars['String']>;
  isStar: Maybe<Scalars['Boolean']>;
  hasReacted: Scalars['Boolean'];
  createdAt: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['String']>;
};

export type ComplimentInput = {
  id: Scalars['Int'];
};

export type ComplimentPayload = {
  __typename?: 'ComplimentPayload';
  compliment: Maybe<Compliment>;
};

export type ComplimentsInput = {
  receiverId?: Maybe<Scalars['Int']>;
  senderId?: Maybe<Scalars['Int']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
};

export type ComplimentsPayload = {
  __typename?: 'ComplimentsPayload';
  compliments: Array<Compliment>;
  pageInfo: PageInfo;
};

export type CreateDepartmentInput = {
  name: Scalars['String'];
};

export type CreateDepartmentPayload = {
  __typename?: 'CreateDepartmentPayload';
  department: Department;
};

export type CursorPageInfo = {
  __typename?: 'CursorPageInfo';
  remaining: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  cursor: Maybe<Scalars['String']>;
};

export type DeleteDepartmentInput = {
  id: Scalars['Int'];
};

export type DeleteDepartmentPayload = {
  __typename?: 'DeleteDepartmentPayload';
  id: Scalars['Int'];
};

export type DeleteUserInput = {
  id: Scalars['Int'];
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  id: Scalars['Int'];
};

export type Department = Node & {
  __typename?: 'Department';
  id: Scalars['Int'];
  name: Scalars['String'];
  organisationId: Scalars['Int'];
  createdAt: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['String']>;
};

export type DepartmentInput = {
  id: Scalars['Int'];
};

export type DepartmentPayload = {
  __typename?: 'DepartmentPayload';
  department: Maybe<Department>;
};

export type DepartmentsInput = {
  organisationId?: Maybe<Scalars['Int']>;
};

export type DepartmentsPayload = {
  __typename?: 'DepartmentsPayload';
  departments: Array<Department>;
  pageInfo: PageInfo;
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type FilterOptions = {
  orderBy?: Maybe<Sort>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};

export type ImportUsersInput = {
  file: Scalars['Upload'];
};

export type InviteUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  department?: Maybe<Scalars['Int']>;
  resend?: Maybe<Scalars['Boolean']>;
};

export type InviteUserPayload = {
  __typename?: 'InviteUserPayload';
  id: Scalars['Int'];
};

export type InviteUsersInput = {
  users: Array<InviteUserInput>;
};

export type Login = Node & {
  __typename?: 'Login';
  id: Scalars['Int'];
};

export type LoginInput = {
  email: Scalars['String'];
  redirectPath?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  login: Maybe<Login>;
};

export type Logout = Node & {
  __typename?: 'Logout';
  id: Scalars['Int'];
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  logout: Maybe<Logout>;
};

export type MePayload = {
  __typename?: 'MePayload';
  me: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  root: Maybe<Scalars['String']>;
  login: Maybe<LoginPayload>;
  logout: LogoutPayload;
  register: Maybe<RegisterPayload>;
  refresh: RefreshPayload;
  verify: VerifyPayload;
  inviteUser: InviteUserPayload;
  inviteUsers: Response;
  importUsers: Response;
  deleteUser: DeleteUserPayload;
  updateUser: UpdateUserPayload;
  updateRole: UpdateRolePayload;
  assignDepartment: Response;
  removeDepartment: RemoveDepartmentPayload;
  clearUnread: Response;
  createDepartment: CreateDepartmentPayload;
  updateDepartment: UpdateDepartmentPayload;
  deleteDepartment: DeleteDepartmentPayload;
  sendCompliment: Maybe<Response>;
  sendCompliments: Maybe<Response>;
  assignStar: Maybe<Response>;
  assignStars: Maybe<Response>;
  removeStar: Maybe<Response>;
  addReaction: AddReactionPayload;
  removeReaction: Maybe<Response>;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type MutationVerifyArgs = {
  input: VerifyInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationInviteUsersArgs = {
  input: InviteUsersInput;
};

export type MutationImportUsersArgs = {
  input: ImportUsersInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationAssignDepartmentArgs = {
  input: AssignDepartmentInput;
};

export type MutationRemoveDepartmentArgs = {
  input: RemoveDepartmentInput;
};

export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};

export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};

export type MutationDeleteDepartmentArgs = {
  input: DeleteDepartmentInput;
};

export type MutationSendComplimentArgs = {
  input: SendComplimentInput;
};

export type MutationSendComplimentsArgs = {
  input: SendComplimentsInput;
};

export type MutationAssignStarArgs = {
  input: AssignStarInput;
};

export type MutationAssignStarsArgs = {
  input: AssignStarsInput;
};

export type MutationRemoveStarArgs = {
  input: RemoveStarInput;
};

export type MutationAddReactionArgs = {
  input: AddReactionInput;
};

export type MutationRemoveReactionArgs = {
  input: RemoveReactionInput;
};

export type Node = {
  id: Scalars['Int'];
};

export type Organisation = Node & {
  __typename?: 'Organisation';
  id: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
  origin: Scalars['String'];
  isDisabled: Maybe<Scalars['Boolean']>;
  users: Array<User>;
  createdAt: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['String']>;
};

export type OrganisationExistsInput = {
  organisation: Scalars['String'];
};

export type OrganisationPayload = {
  __typename?: 'OrganisationPayload';
  organisation: Organisation;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  count: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  root: Maybe<Scalars['String']>;
  me: UserPayload;
  user: UserPayload;
  users: UsersPayload;
  organisation: Maybe<OrganisationPayload>;
  organisationExists: Response;
  department: Maybe<DepartmentPayload>;
  departments: DepartmentsPayload;
  compliment: ComplimentPayload;
  compliments: ComplimentsPayload;
  star: StarPayload;
  stars: StarsPayload;
  reaction: Maybe<ReactionPayload>;
  reactions: ReactionsPayload;
  stats: StatsPayload;
};

export type QueryUserArgs = {
  input: Maybe<UserInput>;
};

export type QueryUsersArgs = {
  input: Maybe<UsersInput>;
};

export type QueryOrganisationExistsArgs = {
  input: OrganisationExistsInput;
};

export type QueryDepartmentArgs = {
  input: DepartmentInput;
};

export type QueryDepartmentsArgs = {
  input: Maybe<DepartmentsInput>;
};

export type QueryComplimentArgs = {
  input: ComplimentInput;
};

export type QueryComplimentsArgs = {
  input: ComplimentsInput;
};

export type QueryStarArgs = {
  input: StarInput;
};

export type QueryStarsArgs = {
  input: Maybe<StarsInput>;
};

export type QueryReactionArgs = {
  input: ReactionInput;
};

export type QueryReactionsArgs = {
  input: Maybe<ReactionsInput>;
};

export type QueryStatsArgs = {
  filter: Maybe<FilterOptions>;
};

export type Reaction = Node & {
  __typename?: 'Reaction';
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  user: User;
  compliment: Compliment;
  type: Maybe<Scalars['String']>;
  read: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['String']>;
};

export type ReactionInput = {
  id: Scalars['Int'];
};

export type ReactionPayload = {
  __typename?: 'ReactionPayload';
  reaction: Maybe<Reaction>;
};

export type ReactionsInput = {
  user?: Maybe<Scalars['Int']>;
  compliment?: Maybe<Scalars['Int']>;
};

export type ReactionsPayload = {
  __typename?: 'ReactionsPayload';
  reactions: Maybe<Array<Maybe<Reaction>>>;
  pageInfo: PageInfo;
};

export type Recipient = {
  id: Scalars['Int'];
  isDepartment?: Maybe<Scalars['Boolean']>;
};

export type Refresh = Token & {
  __typename?: 'Refresh';
  token: Scalars['String'];
  expiry: Scalars['Date'];
};

export type RefreshPayload = {
  __typename?: 'RefreshPayload';
  refresh: Refresh;
};

export type Register = Node & {
  __typename?: 'Register';
  id: Scalars['Int'];
};

export type RegisterInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  organisation: Scalars['String'];
  locale: Scalars['String'];
  code: Scalars['String'];
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  register: Maybe<Register>;
};

export type RemoveDepartmentInput = {
  userId: Scalars['Int'];
  departmentId: Scalars['Int'];
};

export type RemoveDepartmentPayload = {
  __typename?: 'RemoveDepartmentPayload';
  id: Scalars['Int'];
};

export type RemoveReactionInput = {
  id?: Maybe<Scalars['Int']>;
  compliment?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type RemoveStarInput = {
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['Int']>;
};

export type Response = {
  __typename?: 'Response';
  success: Maybe<Scalars['Boolean']>;
};

export enum Role {
  User = 'USER',
  Manager = 'MANAGER',
  Admin = 'ADMIN',
}

export type SendComplimentInput = {
  to: Recipient;
  message: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
};

export type SendComplimentsInput = {
  to: Array<Recipient>;
  message: Scalars['String'];
  theme?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
};

export type Sort = {
  field: Scalars['String'];
  direction: Direction;
};

export type StarInput = {
  id: Scalars['Int'];
};

export type StarPayload = {
  __typename?: 'StarPayload';
  star: Maybe<Compliment>;
};

export type StarsInput = {
  date: Scalars['String'];
};

export type StarsPayload = {
  __typename?: 'StarsPayload';
  stars: Array<Compliment>;
  pageInfo: PageInfo;
};

export type Stat = {
  __typename?: 'Stat';
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isStar: Scalars['Boolean'];
  isDisabled: Maybe<Scalars['Boolean']>;
  sentCount: Maybe<Scalars['Int']>;
  receivedCount: Maybe<Scalars['Int']>;
};

export type StatIsStarArgs = {
  input: Maybe<UserIsStarInput>;
};

export type Stats = {
  __typename?: 'Stats';
  users: Maybe<Array<Maybe<Stat>>>;
  totalCompliments: Maybe<Scalars['Int']>;
  totalLikes: Maybe<Scalars['Int']>;
};

export type StatsPayload = {
  __typename?: 'StatsPayload';
  stats: Maybe<Stats>;
};

export type Subscription = {
  __typename?: 'Subscription';
  root: Maybe<Scalars['String']>;
};

export type Token = {
  token: Scalars['String'];
  expiry: Scalars['Date'];
};

export type UpdateDepartmentInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateDepartmentPayload = {
  __typename?: 'UpdateDepartmentPayload';
  id: Scalars['Int'];
};

export type UpdateRoleInput = {
  id: Scalars['Int'];
  role: Role;
};

export type UpdateRolePayload = {
  __typename?: 'UpdateRolePayload';
  id: Scalars['Int'];
};

export type UpdateUserInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  isOnboard?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  id: Scalars['Int'];
  user: User;
};

export type User = Node & {
  __typename?: 'User';
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Role;
  isStar: Scalars['Boolean'];
  stars: UserStarsPayload;
  locale: Scalars['String'];
  isDisabled: Maybe<Scalars['Boolean']>;
  organisationId: Scalars['Int'];
  departments: Array<Department>;
  feed: UserFeedPayload;
  complimentsReceived: Array<Compliment>;
  complimentsSent: Array<Compliment>;
  isOnboard: Scalars['Boolean'];
  hasUnread: Scalars['Boolean'];
  notifications: Maybe<Array<Maybe<Reaction>>>;
  createdAt: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['String']>;
};

export type UserIsStarArgs = {
  input: Maybe<UserIsStarInput>;
};

export type UserFeedArgs = {
  filter: Maybe<FilterOptions>;
};

export type UserComplimentsReceivedArgs = {
  filter: Maybe<FilterOptions>;
};

export type UserComplimentsSentArgs = {
  filter: Maybe<FilterOptions>;
};

export type UserNotificationsArgs = {
  input: Maybe<UserNotificationsInput>;
};

export type UserFeedPayload = {
  __typename?: 'UserFeedPayload';
  entries: Array<Compliment>;
  pageInfo: CursorPageInfo;
};

export type UserInput = {
  id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
};

export type UserIsStarInput = {
  date: Scalars['String'];
};

export type UserNotificationsInput = {
  read?: Maybe<Scalars['Boolean']>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  user: User;
};

export type UsersInput = {
  filter?: Maybe<FilterOptions>;
};

export type UsersPayload = {
  __typename?: 'UsersPayload';
  users: Array<User>;
  pageInfo: PageInfo;
};

export type UserStarsPayload = {
  __typename?: 'UserStarsPayload';
  stars: Array<Compliment>;
  pageInfo: PageInfo;
};

export type Verify = Token & {
  __typename?: 'Verify';
  token: Scalars['String'];
  expiry: Scalars['Date'];
};

export type VerifyInput = {
  token: Scalars['String'];
};

export type VerifyPayload = {
  __typename?: 'VerifyPayload';
  verify: Verify;
};

export type ComplimentFragmentFragment = { __typename?: 'Compliment' } & Pick<
  Compliment,
  | 'id'
  | 'message'
  | 'hasReacted'
  | 'isStar'
  | 'isAnonymous'
  | 'createdAt'
  | 'theme'
> & {
    sender: Maybe<{ __typename?: 'User' } & Pick<User, 'name' | 'id'>>;
    receiver: { __typename?: 'User' } & Pick<User, 'name' | 'id'>;
  };

export type GetComplimentQueryVariables = {
  input: ComplimentInput;
};

export type GetComplimentQuery = { __typename?: 'Query' } & {
  compliment: { __typename?: 'ComplimentPayload' } & {
    compliment: Maybe<
      { __typename?: 'Compliment' } & ComplimentFragmentFragment
    >;
  };
};

export type GetSentComplimentsQueryVariables = {
  input?: Maybe<UserInput>;
};

export type GetSentComplimentsQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & Pick<User, 'id' | 'name'> & {
        complimentsSent: Array<
          { __typename?: 'Compliment' } & ComplimentFragmentFragment
        >;
      };
  };
};

export type GetDepartmentsQueryVariables = {};

export type GetDepartmentsQuery = { __typename?: 'Query' } & {
  departments: { __typename?: 'DepartmentsPayload' } & {
    departments: Array<
      { __typename?: 'Department' } & Pick<Department, 'id' | 'name'>
    >;
  };
};

export type CreateDepartmentMutationVariables = {
  input: CreateDepartmentInput;
};

export type CreateDepartmentMutation = { __typename?: 'Mutation' } & {
  createDepartment: { __typename?: 'CreateDepartmentPayload' } & {
    department: { __typename?: 'Department' } & Pick<Department, 'id' | 'name'>;
  };
};

export type GetOrganisationExistsQueryVariables = {
  input: OrganisationExistsInput;
};

export type GetOrganisationExistsQuery = { __typename?: 'Query' } & {
  organisationExists: { __typename?: 'Response' } & Pick<Response, 'success'>;
};

export type AddReactionMutationVariables = {
  input: AddReactionInput;
};

export type AddReactionMutation = { __typename?: 'Mutation' } & {
  addReaction: { __typename?: 'AddReactionPayload' } & {
    reaction: { __typename?: 'Reaction' } & Pick<Reaction, 'id'> & {
        compliment: { __typename?: 'Compliment' } & Pick<
          Compliment,
          'id' | 'hasReacted'
        >;
      };
  };
};

export type RemoveReactionMutationVariables = {
  input: RemoveReactionInput;
};

export type RemoveReactionMutation = { __typename?: 'Mutation' } & {
  removeReaction: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'success'>
  >;
};

export type ImportUsersMutationVariables = {
  input: ImportUsersInput;
};

export type ImportUsersMutation = { __typename?: 'Mutation' } & {
  importUsers: { __typename?: 'Response' } & Pick<Response, 'success'>;
};

export type InviteUsersMutationVariables = {
  input: InviteUsersInput;
};

export type InviteUsersMutation = { __typename?: 'Mutation' } & {
  inviteUsers: { __typename?: 'Response' } & Pick<Response, 'success'>;
};

export type GetOrganisationUsersQueryVariables = {};

export type GetOrganisationUsersQuery = { __typename?: 'Query' } & {
  users: { __typename?: 'UsersPayload' } & {
    users: Array<{ __typename?: 'User' } & Pick<User, 'id'>>;
  };
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
};

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'UpdateUserPayload' } & {
    user: { __typename?: 'User' } & Pick<
      User,
      'id' | 'name' | 'locale' | 'isOnboard'
    >;
  };
};

export type RegisterMutationVariables = {
  input: RegisterInput;
};

export type RegisterMutation = { __typename?: 'Mutation' } & {
  register: Maybe<
    { __typename?: 'RegisterPayload' } & {
      register: Maybe<{ __typename?: 'Register' } & Pick<Register, 'id'>>;
    }
  >;
};

export type LoginMutationVariables = {
  input: LoginInput;
};

export type LoginMutation = { __typename?: 'Mutation' } & {
  login: Maybe<
    { __typename?: 'LoginPayload' } & {
      login: Maybe<{ __typename?: 'Login' } & Pick<Login, 'id'>>;
    }
  >;
};

export type LogoutMutationVariables = {};

export type LogoutMutation = { __typename?: 'Mutation' } & {
  logout: { __typename?: 'LogoutPayload' } & {
    logout: Maybe<{ __typename?: 'Logout' } & Pick<Logout, 'id'>>;
  };
};

export type VerifyMutationVariables = {
  input: VerifyInput;
};

export type VerifyMutation = { __typename?: 'Mutation' } & {
  verify: { __typename?: 'VerifyPayload' } & {
    verify: { __typename?: 'Verify' } & Pick<Verify, 'token' | 'expiry'>;
  };
};

export type RefreshMutationVariables = {};

export type RefreshMutation = { __typename?: 'Mutation' } & {
  refresh: { __typename?: 'RefreshPayload' } & {
    refresh: { __typename?: 'Refresh' } & Pick<Refresh, 'token' | 'expiry'>;
  };
};

export type MeQueryVariables = {};

export type MeQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'name'
      | 'role'
      | 'isOnboard'
      | 'locale'
      | 'hasUnread'
      | 'createdAt'
    >;
  };
  organisation: Maybe<
    { __typename?: 'OrganisationPayload' } & {
      organisation: { __typename?: 'Organisation' } & Pick<
        Organisation,
        'id' | 'url' | 'origin'
      >;
    }
  >;
};

export type DashboardFeedFragmentFragment = {
  __typename?: 'UserFeedPayload';
} & {
  entries: Array<{ __typename?: 'Compliment' } & ComplimentFragmentFragment>;
  pageInfo: { __typename?: 'CursorPageInfo' } & Pick<
    CursorPageInfo,
    'hasNextPage' | 'cursor'
  >;
};

export type GetDashboardHomeQueryVariables = {};

export type GetDashboardHomeQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & Pick<User, 'id' | 'name'> & {
        feed: {
          __typename?: 'UserFeedPayload';
        } & DashboardFeedFragmentFragment;
      };
  };
  users: { __typename?: 'UsersPayload' } & {
    users: Array<
      { __typename?: 'User' } & Pick<
        User,
        'id' | 'name' | 'isStar' | 'isOnboard'
      >
    >;
  };
  departments: { __typename?: 'DepartmentsPayload' } & {
    departments: Array<
      { __typename?: 'Department' } & Pick<Department, 'id' | 'name'>
    >;
  };
};

export type GetMoreDashboardFeedQueryVariables = {
  cursor: Scalars['String'];
};

export type GetMoreDashboardFeedQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & {
      feed: { __typename?: 'UserFeedPayload' } & DashboardFeedFragmentFragment;
    };
  };
};

export type GetProfileQueryVariables = {
  input?: Maybe<UserInput>;
  cursor?: Maybe<Scalars['String']>;
};

export type GetProfileQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & Pick<User, 'id' | 'name' | 'isStar'> & {
        complimentsReceived: Array<
          { __typename?: 'Compliment' } & Pick<Compliment, 'id'>
        >;
        complimentsSent: Array<
          { __typename?: 'Compliment' } & Pick<Compliment, 'id'>
        >;
        stars: { __typename?: 'UserStarsPayload' } & {
          stars: Array<{ __typename?: 'Compliment' } & Pick<Compliment, 'id'>>;
        };
        feed: {
          __typename?: 'UserFeedPayload';
        } & DashboardFeedFragmentFragment;
      };
  };
};

export type GetMoreProfileFeedQueryVariables = {
  input?: Maybe<UserInput>;
  cursor: Scalars['String'];
};

export type GetMoreProfileFeedQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'UserPayload' } & {
    user: { __typename?: 'User' } & {
      feed: { __typename?: 'UserFeedPayload' } & DashboardFeedFragmentFragment;
    };
  };
};

export const ComplimentFragmentFragmentDoc = gql`
  fragment ComplimentFragment on Compliment {
    id
    message
    hasReacted
    isStar
    isAnonymous
    sender {
      name
      id
    }
    receiver {
      name
      id
    }
    createdAt
    theme
  }
`;
export const DashboardFeedFragmentFragmentDoc = gql`
  fragment DashboardFeedFragment on UserFeedPayload {
    entries {
      ...ComplimentFragment
    }
    pageInfo {
      hasNextPage
      cursor
    }
  }
  ${ComplimentFragmentFragmentDoc}
`;
export const GetComplimentDocument = gql`
  query GetCompliment($input: ComplimentInput!) {
    compliment(input: $input) {
      compliment {
        ...ComplimentFragment
      }
    }
  }
  ${ComplimentFragmentFragmentDoc}
`;

/**
 * __useGetComplimentQuery__
 *
 * To run a query within a React component, call `useGetComplimentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplimentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplimentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetComplimentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetComplimentQuery,
    GetComplimentQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetComplimentQuery,
    GetComplimentQueryVariables
  >(GetComplimentDocument, baseOptions);
}
export function useGetComplimentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetComplimentQuery,
    GetComplimentQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetComplimentQuery,
    GetComplimentQueryVariables
  >(GetComplimentDocument, baseOptions);
}
export type GetComplimentQueryHookResult = ReturnType<
  typeof useGetComplimentQuery
>;
export type GetComplimentLazyQueryHookResult = ReturnType<
  typeof useGetComplimentLazyQuery
>;
export type GetComplimentQueryResult = ApolloReactCommon.QueryResult<
  GetComplimentQuery,
  GetComplimentQueryVariables
>;
export const GetSentComplimentsDocument = gql`
  query GetSentCompliments($input: UserInput) {
    user(input: $input) {
      user {
        id
        name
        complimentsSent {
          ...ComplimentFragment
        }
      }
    }
  }
  ${ComplimentFragmentFragmentDoc}
`;

/**
 * __useGetSentComplimentsQuery__
 *
 * To run a query within a React component, call `useGetSentComplimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSentComplimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSentComplimentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSentComplimentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSentComplimentsQuery,
    GetSentComplimentsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetSentComplimentsQuery,
    GetSentComplimentsQueryVariables
  >(GetSentComplimentsDocument, baseOptions);
}
export function useGetSentComplimentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSentComplimentsQuery,
    GetSentComplimentsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetSentComplimentsQuery,
    GetSentComplimentsQueryVariables
  >(GetSentComplimentsDocument, baseOptions);
}
export type GetSentComplimentsQueryHookResult = ReturnType<
  typeof useGetSentComplimentsQuery
>;
export type GetSentComplimentsLazyQueryHookResult = ReturnType<
  typeof useGetSentComplimentsLazyQuery
>;
export type GetSentComplimentsQueryResult = ApolloReactCommon.QueryResult<
  GetSentComplimentsQuery,
  GetSentComplimentsQueryVariables
>;
export const GetDepartmentsDocument = gql`
  query GetDepartments {
    departments {
      departments {
        id
        name
      }
    }
  }
`;

/**
 * __useGetDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDepartmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GetDepartmentsDocument, baseOptions);
}
export function useGetDepartmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GetDepartmentsDocument, baseOptions);
}
export type GetDepartmentsQueryHookResult = ReturnType<
  typeof useGetDepartmentsQuery
>;
export type GetDepartmentsLazyQueryHookResult = ReturnType<
  typeof useGetDepartmentsLazyQuery
>;
export type GetDepartmentsQueryResult = ApolloReactCommon.QueryResult<
  GetDepartmentsQuery,
  GetDepartmentsQueryVariables
>;
export const CreateDepartmentDocument = gql`
  mutation CreateDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      department {
        id
        name
      }
    }
  }
`;
export type CreateDepartmentMutationFn = ApolloReactCommon.MutationFunction<
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables
>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDepartmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
  >(CreateDepartmentDocument, baseOptions);
}
export type CreateDepartmentMutationHookResult = ReturnType<
  typeof useCreateDepartmentMutation
>;
export type CreateDepartmentMutationResult = ApolloReactCommon.MutationResult<
  CreateDepartmentMutation
>;
export type CreateDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables
>;
export const GetOrganisationExistsDocument = gql`
  query GetOrganisationExists($input: OrganisationExistsInput!) {
    organisationExists(input: $input) {
      success
    }
  }
`;

/**
 * __useGetOrganisationExistsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationExistsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrganisationExistsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetOrganisationExistsQuery,
    GetOrganisationExistsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetOrganisationExistsQuery,
    GetOrganisationExistsQueryVariables
  >(GetOrganisationExistsDocument, baseOptions);
}
export function useGetOrganisationExistsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrganisationExistsQuery,
    GetOrganisationExistsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetOrganisationExistsQuery,
    GetOrganisationExistsQueryVariables
  >(GetOrganisationExistsDocument, baseOptions);
}
export type GetOrganisationExistsQueryHookResult = ReturnType<
  typeof useGetOrganisationExistsQuery
>;
export type GetOrganisationExistsLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationExistsLazyQuery
>;
export type GetOrganisationExistsQueryResult = ApolloReactCommon.QueryResult<
  GetOrganisationExistsQuery,
  GetOrganisationExistsQueryVariables
>;
export const AddReactionDocument = gql`
  mutation AddReaction($input: AddReactionInput!) {
    addReaction(input: $input) {
      reaction {
        id
        compliment {
          id
          hasReacted
        }
      }
    }
  }
`;
export type AddReactionMutationFn = ApolloReactCommon.MutationFunction<
  AddReactionMutation,
  AddReactionMutationVariables
>;

/**
 * __useAddReactionMutation__
 *
 * To run a mutation, you first call `useAddReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReactionMutation, { data, loading, error }] = useAddReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddReactionMutation,
    AddReactionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddReactionMutation,
    AddReactionMutationVariables
  >(AddReactionDocument, baseOptions);
}
export type AddReactionMutationHookResult = ReturnType<
  typeof useAddReactionMutation
>;
export type AddReactionMutationResult = ApolloReactCommon.MutationResult<
  AddReactionMutation
>;
export type AddReactionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddReactionMutation,
  AddReactionMutationVariables
>;
export const RemoveReactionDocument = gql`
  mutation RemoveReaction($input: RemoveReactionInput!) {
    removeReaction(input: $input) {
      success
    }
  }
`;
export type RemoveReactionMutationFn = ApolloReactCommon.MutationFunction<
  RemoveReactionMutation,
  RemoveReactionMutationVariables
>;

/**
 * __useRemoveReactionMutation__
 *
 * To run a mutation, you first call `useRemoveReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionMutation, { data, loading, error }] = useRemoveReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveReactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveReactionMutation,
    RemoveReactionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RemoveReactionMutation,
    RemoveReactionMutationVariables
  >(RemoveReactionDocument, baseOptions);
}
export type RemoveReactionMutationHookResult = ReturnType<
  typeof useRemoveReactionMutation
>;
export type RemoveReactionMutationResult = ApolloReactCommon.MutationResult<
  RemoveReactionMutation
>;
export type RemoveReactionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveReactionMutation,
  RemoveReactionMutationVariables
>;
export const ImportUsersDocument = gql`
  mutation ImportUsers($input: ImportUsersInput!) {
    importUsers(input: $input) {
      success
    }
  }
`;
export type ImportUsersMutationFn = ApolloReactCommon.MutationFunction<
  ImportUsersMutation,
  ImportUsersMutationVariables
>;

/**
 * __useImportUsersMutation__
 *
 * To run a mutation, you first call `useImportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersMutation, { data, loading, error }] = useImportUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportUsersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportUsersMutation,
    ImportUsersMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ImportUsersMutation,
    ImportUsersMutationVariables
  >(ImportUsersDocument, baseOptions);
}
export type ImportUsersMutationHookResult = ReturnType<
  typeof useImportUsersMutation
>;
export type ImportUsersMutationResult = ApolloReactCommon.MutationResult<
  ImportUsersMutation
>;
export type ImportUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImportUsersMutation,
  ImportUsersMutationVariables
>;
export const InviteUsersDocument = gql`
  mutation InviteUsers($input: InviteUsersInput!) {
    inviteUsers(input: $input) {
      success
    }
  }
`;
export type InviteUsersMutationFn = ApolloReactCommon.MutationFunction<
  InviteUsersMutation,
  InviteUsersMutationVariables
>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InviteUsersMutation,
    InviteUsersMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    InviteUsersMutation,
    InviteUsersMutationVariables
  >(InviteUsersDocument, baseOptions);
}
export type InviteUsersMutationHookResult = ReturnType<
  typeof useInviteUsersMutation
>;
export type InviteUsersMutationResult = ApolloReactCommon.MutationResult<
  InviteUsersMutation
>;
export type InviteUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InviteUsersMutation,
  InviteUsersMutationVariables
>;
export const GetOrganisationUsersDocument = gql`
  query GetOrganisationUsers {
    users {
      users {
        id
      }
    }
  }
`;

/**
 * __useGetOrganisationUsersQuery__
 *
 * To run a query within a React component, call `useGetOrganisationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetOrganisationUsersQuery,
    GetOrganisationUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetOrganisationUsersQuery,
    GetOrganisationUsersQueryVariables
  >(GetOrganisationUsersDocument, baseOptions);
}
export function useGetOrganisationUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrganisationUsersQuery,
    GetOrganisationUsersQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetOrganisationUsersQuery,
    GetOrganisationUsersQueryVariables
  >(GetOrganisationUsersDocument, baseOptions);
}
export type GetOrganisationUsersQueryHookResult = ReturnType<
  typeof useGetOrganisationUsersQuery
>;
export type GetOrganisationUsersLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationUsersLazyQuery
>;
export type GetOrganisationUsersQueryResult = ApolloReactCommon.QueryResult<
  GetOrganisationUsersQuery,
  GetOrganisationUsersQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        name
        locale
        isOnboard
      }
    }
  }
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserMutation
>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const RegisterDocument = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      register {
        id
      }
    }
  }
`;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RegisterMutation,
    RegisterMutationVariables
  >(RegisterDocument, baseOptions);
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<
  RegisterMutation
>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      login {
        id
      }
    }
  }
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<
  LoginMutation
>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      logout {
        id
      }
    }
  }
`;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    baseOptions,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<
  LogoutMutation
>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const VerifyDocument = gql`
  mutation Verify($input: VerifyInput!) {
    verify(input: $input) {
      verify {
        token
        expiry
      }
    }
  }
`;
export type VerifyMutationFn = ApolloReactCommon.MutationFunction<
  VerifyMutation,
  VerifyMutationVariables
>;

/**
 * __useVerifyMutation__
 *
 * To run a mutation, you first call `useVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMutation, { data, loading, error }] = useVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyMutation,
    VerifyMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<VerifyMutation, VerifyMutationVariables>(
    VerifyDocument,
    baseOptions,
  );
}
export type VerifyMutationHookResult = ReturnType<typeof useVerifyMutation>;
export type VerifyMutationResult = ApolloReactCommon.MutationResult<
  VerifyMutation
>;
export type VerifyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyMutation,
  VerifyMutationVariables
>;
export const RefreshDocument = gql`
  mutation Refresh {
    refresh {
      refresh {
        token
        expiry
      }
    }
  }
`;
export type RefreshMutationFn = ApolloReactCommon.MutationFunction<
  RefreshMutation,
  RefreshMutationVariables
>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RefreshMutation,
    RefreshMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RefreshMutation,
    RefreshMutationVariables
  >(RefreshDocument, baseOptions);
}
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = ApolloReactCommon.MutationResult<
  RefreshMutation
>;
export type RefreshMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RefreshMutation,
  RefreshMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      user {
        id
        name
        role
        isOnboard
        locale
        hasUnread
        createdAt
      }
    }
    organisation {
      organisation {
        id
        url
        origin
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    baseOptions,
  );
}
export function useMeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MeQuery,
    MeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    baseOptions,
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<
  MeQuery,
  MeQueryVariables
>;
export const GetDashboardHomeDocument = gql`
  query GetDashboardHome {
    me {
      user {
        id
        name
        feed(filter: { limit: 10 }) {
          ...DashboardFeedFragment
        }
      }
    }
    users {
      users {
        id
        name
        isStar
        isOnboard
      }
    }
    departments {
      departments {
        id
        name
      }
    }
  }
  ${DashboardFeedFragmentFragmentDoc}
`;

/**
 * __useGetDashboardHomeQuery__
 *
 * To run a query within a React component, call `useGetDashboardHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardHomeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDashboardHomeQuery,
    GetDashboardHomeQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetDashboardHomeQuery,
    GetDashboardHomeQueryVariables
  >(GetDashboardHomeDocument, baseOptions);
}
export function useGetDashboardHomeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDashboardHomeQuery,
    GetDashboardHomeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetDashboardHomeQuery,
    GetDashboardHomeQueryVariables
  >(GetDashboardHomeDocument, baseOptions);
}
export type GetDashboardHomeQueryHookResult = ReturnType<
  typeof useGetDashboardHomeQuery
>;
export type GetDashboardHomeLazyQueryHookResult = ReturnType<
  typeof useGetDashboardHomeLazyQuery
>;
export type GetDashboardHomeQueryResult = ApolloReactCommon.QueryResult<
  GetDashboardHomeQuery,
  GetDashboardHomeQueryVariables
>;
export const GetMoreDashboardFeedDocument = gql`
  query GetMoreDashboardFeed($cursor: String!) {
    me {
      user {
        feed(filter: { limit: 10, cursor: $cursor }) {
          ...DashboardFeedFragment
        }
      }
    }
  }
  ${DashboardFeedFragmentFragmentDoc}
`;

/**
 * __useGetMoreDashboardFeedQuery__
 *
 * To run a query within a React component, call `useGetMoreDashboardFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoreDashboardFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoreDashboardFeedQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetMoreDashboardFeedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMoreDashboardFeedQuery,
    GetMoreDashboardFeedQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetMoreDashboardFeedQuery,
    GetMoreDashboardFeedQueryVariables
  >(GetMoreDashboardFeedDocument, baseOptions);
}
export function useGetMoreDashboardFeedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMoreDashboardFeedQuery,
    GetMoreDashboardFeedQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetMoreDashboardFeedQuery,
    GetMoreDashboardFeedQueryVariables
  >(GetMoreDashboardFeedDocument, baseOptions);
}
export type GetMoreDashboardFeedQueryHookResult = ReturnType<
  typeof useGetMoreDashboardFeedQuery
>;
export type GetMoreDashboardFeedLazyQueryHookResult = ReturnType<
  typeof useGetMoreDashboardFeedLazyQuery
>;
export type GetMoreDashboardFeedQueryResult = ApolloReactCommon.QueryResult<
  GetMoreDashboardFeedQuery,
  GetMoreDashboardFeedQueryVariables
>;
export const GetProfileDocument = gql`
  query GetProfile($input: UserInput, $cursor: String) {
    user(input: $input) {
      user {
        id
        name
        complimentsReceived {
          id
        }
        complimentsSent {
          id
        }
        isStar
        stars {
          stars {
            id
          }
        }
        feed(filter: { limit: 10, cursor: $cursor }) {
          ...DashboardFeedFragment
        }
      }
    }
  }
  ${DashboardFeedFragmentFragmentDoc}
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      input: // value for 'input'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    baseOptions,
  );
}
export function useGetProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetProfileQuery,
    GetProfileQueryVariables
  >(GetProfileDocument, baseOptions);
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>;
export type GetProfileQueryResult = ApolloReactCommon.QueryResult<
  GetProfileQuery,
  GetProfileQueryVariables
>;
export const GetMoreProfileFeedDocument = gql`
  query GetMoreProfileFeed($input: UserInput, $cursor: String!) {
    user(input: $input) {
      user {
        feed(filter: { limit: 10, cursor: $cursor }) {
          ...DashboardFeedFragment
        }
      }
    }
  }
  ${DashboardFeedFragmentFragmentDoc}
`;

/**
 * __useGetMoreProfileFeedQuery__
 *
 * To run a query within a React component, call `useGetMoreProfileFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoreProfileFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoreProfileFeedQuery({
 *   variables: {
 *      input: // value for 'input'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetMoreProfileFeedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMoreProfileFeedQuery,
    GetMoreProfileFeedQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetMoreProfileFeedQuery,
    GetMoreProfileFeedQueryVariables
  >(GetMoreProfileFeedDocument, baseOptions);
}
export function useGetMoreProfileFeedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMoreProfileFeedQuery,
    GetMoreProfileFeedQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetMoreProfileFeedQuery,
    GetMoreProfileFeedQueryVariables
  >(GetMoreProfileFeedDocument, baseOptions);
}
export type GetMoreProfileFeedQueryHookResult = ReturnType<
  typeof useGetMoreProfileFeedQuery
>;
export type GetMoreProfileFeedLazyQueryHookResult = ReturnType<
  typeof useGetMoreProfileFeedLazyQuery
>;
export type GetMoreProfileFeedQueryResult = ApolloReactCommon.QueryResult<
  GetMoreProfileFeedQuery,
  GetMoreProfileFeedQueryVariables
>;
