import React, { FC } from 'react';

import * as Styled from './styles';
import { useTranslation } from 'react-i18next';

import { Spacer, Image, LanguagePicker } from 'components';

import hrosarinnLogo from './images/hrosarinn-logo.svg';

const MarketingHome: FC = () => {
  const { t } = useTranslation();
  return (
    <Styled.Container>
      <Styled.Inner>
        <Image src={hrosarinnLogo} alt="" min={100} max={452} />
        <Spacer v={4} mdv={18} />
        <Styled.Button to="/login">{t('home.signIn')}</Styled.Button>
      </Styled.Inner>
      <LanguagePicker invert />
    </Styled.Container>
  );
};

export default MarketingHome;
