import { SlatePlugin } from '@slate-plugin-system/core';
import { ReactEditor } from 'slate-react';
import { Editor, Node } from 'slate';
import React from 'react';
import styled from 'styled-components';

const PLACEHOLDER_SYMBOL = Symbol('placeholder');

const Placeholder = styled.span.attrs({ contentEditable: false })`
  pointer-events: none;
  display: inline-block;
  vertical-align: text-top;
  position: absolute;
  max-width: 100%;
  opacity: 0.333;
  transform: translateX(-50%);
`;

const PlaceholderPlugin = (placeholder?: string): SlatePlugin<ReactEditor> => ({
  decorate([node, path]) {
    if (
      placeholder &&
      path.length === 0 &&
      Array.isArray(node.children) &&
      node.children.length === 1 &&
      Node.string(node) === ''
    ) {
      const start = Editor.start(node as Editor, []);
      return [
        {
          [PLACEHOLDER_SYMBOL]: true,
          placeholder,
          anchor: start,
          focus: start,
        },
      ];
    }
    return [];
  },
  renderLeaf(props) {
    // @ts-ignore
    if (props.leaf[PLACEHOLDER_SYMBOL]) {
      return (
        <>
          <Placeholder>{String(props.leaf.placeholder)}</Placeholder>
          {props.children}
        </>
      );
    }
    return props.children;
  },
});

export default PlaceholderPlugin;
