import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import Modal from '../components/Modal';

import * as Styled from './styles';
import * as gql from './gql';

type RenameModal = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  departmentToEdit: {
    id: number;
    name: string;
  };
};

const RenameModal = ({
  isOpen,
  onCancel,
  onOk,
  departmentToEdit,
}: RenameModal) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');

  useEffect(() => {
    setName(departmentToEdit.name);
  }, [departmentToEdit]);

  const [updateDept] = useMutation(gql.UPDATE_DEPARTMENT);

  return (
    <Modal
      title={t('dashboard.departments.renameModal.title')}
      buttonLabel={t('dashboard.departments.renameModal.buttonLabel')}
      isOpen={isOpen}
      onCancel={onCancel}
      onOk={async () => {
        await updateDept({
          variables: {
            input: {
              id: departmentToEdit.id,
              name,
            },
          },
        });
        onOk();
      }}
    >
      <Styled.Field
        id="newDepartmentName"
        value={name}
        onChange={e => setName(e.target.value)}
        type="text"
        required
        placeholder={t('dashboard.departments.createPlaceholder')}
        style={{ width: '100%' }}
      />
    </Modal>
  );
};

export default RenameModal;
