import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as StyleUtils from 'styles/utils';
import { LanguageIcon } from './../icons';
import { Type } from './Typography';
import { Spacer } from './Spacer';
import { useChangeLocale } from 'hooks';
import { theme } from '../styles';

const Picker = styled.div`
  ${StyleUtils.Flex};
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.body};
  position: absolute;
  top: ${StyleUtils.Spacing(3)};
  right: ${StyleUtils.Spacing(4)};
`;

const PickerIconContainer = styled.div`
  ${StyleUtils.Flex};
  ${StyleUtils.CenterContent};
  width: 22px;
`;

export const LanguagePicker: React.FC<{ invert?: boolean }> = ({ invert }) => {
  const { t, i18n } = useTranslation();
  const changeLocale = useChangeLocale();

  return (
    <Picker
      onClick={changeLocale}
      style={{ color: invert ? theme.colors.white : theme.colors.charcoal }}
    >
      <PickerIconContainer>
        <LanguageIcon
          color={invert ? theme.colors.white : theme.colors.purple}
        />
      </PickerIconContainer>
      <Spacer h={2} />
      <Type min={14} max={16}>
        {i18n.language === 'is'
          ? t('dashboard.avatarMenu.language.en')
          : t('dashboard.avatarMenu.language.is')}
      </Type>
    </Picker>
  );
};

export default LanguagePicker;
